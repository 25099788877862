import React, { useState, useEffect, useCallback } from 'react'
import Modal from 'components/Modal'
import Empty from 'components/Empty'
import axios from 'utils/axios'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import LoadingWrapper from 'components/Loading'
import EditPartnerForm from './EditPartnerForm'
import { t } from 'utils/localization'
import { FALLBACK } from 'utils/localization/config'
import { LANGUAGE } from 'utils/constants'
import { useIsMounted } from 'utils/hooks'
import { Button, Tabs, Tab, Row, Col, Card } from 'react-bootstrap'

const PartnersTab = () => {
  const [modal, setModal] = useState({ id: null, open: false })
  const [state, setState] = useState({ link: '', commonCover: null })
  const [loading, setLoading] = useState(false)
  const [partners, setPartners] = useState([])
  const [uploaded, setUploaded] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)

  const isMounted = useIsMounted()
  const locales = modal.id
    ? LANGUAGE.getLanguages()
    : [LANGUAGE.getLanguage(FALLBACK)]

  const getData = useCallback(async () => {
    try {
      isMounted && setLoading(true)
      const res = await axios.get('/admin/about-bustourma/partners')
      isMounted && setPartners(res.data)
    } catch (error) {
      /* empty */
    } finally {
      isMounted && setLoading(false)
    }
  }, [isMounted])

  useEffect(() => {
    getData()
  }, [getData])

  const afterSubmit = (data, reload = false, closeModal = false) => {
    if (data) {
      setState({
        link: data.link,
        commonCover: null
      })
      setModal({
        id: data.id,
        open: true
      })
      setUploaded(data.commonCover)
    }
    if (reload) {
      getData()
    }
    if (closeModal) {
      handleCloseModal()
    }
  }

  const handleOpenModal =
    (id = null) =>
    () => {
      setModal({ id, open: true })
    }

  const handleCloseModal = () => {
    setModal({ id: null, open: false })
    setUploaded(null)
    setState({
      link: '',
      commonCover: null
    })
  }

  const handleChange = (name) => (value) => {
    setState({ ...state, [name]: value })
  }

  return (
    <LoadingWrapper loading={loading}>
      <Button onClick={handleOpenModal()} className='mb-4'>
        {t('AddPartner')}
      </Button>

      <Row>
        {partners.map((partner) => (
          <Col lg={3} key={partner.id} className='mb-3'>
            <Card>
              {(partner.cover || partner.commonCover) && (
                <Card.Img
                  alt={partner.title}
                  src={partner.cover || partner.commonCover}
                  variant='top'
                  style={{
                    height: 200,
                    objectFit: 'cover'
                  }}
                />
              )}
              <Card.Body>
                <Card.Title>{partner.title}</Card.Title>
                {partner.link && (
                  <Card.Text>
                    <i>
                      <a href={partner.link}>{partner.link}</a>
                    </i>
                  </Card.Text>
                )}
                <Button variant='primary' onClick={handleOpenModal(partner.id)}>
                  {t('EditPartner')}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {!partners.length && (
          <Col lg={12}>
            <Empty />
          </Col>
        )}
      </Row>

      <Modal
        open={modal.open}
        title={t(modal.id ? 'EditPartner' : 'AddPartner')}
        onClose={handleCloseModal}
        loading={modalLoading}
      >
        <TextControl
          name='link'
          label={t('Link')}
          value={state.link}
          onChange={handleChange('link')}
          useFormik={false}
        />

        <FileUpload
          size='sm'
          label={t('CommonLogo')}
          files={uploaded}
          value={state.commonCover}
          onChange={handleChange('commonCover')}
        />

        <Tabs className='mt-4 mb-4' defaultActiveKey={FALLBACK}>
          {locales.map((lang) => (
            <Tab key={lang.code} title={lang.name} eventKey={lang.code}>
              <EditPartnerForm
                t={t}
                id={modal.id}
                locale={lang.code}
                commonData={state}
                setLoading={setModalLoading}
                afterSubmit={afterSubmit}
              />
            </Tab>
          ))}
        </Tabs>
      </Modal>
    </LoadingWrapper>
  )
}

export default PartnersTab
