import ControlInputGroup from 'components/ControlInputGroup'
import { t } from 'utils/localization'
import { Card } from 'react-bootstrap'

const SectionCard = (props) => {
  const { data, onChange } = props

  return (
    <Card
      style={{
        cursor: 'move',
        userSelect: 'none',
        marginBottom: '.5rem'
      }}
    >
      <Card.Body style={{ padding: '1rem' }}>
        <Card.Title>{data.text}</Card.Title>
        <Card.Text
          as='div'
          style={{
            marginBottom: '-1.5rem'
          }}
        >
          <ControlInputGroup
            type='checkbox'
            onChange={onChange}
            useFormik={false}
            options={[
              {
                id: data.id,
                label: t('LabelVisible'),
                value: data.visible
              }
            ]}
          />
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default SectionCard
