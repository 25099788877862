import Table from 'components/Table'
import axios from 'utils/axios'
import deleteIcon from 'images/icon-delete.svg'
import TextControl from 'components/TextControl'
import LoadingWrapper from 'components/Loading'
import React, { useState, useEffect, useCallback } from 'react'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { objectToUrlParams } from 'utils/helpers'
import { useDebouncedCallback } from 'use-debounce'

const INITIAL_FILTER = {
  page: 1,
  search: '',
  rowsPerPage: 10
}

const ZeroRequestPage = () => {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({ ...INITIAL_FILTER })
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})

  const handleChangeFilter = (name) => (value) => {
    if (name === 'pagination') {
      setFilter({ ...filter, ...value.pagination })
    } else {
      setFilter({ ...filter, [name]: value })
    }
  }

  const getData = useDebouncedCallback(async () => {
    try {
      setLoading(true)

      const res = await axios.get(
        `/admin/nullable-search?${objectToUrlParams(filter)}`
      )

      setData(res.data)
      setPagination(res.pagination)
    } catch {
      /*empty*/
    } finally {
      setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getData()
  }, [getData, filter])

  const handleDelete = useCallback(
    (id) => async () => {
      if (await confirm(t('ConfirmDeleteRequest'))) {
        try {
          await axios.delete(`/admin/nullable-search/${id}`)
          getData()
        } catch {
          /*empty*/
        }
      }
    },
    [getData]
  )

  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        Header: '№',
        accessor: 'id'
      },
      {
        id: 'text',
        Header: t('Request'),
        accessor: 'text'
      },
      {
        id: 'count',
        Header: [t('LabelAmount')],
        accessor: 'count'
      },
      {
        id: 'deleteButton',
        Header: '',
        accessor: 'id',
        Cell: ({ value }) => (
          <div onClick={handleDelete(value)}>
            <img alt='' src={deleteIcon} className='touchable' />
          </div>
        )
      }
    ],
    [handleDelete]
  )

  return (
    <div>
      <h1>{t('ZeroRequests', 'menu')}</h1>

      <TextControl
        value={filter.search}
        label={t('LabelSearch')}
        onChange={handleChangeFilter('search')}
        useFormik={false}
      />

      <LoadingWrapper loading={loading}>
        <Table
          data={data}
          columns={columns}
          pagination={pagination}
          onChange={handleChangeFilter('pagination')}
        />
      </LoadingWrapper>
    </div>
  )
}

export default ZeroRequestPage
