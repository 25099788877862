import FormGroup from 'components/FormGroup'
import PreviewGroup from 'components/PreviewGroup'
import { t } from 'utils/localization'
import { capitalize } from 'utils/helpers'
import { Tabs, Tab, Card, Row, Col, ListGroup } from 'react-bootstrap'

const ProductData = (props) => {
  const { data } = props
  const {
    photo,
    phones,
    pricing,
    included,
    languages,
    whatToTake,
    directions,
    categories,
    productType,
    eventDetails,
    locationDetails
  } = data

  const showTab = !!Object.values(data).length
  const eventsList = (() => {
    if (!data?.eventDetails?.events) {
      return []
    }

    const parsed = []
    data?.eventDetails?.events.forEach((event) => {
      const index = parsed.findIndex((item) => item.day === event.day)

      if (index > -1) {
        parsed[index].events.push(event)
      } else {
        parsed.push({
          day: event.day,
          events: [event]
        })
      }
    })

    return parsed
  })()
  const phoneNumbers = (() => {
    if (!phones?.phoneNumbers) {
      return '-'
    }

    return phones.phoneNumbers
      .map((item) => {
        let number = item.number
        let social = []

        item.viber === 'true' && social.push('Viber')
        item.telegram === 'true' && social.push('Telegram')
        item.whatsapp === 'true' && social.push('WhatsApp')

        if (social.length) {
          number += ` (${social.join(', ')})`
        }

        return number
      })
      .join(', ')
  })()

  return (
    <Tabs>
      <Tab eventKey='basics' title={t('TabBasics')}>
        {showTab && (
          <>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionProductType')}</Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <PreviewGroup
                      label={t('LabelProductType')}
                      value={t(capitalize(productType.productType))}
                    />
                    <PreviewGroup
                      label={t('LabelProductName')}
                      value={productType.title}
                    />
                    <PreviewGroup
                      label={t('LabelTourType')}
                      value={(() => {
                        const types = []
                        if (productType.isGroup) {
                          types.push(t('TourTypeGroup'))
                        }
                        if (productType.isIndividual) {
                          types.push(t('TourTypeIndividual'))
                        }
                        return types.join(', ')
                      })()}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionDirections')}</Card.Header>
              <Card.Body>
                <PreviewGroup
                  label={t('LabelCategoies')}
                  value={directions?.map((item) => item.name).join(', ') || '-'}
                />
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionCategories')}</Card.Header>
              <Card.Body>
                <PreviewGroup
                  label={t('LabelCategoies')}
                  value={
                    categories?.categories
                      ?.map((item) => item.title)
                      .join(', ') || '-'
                  }
                />
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionCover')}</Card.Header>
              <Card.Body>
                <div className='b-dropzone'>
                  <div className='b-preview__container'>
                    <div className='b-preview__wrapper' key={photo.cover}>
                      <div className='b-preview' style={{ height: 200 }}>
                        <div className='b-preview__inner'>
                          <span className='b-preview__item'>
                            <a
                              href={photo.cover}
                              data-fancybox='preview-photos'
                            >
                              <img src={photo.cover} alt='' />
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionPhoto')}</Card.Header>
              <Card.Body>
                <div className='b-dropzone'>
                  <div className='b-preview__container'>
                    {photo.images.map((image, index) => (
                      <div className='b-preview__wrapper' key={index}>
                        <div className='b-preview' style={{ height: 200 }}>
                          <div className='b-preview__inner'>
                            <span className='b-preview__item'>
                              <a href={image} data-fancybox='preview-photos'>
                                <img src={image} alt={image} />
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
      </Tab>
      <Tab eventKey='product' title={t('TabProduct')}>
        {showTab && (
          <>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionRoute')}</Card.Header>
              <Card.Body>
                <Row>
                  {productType.isGroup && (
                    <Col lg={6}>
                      <div className='mb-2'>
                        <b>{t('TourTypeGroup')}</b>
                      </div>
                      <PreviewGroup
                        label={t('LabelStartLocation')}
                        value={t(
                          `Meeting-${locationDetails.group.customerMeetingType}`
                        )}
                      />
                      {locationDetails.group.customerMeetingType ===
                        'fixedLocation' && (
                        <PreviewGroup
                          label={t('Address')}
                          value={locationDetails.group.address}
                        />
                      )}
                    </Col>
                  )}
                  {productType.isIndividual && (
                    <Col lg={6}>
                      <div className='mb-2'>
                        <b>{t('TourTypeIndividual')}</b>
                      </div>
                      <PreviewGroup
                        label={t('LabelStartLocation')}
                        value={t(
                          `Meeting-${locationDetails.individual.customerMeetingType}`
                        )}
                      />
                      {locationDetails.individual.customerMeetingType ===
                        'fixedLocation' && (
                        <PreviewGroup
                          label={t('Address')}
                          value={locationDetails.individual.address}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionEvents')}</Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <PreviewGroup
                      label={t('LabelDescription')}
                      value={eventDetails.description || '-'}
                    />
                  </Col>
                  <Col lg={6}>
                    <PreviewGroup
                      label={t('LabelTourDuration')}
                      value={
                        eventDetails.durationType === 'one'
                          ? t('TourTypeOneDay')
                          : t('TourTypeManyDays')
                      }
                    />
                    <PreviewGroup
                      value={
                        eventDetails.durationType === 'one'
                          ? t('DurationValue', 'common', {
                              hours: eventDetails.durationHours,
                              minutes: eventDetails.durationMinutes
                            })
                          : t('DurationValueDays', 'common', {
                              days: eventDetails.durationDay
                            })
                      }
                    />
                  </Col>
                </Row>
                <FormGroup label={t('LabelEvents')}>
                  {eventsList.map(({ day, events }) => (
                    <ListGroup key={day} className='mb-3'>
                      <ListGroup.Item variant='primary'>
                        <b>
                          {t('Day')} {day}
                        </b>
                      </ListGroup.Item>
                      {events.map((event, index) => (
                        <ListGroup.Item key={event.id}>
                          <div># {index + 1}</div>
                          <div>
                            <b>{event.title}</b>
                          </div>
                          <div>{event.eventType.title}</div>
                          <div>
                            {t('DurationValue', 'common', {
                              hours: event.durationHours,
                              minutes: event.durationMinutes
                            })}
                          </div>
                          <div>{t(`Price-${event.priceType}`)}</div>

                          <div>
                            <i>{t('Address')}:</i>{' '}
                            {event.location?.address || '-'}
                          </div>
                          <div>
                            <i>{t('LabelDescription')}:</i> {event.description}
                          </div>
                          <div>
                            <i>{t('ApproximatePrice')}:</i> {event.priceFrom}{' '}
                            {event.priceTo && ` - ${event.priceTo}`} AMD
                          </div>
                          {event.options.map((option) => (
                            <div key={option.id}>
                              <i>{option.title}:</i>{' '}
                              {option.valueType === 'list' &&
                                (option.value.title || '-')}
                              {option.valueType === 'checkbox' &&
                                (option.value
                                  .map((item) => item.title)
                                  .join(', ') ||
                                  '-')}
                            </div>
                          ))}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ))}
                </FormGroup>
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionLanguages')}</Card.Header>
              <Card.Body>
                <Row>
                  {productType.isGroup && (
                    <Col lg={6}>
                      <div className='mb-2'>
                        <b>{t('TourTypeGroup')}</b>
                      </div>
                      <PreviewGroup
                        label={t('LabelAudioPersonalLanguages')}
                        value={
                          languages.group?.guideTypes
                            ? languages.group.guideTypes
                                .filter((type) => type.personal)
                                .map((type) => t(type.locale))
                                .join(', ') || '-'
                            : '-'
                        }
                      />
                      <PreviewGroup
                        label={t('LabelAudioGuideLanguages')}
                        value={
                          languages.group?.guideTypes
                            ? languages.group.guideTypes
                                .filter((type) => type.audio)
                                .map((type) => t(type.locale))
                                .join(', ') || '-'
                            : '-'
                        }
                      />
                    </Col>
                  )}
                  {productType.isIndividual && (
                    <Col lg={6}>
                      <div className='mb-2'>
                        <b>{t('TourTypeIndividual')}</b>
                      </div>
                      <PreviewGroup
                        label={t('LabelAudioPersonalLanguages')}
                        value={
                          languages.individual?.guideTypes
                            ? languages.individual.guideTypes
                                .filter((type) => type.personal)
                                .map((type) => t(type.locale))
                                .join(', ') || '-'
                            : '-'
                        }
                      />
                      <PreviewGroup
                        label={t('LabelAudioGuideLanguages')}
                        value={
                          languages.individual?.guideTypes
                            ? languages.individual.guideTypes
                                .filter((type) => type.audio)
                                .map((type) => t(type.locale))
                                .join(', ') || '-'
                            : '-'
                        }
                      />
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('LabelIncluded')}</Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <PreviewGroup
                      value={
                        included.length
                          ? included.map((item) => item.name).join(', ')
                          : '-'
                      }
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionWhatToBring')}</Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <PreviewGroup
                      value={
                        whatToTake?.whatTotake?.length
                          ? whatToTake.whatTotake
                              .map((item) => item.name)
                              .join(', ')
                          : '-'
                      }
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('SectionInformation')}</Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <PreviewGroup
                      value={
                        whatToTake?.healthRestrictions?.length
                          ? whatToTake.healthRestrictions
                              .map((item) => item.title)
                              .join(', ')
                          : '-'
                      }
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('TableTitleContacts')}</Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <PreviewGroup
                      label={t('LabelTourPhoneNumbers')}
                      value={phoneNumbers}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        )}
      </Tab>
      <Tab eventKey='schedule' title={t('TabSchedule')}>
        {showTab && (
          <Card className='mt-4'>
            <Card.Header as='h4'>{t('SectionPricing')}</Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <PreviewGroup
                    label={t('LabelAllowChildren')}
                    value={pricing.canChild}
                  />
                  {pricing.canChild && (
                    <PreviewGroup
                      label={t('LabelChildAge')}
                      value={`${pricing.childAgeFrom} - ${pricing.childAgeTo}`}
                    />
                  )}
                </Col>
                <Col md={6} />
                {productType.isGroup && (
                  <Col md={6}>
                    <div className='mb-2'>
                      <b>{t('TourTypeGroup')}</b>
                    </div>

                    {pricing.perPerson.map((item) => (
                      <ListGroup key={item.id} className='mb-3'>
                        <ListGroup.Item variant='primary'>
                          {item.weekDay ? (
                            <b>{t(`Day${item.weekDay}`)}</b>
                          ) : (
                            <b>{item.days.join(', ')}</b>
                          )}
                        </ListGroup.Item>
                        {item.info ? (
                          <>
                            <ListGroup.Item>
                              {t('LableTourStartTime')}
                              {': '}
                              {item.info.times.join(', ')}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              {t('LabelMaxClientsNumber')}
                              {': '}
                              {item.info.maxClients}
                            </ListGroup.Item>
                            {item.info?.adultPrice && (
                              <ListGroup.Item>
                                {t('LabelPriceAdult')}{' '}
                                {item.info?.adultPrice || '-'} AMD
                              </ListGroup.Item>
                            )}
                            {item.info?.armenianResidentPrice && (
                              <ListGroup.Item>
                                {t('LabelPriceArmenian')}{' '}
                                {item.info?.armenianResidentPrice || '-'} AMD
                              </ListGroup.Item>
                            )}
                            {pricing.canChild && item.info.freeChildTo && (
                              <ListGroup.Item>
                                {t('LabelChildAge')}:
                                {` ${item.info.freeChildFrom} - ${item.info.freeChildTo}`}
                                <br />
                                {t('LabelPriceChild')} {t('LabelPriceFree')}
                              </ListGroup.Item>
                            )}
                            {pricing.canChild && item.info.childPrice && (
                              <ListGroup.Item>
                                {t('LabelChildAge')}:
                                {` ${item.info.payChildFrom} - ${item.info.payChildTo}`}
                                <br />
                                {t('LabelPriceChild')}{' '}
                                {item.info?.childPrice || '-'} AMD
                              </ListGroup.Item>
                            )}
                          </>
                        ) : (
                          <ListGroup.Item>{t('LabelNoWorkDay')}</ListGroup.Item>
                        )}
                      </ListGroup>
                    ))}
                  </Col>
                )}
                {productType.isIndividual && (
                  <Col md={6}>
                    <div className='mb-2'>
                      <b>{t('TourTypeIndividual')}</b>
                    </div>

                    {pricing.perTour.map((item) => (
                      <ListGroup key={item.id} className='mb-3'>
                        <ListGroup.Item variant='primary'>
                          {item.weekDay ? (
                            <b>{t(`Day${item.weekDay}`)}</b>
                          ) : (
                            <b>{item.days.join(', ')}</b>
                          )}
                        </ListGroup.Item>
                        {item.info ? (
                          <>
                            <ListGroup.Item>
                              {t('LableTourStartTime')}
                              {': '}
                              {item.info.times.join(', ')}
                            </ListGroup.Item>
                            {item.info.price.map((priceItem, index) => (
                              <ListGroup.Item key={index}>
                                <div key={index}>
                                  <b>
                                    {priceItem.minPeople} -{' '}
                                    {priceItem.maxPeople}:
                                  </b>
                                </div>
                                {priceItem.guidePrice && (
                                  <div>
                                    {t('LabelPriceWithGuide')}{' '}
                                    {priceItem.guidePrice} AMD
                                  </div>
                                )}
                                {priceItem.price && (
                                  <div>
                                    {t('LabelPriceNoGuide')} {priceItem.price}{' '}
                                    AMD
                                  </div>
                                )}
                                {priceItem.driverIsGuide && (
                                  <div>{t('LabelGuideIsDriver')}</div>
                                )}
                              </ListGroup.Item>
                            ))}
                          </>
                        ) : (
                          <ListGroup.Item>{t('LabelNoWorkDay')}</ListGroup.Item>
                        )}
                      </ListGroup>
                    ))}
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        )}
      </Tab>
    </Tabs>
  )
}

export default ProductData
