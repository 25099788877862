import LocaleService, { t } from 'utils/localization'
import { LANGUAGE } from 'utils/constants'
import { useProfile } from 'utils/hooks/useContext'
import { Dropdown, Button, Badge } from 'react-bootstrap'

import iconLogo from 'images/main-logo-primary.png'
import iconNotification from 'images/icon-notification.svg'

import './styles.scss'

const Header = () => {
  const { user, logout, notificationCount } = useProfile()

  const adminBadge = notificationCount.reduce(
    (res, current) => (res += current.count),
    0
  )

  const handleChangeLanguage = async (locale) => {
    if (locale !== LocaleService.locale) {
      LocaleService.change(locale)
    }
  }

  return (
    <header className='b-header'>
      <div className='b-menu'>
        <div className='b-header__logo'>
          <a rel='noreferrer' href={process.env.REACT_APP_HOST} target='_blank'>
            <img src={iconLogo} alt='Bustourma' />
          </a>
        </div>

        <ul>
          <Dropdown onSelect={handleChangeLanguage} align='end'>
            <Dropdown.Toggle>
              {LANGUAGE.getNameByCode(LocaleService.locale)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {LANGUAGE.getLanguages().map((lang) => (
                <Dropdown.Item
                  key={lang.code}
                  active={LocaleService.locale === lang.code}
                  eventKey={lang.code}
                >
                  {t(lang.code)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {!!user && (
            <>
              <Button variant='primary' className='me-3 ms-3'>
                <img alt='' src={iconNotification} />
                {!!adminBadge && (
                  <Badge bg='light' text='dark' pill className='ms-1'>
                    {adminBadge}
                  </Badge>
                )}
              </Button>
              <Button onClick={logout} variant='outline-danger'>
                {t('Logout', 'signin')}
              </Button>
            </>
          )}
        </ul>
      </div>
    </header>
  )
}

export default Header
