import TextControl from 'components/TextControl'
import React, { useEffect, useState } from 'react'
import '../../styles.scss'
import { t } from 'utils/localization'

const FreeDatePeopleRange = (props) => {
  const { onChange, inputPrice, inputError, onPriceChange } = props

  const [count, setCount] = useState(0)

  useEffect(() => {
    onChange({ person: 'peopleRange', count: `1-${count}` })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  const handleMinusClick = () => {
    if (count > 0) {
      setCount(count - 1)
    }
  }

  const handlePlusClick = () => {
    setCount(count + 1)
  }

  return (
    <div className='b-control-counter'>
      <span className='counter-title'>{t('OrderFreeDateRange', 'common')}</span>
      <span className='counter-buttons'>
        <span onClick={handleMinusClick} className='counter-buttons__minus'>
          -
        </span>
        {count}
        <span onClick={handlePlusClick} className='counter-buttons__plus'>
          +
        </span>
      </span>
      <TextControl
        label={t('OrderPrice', 'common')}
        value={inputPrice}
        error={inputError}
        onChange={(ev) =>
          onPriceChange({ person: 'price', price: Number(ev?.target?.value) })
        }
      />
    </div>
  )
}

export default FreeDatePeopleRange
