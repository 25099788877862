import React from 'react'
import TextControl from 'components/TextControl'
import { t } from 'utils/localization'
import { Modal, Button } from 'react-bootstrap'
import { confirmable, createConfirmation } from 'react-confirm'
import alert from 'utils/alert'

const ComplexConfirmation = (props) => {
  const { show, value, text, proceed, okLabel } = props

  const handleCancel = () => {
    proceed(false)
  }

  const handleConfirm = () => {
    proceed(value)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(value).then(function () {
      alert.success('Ссылка скопирована')
    })
  }

  return (
    <div className='static-modal'>
      <Modal show={show} onHide={handleCancel}>
        <Modal.Header>
          <Modal.Title />
        </Modal.Header>
        <Modal.Body>
          {text}
          <div className='mt-3' onClick={handleCopy}>
            <TextControl value={value} disabled />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' onClick={handleConfirm}>
            {okLabel || t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default function prompt(text, options = {}) {
  return createConfirmation(confirmable(ComplexConfirmation))({
    text,
    ...options
  })
}
