import clsx from 'clsx'
import { RequiredSpan } from 'components/Error'
import { Col, Form, Row } from 'react-bootstrap'

const ControlInputGroup = (props) => {
  const {
    type = 'radio',
    name,
    label,
    value,
    error,
    options = [],
    onChange,
    required,
    useFormik = true,
    className,
    ...rest
  } = props

  const handleChange = (event) => {
    if (onChange) {
      onChange(
        useFormik
          ? event
          : type === 'radio'
          ? event.target.value
          : event.target.checked
      )
    }
  }

  const getProps = (option) => {
    let inputProps = {
      type,
      label: option.label,
      custom: true,
      onChange: handleChange,
      ...rest
    }

    if (type === 'radio') {
      inputProps = {
        ...inputProps,
        id: option.value,
        name,
        value: option.value,
        checked: value === option.value
      }
    } else if (type === 'checkbox') {
      inputProps = {
        ...inputProps,
        id: option.id,
        name: option.id,
        checked: option.value
      }
    }

    return inputProps
  }

  return (
    <Form.Group className={clsx('mb-4', className)}>
      {label && (
        <Form.Label>
          {label}
          {required ? <RequiredSpan /> : ''}
        </Form.Label>
      )}
      <div>
        <Row>
          {options.map((option) => (
            <Col key={option.value} lg='auto'>
              <Form.Check {...getProps(option)} />
            </Col>
          ))}
        </Row>
      </div>
      {error && (
        <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default ControlInputGroup
