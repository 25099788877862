import iconEye from 'images/icon-password-shown.svg'
import iconEdit from 'images/icon-edit.svg'
import iconDelete from 'images/icon-delete.svg'

import './styles.scss'

const CrudActions = (props) => {
  const { onEdit, onOpen, onDelete } = props

  return (
    <div className='b-crud'>
      {onEdit && <img alt='' src={iconEdit} onClick={onEdit} />}
      {onEdit && onDelete && <i />}
      {onDelete && <img alt='' src={iconDelete} onClick={onDelete} />}
      {onOpen && (
        <img alt='' src={iconEye} onClick={onOpen} className='filtred' />
      )}
    </div>
  )
}

export default CrudActions
