const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async (callback) => {
    let locale = localStorage.getItem('locale')
    if (!locale) {
      locale = navigator.language.split('-')[0]
      localStorage.setItem('locale', locale)
    }
    callback(locale)
  },
  init: () => {},
  cacheUserLanguage: () => {}
}

export default languageDetector
