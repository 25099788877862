import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import alert from 'utils/alert'
import axios from 'utils/axios'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import HtmlControl from 'components/HtmlControl'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { LANGUAGE } from 'utils/constants'
import { useFormik } from 'formik'
import { objectToFormData } from 'utils/helpers'
import { Button, Row, Col } from 'react-bootstrap'

const EditGeneralForm = (props) => {
  const { locale, afterSubmit, getCommonData } = props

  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required(t('Required', 'validation')),
    phone: Yup.string().required(t('Required', 'validation')),
    banner: Yup.mixed().notRequired(),
    address: Yup.string().required(t('Required', 'validation')),
    description: Yup.string().notRequired(),
    commertialPhone: Yup.string().required(t('Required', 'validation'))
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: {
      title: data.title || '',
      phone: data.phone || '',
      banner: null,
      address: data.address || '',
      description: data.description || '',
      commertialPhone: data.commertialPhone || ''
    },
    validationSchema: ValidationSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        const res = await axios.get(`/public/about-bustourma?locale=${locale}`)
        if (isMounted) {
          setData(res.data)
          afterSubmit(res.data)
        }
      } catch (error) {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    getData()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const handleSubmit = async (values) => {
    setError(null)
    setLoading(true)
    try {
      const data = objectToFormData({ ...values, locale, ...getCommonData() })
      const res = await axios.put('/admin/about-bustourma', data)

      setData(res.data)
      afterSubmit(res.data)
      setFieldValue('banner', null)
      alert.success()
    } catch (error) {
      setError(error)
      alert.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (file) => {
    try {
      await axios.delete(
        `/admin/about-bustourma/banner?image=${file}&bannerLocale=${locale}`
      )
      setData({ ...data, banner: '' })
    } catch {
      alert.error()
    }
  }

  const handleChangeField = (name) => (value) => {
    setFieldValue(name, value)
  }

  return (
    <LoadingWrapper loading={loading}>
      <ErrorMessage error={error} />

      <form onSubmit={onSubmit}>
        <Row>
          <Col lg={6}>
            <TextControl
              name='title'
              label={t('LabelTitle')}
              value={values.title}
              error={touched.title && errors.title}
              required
              onChange={handleChange}
            />
            <TextControl
              name='address'
              label={t('Address')}
              value={values.address}
              error={touched.address && errors.address}
              required
              onChange={handleChange}
            />
            <TextControl
              name='phone'
              label={t('LabelPhone')}
              value={values.phone}
              error={touched.phone && errors.phone}
              required
              onChange={handleChange}
            />
            <TextControl
              name='commertialPhone'
              label={t('CommertialPhone')}
              value={values.commertialPhone}
              error={touched.commertialPhone && errors.commertialPhone}
              required
              onChange={handleChange}
            />
            <HtmlControl
              name='description'
              label={t('LabelDescription')}
              value={values.description}
              onChange={handleChangeField('description')}
            />
          </Col>
          <Col lg={6}>
            <FileUpload
              label={t('LabelBanner')}
              value={values.banner}
              files={data.banner}
              onChange={handleChangeField('banner')}
              onDelete={handleDelete}
            />
          </Col>
        </Row>
        <Button type='submit'>
          {t('SaveFor', 'common', { locale: LANGUAGE.getNameByCode(locale) })}
        </Button>
      </form>
    </LoadingWrapper>
  )
}

export default EditGeneralForm
