import clsx from 'clsx'
import axios from 'utils/axios'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import { t } from 'utils/localization'
import { Form } from 'react-bootstrap'
import { RequiredSpan } from 'components/Error'
import { useDebouncedCallback } from 'use-debounce'
import { useState, useEffect, useCallback } from 'react'

import './styles.scss'

const SelectControl = (props) => {
  const {
    url,
    name,
    keys,
    label,
    value,
    async,
    error,
    options = [],
    multiple,
    onChange,
    onSelect,
    required,
    className,
    ...rest
  } = props

  const [search, setSearch] = useState('')
  const [current, setCurrent] = useState(
    multiple ? [] : { value: '', label: '' }
  )

  useEffect(() => {
    if (value && current?.label !== value.address) {
      setCurrent({
        data: value,
        value: value.address,
        label: value.address
      })
    }
  }, [value, current])

  const selectedValue =
    value && options
      ? multiple
        ? options.filter((option) => value.includes(option.value))
        : // eslint-disable-next-line eqeqeq
          options.find((option) => value == option.value)
      : null

  const load = useDebouncedCallback(async (value, callback) => {
    if (value) {
      try {
        const loadUrl = url
          ? `${url}${encodeURIComponent(value)}`
          : `/public/location?place=${encodeURIComponent(value)}`

        const res = await axios.get(loadUrl)
        const items = res.data.map((item) => ({
          data: item,
          value: item[keys?.value || 'address'],
          label: item[keys?.label || 'address']
        }))
        callback(items)
      } catch {
        /* empty */
      }
    }
  }, 800)

  const handleLoad = (value, callback) => {
    load(value, callback)
  }

  const handleFocus = useCallback(() => {
    if (current && current.label) {
      setSearch(current.label)
    }
  }, [current, setSearch])

  const handleChange = useCallback(
    (data) => {
      if (onChange) {
        multiple
          ? onChange(data ? data.map((option) => option.value) : [])
          : onChange(data.value)
      }
    },
    [multiple, onChange]
  )

  const handleInputChange = useCallback(
    (query) => {
      setSearch(query)
    },
    [setSearch]
  )

  const handleChangeAsync = useCallback(
    (option) => {
      setCurrent(option || [])
      if (onChange) {
        multiple
          ? onChange(option ? option.map((item) => item.value) : [])
          : onChange(option.value || option)
      }
      if (onSelect) {
        onSelect(option)
      }
    },
    [multiple, onChange, onSelect]
  )

  const renderLoadingMessage = () => {
    return null
  }

  const renderNoOptionsMessage = () => {
    return t('SelectNoOptionsText')
  }

  const styles = {
    loadingIndicator: () => ({ display: 'none' }),
    dropdownIndicator: () => ({ display: 'none' }),
    indicatorSeparator: () => ({ display: 'none' })
  }

  return (
    <Form.Group className={clsx('mb-4', className)}>
      {label && (
        <Form.Label>
          {label}
          {required ? <RequiredSpan /> : ''}
        </Form.Label>
      )}

      {async ? (
        <AsyncSelect
          styles={styles}
          className='react-select-container'
          classNamePrefix='react-select'
          isMulti={multiple}
          required={required}
          onFocus={handleFocus}
          onChange={handleChangeAsync}
          onInputChange={handleInputChange}
          loadOptions={handleLoad}
          cacheOptions
          value={multiple ? current : current.value ? current : null}
          inputValue={search}
          blurInputOnSelect
          closeMenuOnSelect
          placeholder={t('LabelSearch')}
          loadingMessage={renderLoadingMessage}
          noOptionsMessage={renderNoOptionsMessage}
          defaultOptions
          {...rest}
        />
      ) : (
        <ReactSelect
          name={name}
          value={selectedValue}
          options={options}
          onChange={handleChange}
          isMulti={multiple}
          required={required}
          className='react-select'
          classNamePrefix='react-select'
          placeholder={t('LabelSearch')}
          closeMenuOnSelect={!multiple}
          noOptionsMessage={renderNoOptionsMessage}
          {...rest}
        />
      )}

      {error && (
        <Form.Control.Feedback type='invalid' className='d-block'>
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default SelectControl
