import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import TextControl from 'components/TextControl'
import SelectControl from 'components/SelectControl'
import { t } from 'utils/localization'
import { useProfile } from 'utils/hooks/useContext'
import { useState, useEffect } from 'react'
import { Collapse, Button, Card, Row, Col } from 'react-bootstrap'

const Actions = (props) => {
  const { data, afterSubmit } = props
  const { loadAdminNotifications } = useProfile()

  const [modal, setModal] = useState({ open: false, type: '' })
  const [action, setAction] = useState(null)
  const [reason, setReason] = useState('')
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [actions, setActions] = useState([])

  useEffect(() => {
    if (data?.actions) {
      setActions(
        data.actions.map((action) => ({
          value: action.code,
          label: action.title
        }))
      )
    }
  }, [data])

  const toggleModal =
    (open, type = '') =>
    () => {
      setModal({ open, type })
      if (!open) {
        setReason('')
      }
    }

  const toggleActions = () => {
    setVisible(!visible)
  }

  const getModalTitle = () => {
    let code = ''

    if (modal.type === 'suspension') {
      code = data.isSuspension ? 'Activate' : 'Deactivate'
    } else if (modal.type === 'block') {
      code = data.inBlackList ? 'Unlock' : 'Block'
    }

    return code ? t(code) : ''
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)

      let url = null
      let method = null
      let payload = null

      if (action) {
        url = `/admin/check/merchant/${data.id}?state=${action}`
        method = 'put'
        payload = { comment: reason }
      } else {
        url = `/admin/merchant/${modal.type}/${data.id}`
        method = 'post'
        payload = { reason }
      }

      const res = await axios({ url, data: payload, method })
      toggleModal(false)()

      setAction(null)
      afterSubmit(res.data)

      loadAdminNotifications()

      alert.success()
    } catch (error) {
      alert.error()
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitAction = async () => {
    if (action === 'reject') {
      toggleModal(true, 'reject')()
    } else {
      handleSubmit()
    }
  }

  return (
    <Col lg={12}>
      <Button variant='link' onClick={toggleActions}>
        {t(`${visible ? 'Hide' : 'Show'}Actions`)}
      </Button>
      <Collapse in={visible}>
        <div>
          <Card className='mt-4'>
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <SelectControl
                    name='action'
                    value={action}
                    options={actions}
                    onChange={setAction}
                  />
                </Col>
                <Col lg={1}>
                  <Button onClick={handleSubmitAction} disabled={!action}>
                    {t('Save')}
                  </Button>
                </Col>
              </Row>

              <div>
                <Button
                  onClick={toggleModal(true, 'suspension')}
                  disabled={data?.state === 'waitCheck'}
                  className='me-2'
                >
                  {t(data.isSuspension ? 'Activate' : 'Deactivate')}
                </Button>
                <Button
                  onClick={toggleModal(true, 'block')}
                  variant='danger'
                  disabled={data?.state === 'waitCheck'}
                >
                  {t(data.inBlackList ? 'Unlock' : 'Block')}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Collapse>

      <Modal
        open={modal.open}
        title={getModalTitle()}
        loading={loading}
        onClose={toggleModal(false)}
      >
        <div>
          <TextControl
            as='textarea'
            rows={6}
            name='reason'
            label={t('LabelReason')}
            value={reason}
            onChange={setReason}
            useFormik={false}
          />

          <div className='b-modal__footer'>
            <Button
              onClick={handleSubmit}
              disabled={!reason.trim()}
              className='ms-auto'
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </Modal>
    </Col>
  )
}

export default Actions
