import alert from 'utils/alert'
import axios from 'utils/axios'
import Table from 'components/Table'
import Rating from 'components/Rating'
import TextControl from 'components/TextControl'
import DateControl from 'components/DateControl'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import SelectCheckboxControl from 'components/SelectCheckboxControl'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { confirm } from 'components/Dialog'
import { objectToUrlParams } from 'utils/helpers'
import { useDebouncedCallback } from 'use-debounce'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useMemo, useState, useEffect, useCallback } from 'react'

import iconDownload from 'images/icon-download.svg'
import iconPaperClip from 'images/paper-clip.svg'

const COLUMNS = [
  {
    value: 'id',
    label: '№'
  },
  {
    value: 'productName',
    label: 'Наименование тура'
  },
  {
    value: 'orderType',
    label: 'Тип тура'
  },
  {
    value: 'productDate',
    label: 'Дата поездки'
  },
  {
    value: 'productTravelers',
    label: 'Кол-во человек'
  },
  {
    value: 'partnerName',
    label: 'Партнер'
  },
  {
    value: 'partnerEmail',
    label: 'E-mail'
  },
  {
    value: 'partnerPhone',
    label: 'Телефон'
  },
  {
    value: 'clientName',
    label: 'Клиент'
  },
  {
    value: 'productRating',
    label: 'Оценка'
  },
  {
    value: 'purchaseDate',
    label: 'Дата покупки'
  },
  {
    value: 'partnerLegalName',
    label: 'Юридическое лицо'
  },
  {
    value: 'operationType',
    label: 'Тип операции'
  },
  {
    value: 'partnerAccount',
    label: 'Номер счета'
  },
  {
    value: 'status',
    label: 'Статус'
  },
  {
    value: 'sum',
    label: 'Сумма'
  },
  {
    value: 'sumRefund',
    label: 'Сумма возврата'
  },
  {
    value: 'sumCorrected',
    label: 'Сумма откор.'
  },
  {
    value: 'comission',
    label: 'Комиссия'
  },
  {
    value: 'partnerProceeds',
    label: 'Выручка партнера'
  },
  {
    value: 'documents',
    label: 'Документы'
  },
  {
    value: 'tickets',
    label: 'Билеты'
  }
]

const STATUS_COLOR = {
  confirm: '#FF9500',
  deposit: '#0075FF',
  complete: '#07CC03',
  waitCancel: '#FF0000',
  userCancel: '#FF0000',
  paymentWait: '#FF9500',
  waitConfirm: '#FF9500',
  merchantCancel: '#FF0000'
}

const FinancePage = () => {
  const [total, setTotal] = useState(null)
  const [loading, setLoading] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [pagination, setPagination] = useState({})
  const [transactions, setTransactions] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])
  const [selectedTransactions, setSelectedTransactions] = useState([])
  const [selectedColumns, setSelectedColumns] = useState(
    COLUMNS.map((column) => column.value)
  )
  const [filter, setFilter] = useState({
    page: 1,
    text: '',
    types: [],
    states: [],
    dateEnd: '',
    dateStart: '',
    sortTrend: 'DESC',
    sortColumn: 'id',
    rowsPerPage: 10,
    paymentDateEnd: '',
    paymentDateStart: ''
  })

  const getTransactions = useDebouncedCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(
        `/admin/report/transactions?${objectToUrlParams(filter)}`
      )
      setPagination(res.pagination)
      setTransactions(res.data)
    } catch {
      setPagination({})
      setTransactions([])
    } finally {
      setLoading(false)
    }
  }, [500])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await axios.get('/admin/report/transactions/states')
        setStatuses(
          res.data.map((item) => ({ label: item.title, value: item.code }))
        )
      } catch {
        /*empty*/
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await axios.get('/admin/report/transactions/payment-types')
        setPaymentTypes(
          res.data.map((item) => ({ label: item.title, value: item.code }))
        )
      } catch {
        /*empty*/
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        if (filter.dateEnd && filter.dateStart) {
          const res = await axios.get(
            `admin/report/transactions/sums?dateEnd=${filter.dateEnd}&dateStart=${filter.dateStart}`
          )
          setTotal(res.data)
        } else {
          setTotal(null)
        }
      } catch {
        /*empty*/
      }
    })()
  }, [filter.dateEnd, filter.dateStart])

  useEffect(() => {
    getTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const handleChange = (name) => (value) => {
    setFilter({ ...filter, [name]: value })
  }

  const handleChangeSort = useCallback(
    (field) => () => {
      if (filter.sortColumn === field) {
        const sortTrend = filter.sortTrend === 'ASC' ? 'DESC' : 'ASC'
        setFilter({
          sortColumn: field,
          sortTrend: sortTrend
        })
      } else {
        setFilter({
          sortColumn: field,
          sortTrend: 'DESC'
        })
      }
    },
    [filter]
  )

  const handleChangeStatus = async () => {
    if (await confirm('Отменить транзакции, как оплаченные?')) {
      try {
        await axios.post('/admin/report/transactions', {
          transactions: selectedTransactions
        })
        getTransactions()
        setSelectedTransactions([])
      } catch (error) {
        alert.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleUploadFile = useCallback(
    (id) => async (event) => {
      const file =
        event.target.files && event.target.files.length
          ? event.target.files[0]
          : null

      if (file) {
        try {
          setLoading(true)

          const formData = new FormData()
          formData.append('document', file)
          const res = await axios.put(
            `/admin/report/transactions/${id}/bank-receipt`,
            formData
          )

          const index = transactions.findIndex((t) => t.id === id)
          if (index > -1) {
            const newTransactions = [...transactions]
            newTransactions[index] = {
              ...newTransactions[index],
              ...res.data
            }
            setTransactions(newTransactions)
          }
        } catch (error) {
          alert.error(error.message)
        } finally {
          setLoading(false)
        }
      }
    },
    [transactions]
  )

  const handleChangePagination = ({ pagination }) => {
    setFilter({ ...filter, ...pagination })
  }

  const handleSelectTransaction = useCallback(
    (id) => () => {
      const index = selectedTransactions.findIndex((t) => t === id)
      const selected = [...selectedTransactions]

      if (index > -1) {
        selected.splice(index, 1)
      } else {
        selected.push(id)
      }

      setSelectedTransactions(selected)
    },
    [selectedTransactions]
  )

  const columns = useMemo(() => {
    return [
      {
        id: 'select',
        Header: '',
        Cell: ({ row }) => (
          <Form.Check
            id={row.original.id}
            custom
            onClick={handleSelectTransaction(row.original.id)}
            checked={selectedTransactions.includes(row.original.id)}
            disabled={row.original.state?.code !== 'deposit'}
          />
        )
      },
      {
        id: 'id',
        Header: '№',
        accessor: 'id'
      },
      {
        id: 'productName',
        Header: 'Наименование тура',
        accessor: 'product',
        Cell: ({ value }) => (
          <Link to={`/products/${value.id}`} target='_blank'>
            {value.title}
          </Link>
        )
      },
      {
        id: 'orderType',
        Header: 'Тип тура',
        accessor: 'orderType',
        Cell: ({ value }) =>
          ({
            isGroup: t('GroupTourType', 'common'),
            isIndividualWithGuide: t('IndividualWithGuideTourType', 'common'),
            isIndividualWithoutGuide: t(
              'IndividualWithoutGuideTourType',
              'common'
            )
          }[value] || '-')
      },
      {
        id: 'productDate',
        Header: 'Дата поездки',
        accessor: 'dateStart',
        Cell: ({ value, row }) => `${value}\xa0-\xa0${row.original.dateEnd}`
      },
      {
        id: 'productTravelers',
        Header: 'Кол-во человек',
        accessor: 'places',
        Cell: ({ value, row: { original } }) =>
          original.orderType === 'isGroup'
            ? value.adult + value.child
            : original.peopleRange
      },
      {
        id: 'partnerName',
        Header: 'Партнер',
        accessor: 'merchant',
        Cell: ({ value }) => (
          <Link to={`/merchants/${value.id}`} target='_blank'>
            {value.publicCompanyTitle}
          </Link>
        )
      },
      {
        id: 'partnerEmail',
        Header: 'E-mail',
        accessor: 'merchant.email',
        Cell: ({ value }) => <a href={`mailto:${value}`}>{value}</a>
      },
      {
        id: 'partnerPhone',
        Header: 'Телефон',
        accessor: 'merchant.phone',
        Cell: ({ value }) => <a href={`tel:${value}`}>{value}</a>
      },
      {
        id: 'clientName',
        Header: 'Клиент',
        accessor: 'client',
        Cell: ({ value }) => (
          <Link to={`/users/${value.id}`} target='_blank'>
            {value.fullName || `#${value.id}`}
          </Link>
        )
      },
      {
        id: 'productRating',
        Header: () => (
          <div className='d-flex align-items-center'>
            Оценка
            <SortButton
              onClick={handleChangeSort('rating')}
              direction={filter.sortColumn === 'rating' ? filter.sortTrend : ''}
            />
          </div>
        ),
        accessor: 'review.rating',
        Cell: ({ value }) => (value ? <Rating value={value} /> : '-')
      },
      {
        id: 'purchaseDate',
        Header: () => (
          <div className='d-flex align-items-center'>
            Дата покупки
            <SortButton
              onClick={handleChangeSort('datePayment')}
              direction={
                filter.sortColumn === 'datePayment' ? filter.sortTrend : ''
              }
            />
          </div>
        ),
        accessor: 'datePayment'
      },
      {
        id: 'partnerLegalName',
        Header: 'Юридическое лицо',
        accessor: 'merchant.companyTitle'
      },
      {
        id: 'operationType',
        Header: 'Тип операции',
        accessor: 'paymentType',
        Cell: ({ value }) => {
          const paymentType = paymentTypes.find((type) => type.value === value)
          return paymentType?.label || '-'
        }
      },
      {
        id: 'partnerAccount',
        Header: 'Номер счета',
        accessor: 'merchant.bankAccount',
        Cell: ({ value }) => value || '-'
      },
      {
        id: 'status',
        Header: 'Статус',
        accessor: 'state',
        Cell: ({ value }) =>
          value ? (
            <div className='d-flex'>
              <div
                style={{
                  width: 10,
                  height: 10,
                  minWidth: 10,
                  marginTop: 4,
                  marginRight: 4,
                  borderRadius: '50%',
                  backgroundColor: STATUS_COLOR[value.code] || 'grey'
                }}
              />
              {value.title}
            </div>
          ) : null
      },
      {
        id: 'sum',
        Header: () => (
          <div className='d-flex align-items-center'>
            Сумма
            <SortButton
              onClick={handleChangeSort('sum')}
              direction={filter.sortColumn === 'sum' ? filter.sortTrend : ''}
            />
          </div>
        ),
        accessor: 'sum.fullSum'
      },
      {
        id: 'sumRefund',
        Header: 'Сумма возврата',
        accessor: 'refund',
        Cell: ({ value }) => (value ? `${value.returnSumAMD}` : '0')
      },
      {
        id: 'sumCorrected',
        Header: 'Сумма откор.',
        Cell: ({ row }) => {
          if (row.original.sum && row.original.refund) {
            return row.original.sum.fullSum - row.original.refund.returnSumAMD
          } else {
            return row.original.sum.fullSum
          }
        }
      },
      {
        id: 'comission',
        Header: 'Комиссия',
        accessor: 'sum.commissionAmount',
        Cell: ({ value, row }) =>
          `${value} (${row.original.sum.paymentCommissionTour}%)`
      },
      {
        id: 'partnerProceeds',
        Header: 'Выручка партнера',
        accessor: 'sum.sumWithCommission'
      },
      {
        id: 'documents',
        Header: 'Документы',
        accessor: 'unholdBankReceipt',
        Cell: ({ value, row }) =>
          value ? (
            <Button variant='outline-light' href={value} target='_blank'>
              <img alt='' src={iconDownload} width={20} height={20} />
            </Button>
          ) : (
            <div>
              <label htmlFor={'clip-' + row.original.id}>
                <div className='btn btn-outline-light'>
                  <img alt='' src={iconPaperClip} width={20} height={20} />
                </div>
              </label>
              <input
                id={'clip-' + row.original.id}
                type='file'
                hidden
                onChange={handleUploadFile(row.original.id)}
              />
            </div>
          )
      },
      {
        id: 'tickets',
        Header: 'Билеты',
        accessor: 'tickets',
        Cell: ({ value }) => (
          <div>
            {!value?.length && '-'}
            {value?.map((link, index) => (
              <div key={index}>
                <a rel='noreferrer' href={link} target='_blank'>
                  Билет №{index + 1}
                </a>
              </div>
            ))}
          </div>
        )
      }
    ].filter(
      (item) => selectedColumns.includes(item.id) || item.id === 'select'
    )
  }, [
    filter,
    paymentTypes,
    selectedColumns,
    handleUploadFile,
    handleChangeSort,
    selectedTransactions,
    handleSelectTransaction
  ])

  return (
    <div>
      <h1>{t('Finance', 'menu')}</h1>

      <Row>
        <Col md={4}>
          <TextControl
            label={t('LabelSearch')}
            value={filter.text}
            onChange={handleChange('text')}
            useFormik={false}
            placeholder={t('LabelSearchPlaceholder')}
          />
        </Col>
        <Col md={4}>
          <SelectControl
            label={t('LabelStatus')}
            value={filter.states}
            options={statuses}
            onChange={handleChange('states')}
            multiple
            useFormik={false}
          />
        </Col>
        <Col md={4}>
          <SelectControl
            label={t('LabelType')}
            value={filter.types}
            options={paymentTypes}
            multiple
            onChange={handleChange('types')}
            useFormik={false}
          />
        </Col>
        <Col md={4}>
          <Form.Label>{t('LabelDateEvent')}</Form.Label>
          <Row>
            <Col md={6}>
              <DateControl
                value={filter.dateStart}
                onChange={handleChange('dateStart')}
              />
            </Col>
            <Col md={6}>
              <DateControl
                value={filter.dateEnd}
                onChange={handleChange('dateEnd')}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Label>{t('LabelDatePayment')}</Form.Label>
          <Row>
            <Col md={6}>
              <DateControl
                value={filter.paymentDateStart}
                onChange={handleChange('paymentDateStart')}
              />
            </Col>
            <Col md={6}>
              <DateControl
                value={filter.paymentDateEnd}
                onChange={handleChange('paymentDateEnd')}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4} className='d-flex align-items-end justify-content-between'>
          <SelectCheckboxControl
            value={selectedColumns}
            options={COLUMNS}
            onChange={setSelectedColumns}
          />
          <Button
            onClick={handleChangeStatus}
            disabled={!selectedTransactions.length}
            className='mb-4'
          >
            Изменить статус
          </Button>
        </Col>
      </Row>

      <LoadingWrapper loading={loading}>
        <Table
          data={transactions}
          columns={columns}
          onChange={handleChangePagination}
          canShowAll
          scrollbale
          pagination={pagination}
        />
      </LoadingWrapper>

      <div style={{ height: 80 }} />

      {total && (
        <div
          style={{
            left: 0,
            right: 0,
            height: 80,
            bottom: 0,
            padding: '0 40px',
            display: 'flex',
            position: 'fixed',
            borderTop: '1px solid #979797',
            alignItems: 'center',
            backgroundColor: 'white'
          }}
        >
          Общая сумма: {total.commonFullSum || '-'}
          {/* <Button variant='outline-light' className='ml-auto ms-2'>
            <img src='/static/svg/trending-up.svg' />
          </Button>

          <Button>
            Скачать таблицу
          </Button> */}
        </div>
      )}
    </div>
  )
}

export default FinancePage

const SortButton = (props) => {
  const { onClick, direction } = props

  return (
    <button
      onClick={onClick}
      type='button'
      style={{
        padding: '0 10px',
        display: 'flex',
        border: 'none',
        outline: 'none',
        flexDirection: 'column',
        backgroundColor: 'transparent'
      }}
    >
      <span
        style={{
          color: direction === 'ASC' ? '#EE3B46' : '#3A4046'
        }}
      >
        ︿
      </span>
      <span
        style={{
          color: direction === 'DESC' ? '#EE3B46' : '#3A4046'
        }}
      >
        ﹀
      </span>
    </button>
  )
}
