import clsx from 'clsx'
import './styles.scss'

const Rating = (props) => {
  const { value } = props

  return (
    <div className='b-rating'>
      {[1, 2, 3, 4, 5].map((rate) => (
        <div
          className={clsx(
            'b-rating__star',
            value >= rate && 'b-rating__star--filled'
          )}
        />
      ))}

      <div className='b-rating__value'>{value}</div>
    </div>
  )
}

export default Rating
