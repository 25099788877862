import clsx from 'clsx'
import { forwardRef, Children } from 'react'
import { Form, Button, Dropdown } from 'react-bootstrap'

import iconSettings from 'images/icon-settings.svg'

const SelectCheckboxControl = (props) => {
  const { label, value, options = [], onChange, className } = props

  const handleSelect = (key) => () => {
    let selected = value ? [...value] : []
    if (value?.includes(key)) {
      const index = value.findIndex((item) => item === key)
      selected.splice(index, 1)
    } else {
      selected.push(key)
    }
    if (onChange) {
      onChange(selected)
    }
  }

  const handleSelectAll = () => {
    if (onChange) {
      onChange(options.map((option) => option.value))
    }
  }

  return (
    <Form.Group className={clsx('mb-4', className)}>
      {label && <Form.Label>{label}</Form.Label>}
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
          Custom toggle
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} onSelectAll={handleSelectAll}>
          {options.map((option) => (
            <Dropdown.Item
              key={option.value}
              active={value?.includes(option.value)}
              onClick={handleSelect(option.value)}
              eventKey={option.value}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  )
}

export default SelectCheckboxControl

const CustomMenu = forwardRef((props, ref) => {
  const {
    style,
    children,
    className,
    onSelectAll,
    'aria-labelledby': labeledBy
  } = props

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul
        className='list-unstyled'
        style={{
          minWidth: 300,
          maxHeight: 300,
          overflow: 'auto'
        }}
      >
        {Children.toArray(children).map((child, index) => (
          <div
            key={index}
            onClick={child.props.onClick}
            className='d-flex justify-content-between align-items-center'
            style={{
              padding: '0.2rem 1rem'
            }}
          >
            <span>{child.props.children}</span>
            <Form.Check type='checkbox' custom checked={child.props.active} />
          </div>
        ))}
      </ul>

      <Button variant='link' className='w-100' onClick={onSelectAll}>
        Выбрать все
      </Button>
    </div>
  )
})

const CustomToggle = forwardRef(({ onClick }, ref) => (
  <Button ref={ref} variant='outline-light' onClick={onClick}>
    <img alt='' src={iconSettings} />
  </Button>
))
