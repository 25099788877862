import Layout from 'components/Layout'
import ReactNotification from 'react-notifications-component'
import LocaleService, { t } from 'utils/localization'
import { BrowserRouter } from 'react-router-dom'
import { ProvideAppContext } from 'utils/hooks/useContext'
import { useState, useEffect } from 'react'

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    LocaleService.init()
      .then(() => {
        document.title = t('AdminPage', 'menu')
        setIsLoaded(true)
      })
      .catch((error) => console.log(error))
  }, [])

  return (
    <ProvideAppContext>
      <ReactNotification />

      <BrowserRouter>{isLoaded && <Layout />}</BrowserRouter>
    </ProvideAppContext>
  )
}

export default App
