import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/localization'
import { Alert } from 'react-bootstrap'

import './styles.scss'

export const RequiredSpan = () => {
  return <span className='text-danger'> *</span>
}

export const ErrorLabel = (props) => {
  const { error } = props

  return <span className='control-error-message'>{error}</span>
}

ErrorLabel.propTypes = {
  error: PropTypes.string.isRequired
}

const ErrorMessage = (props) => {
  const { error, onClose, closable } = props
  const { fields, message } = error || {}

  let errors
  let title = fields
    ? t('FieldErrorMessage', 'validation')
    : message
    ? t('ErrorMessage', 'validation')
    : ''
  if (fields) {
    errors = Object.keys(fields).map((key) => (
      <div key={key}>- {fields[key]}</div>
    ))
  } else if (message) {
    errors = <div>{message}</div>
  }

  return !errors ? null : (
    <div className='b-error'>
      <Alert variant='danger' onClose={onClose} dismissible={!!closable}>
        <Alert.Heading>
          <span className='text-md'>{title}</span>
        </Alert.Heading>
        <div className='text-md mb-0'>{errors}</div>
      </Alert>
    </div>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.object,
  onClose: PropTypes.func,
  closable: PropTypes.bool
}

export default ErrorMessage
