import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import Empty from 'components/Empty'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import SelectControl from 'components/SelectControl'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { LANGUAGE } from 'utils/constants'
import { FALLBACK } from 'utils/localization/config'
import { useState } from 'react'
import { useFormik } from 'formik'
import { Button, Tabs, Tab, Row, Col, Card } from 'react-bootstrap'

const BannersSettings = (props) => {
  const { banners = [], getData } = props

  const [data, setData] = useState({})
  const [modal, setModal] = useState({ id: null, open: false })
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const PAGES = [{ label: t('PageMain', 'menu'), value: 'mainPage' }]

  const getPage = (code) => {
    const page = PAGES.find((page) => page.value === code)
    return page ? page.label : ''
  }

  const getHtmlInitial = () => {
    const shape = {}
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] = data.html ? data.html[code] || '' : ''
    })
    return shape
  }

  const getHtmlShape = () => {
    const shape = {}
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] =
        code === FALLBACK
          ? Yup.string().required(t('Required', 'validation'))
          : Yup.string().notRequired()
    })
    return shape
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: {
      css: data.css || '',
      page: data.page || PAGES[0].value,
      html: getHtmlInitial()
    },
    validationSchema: Yup.object().shape({
      css: Yup.string().notRequired(),
      html: Yup.object().shape(getHtmlShape()),
      page: Yup.string().required(t('Required', 'validation'))
    }),
    enableReinitialize: true
  })

  const toggleModal =
    (open, id = null) =>
    async () => {
      setModal({ open, id })
      if (open && id) {
        try {
          setLoading(true)
          const res = await axios.get(`/admin/banner/${id}`)
          setData(res.data)
        } catch (error) {
          /* empty */
        } finally {
          setLoading(false)
        }
      }
      if (!open) {
        setData({})
        setError(null)
      }
    }

  const handleSubmit = async (values) => {
    try {
      setError(false)
      setLoading(true)

      await axios({
        url: `/admin/banner/${modal.id || ''}`,
        data: values,
        method: modal.id ? 'put' : 'post'
      })

      getData()
      alert.success()
    } catch (error) {
      setError(error)
      alert.error()
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    if (modal.id) {
      try {
        if (await confirm(t('ConfirmBannerDelete'))) {
          setError(false)
          setLoading(true)

          await axios.delete(`/admin/banner/${modal.id}`)

          getData()
          toggleModal(false)()
          alert.success()
        }
      } catch (error) {
        setError(error)
        setLoading(false)
        alert.error()
      }
    }
  }

  const handleChangeField = (name) => (value) => {
    setFieldValue(name, value)
  }

  return (
    <>
      <Button className='mb-4' onClick={toggleModal(true)}>
        {t('AddBanner')}
      </Button>
      <Row>
        {!banners.length && (
          <Col lg={12}>
            <Empty />
          </Col>
        )}
        <Col lg={6}>
          {banners.map((banner) => (
            <Card key={banner.id} className='mb-2'>
              <Card.Body style={{ padding: '1rem' }}>
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    <Card.Title>
                      {t('LabelBanner')} №{banner.id}
                    </Card.Title>
                    <Card.Text>{getPage(banner.page)}</Card.Text>
                  </div>
                  <Button
                    variant='primary'
                    onClick={toggleModal(true, banner.id)}
                  >
                    {t('Edit')}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>

      <Modal
        open={modal.open}
        title={t(modal.id ? 'Edit' : 'Create')}
        onClose={toggleModal(false)}
        loading={loading}
      >
        <form onSubmit={onSubmit}>
          <ErrorMessage error={error} />

          <SelectControl
            name='page'
            label={t('LabelPage')}
            value={values.page}
            error={touched.page && errors.page}
            options={PAGES}
            onChange={handleChangeField('page')}
          />

          <TextControl
            as='textarea'
            rows={6}
            name='css'
            label='CSS'
            value={values.css}
            error={touched.css && errors.css}
            onChange={handleChange}
          />

          <Tabs className='mt-4 mb-4' defaultActiveKey={FALLBACK}>
            {LANGUAGE.getLanguages().map((lang) => (
              <Tab key={lang.code} eventKey={lang.code} title={lang.name}>
                <TextControl
                  as='textarea'
                  rows={6}
                  name={'html.' + lang.code}
                  label='HTML'
                  value={values.html[lang.code]}
                  error={
                    touched.html &&
                    touched.html[lang.code] &&
                    errors.html &&
                    errors.html[lang.code]
                  }
                  onChange={handleChange}
                />
              </Tab>
            ))}
          </Tabs>

          <div className='b-modal__footer'>
            {modal.id && (
              <Button
                variant='danger'
                className='ms-auto'
                onClick={handleDelete}
              >
                {t('Delete')}
              </Button>
            )}
            <Button type='submit'>{t('Save')}</Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default BannersSettings
