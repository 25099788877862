import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import Table from 'components/Table'
import CrudActions from 'components/CrudActions'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { LANGUAGE } from 'utils/constants'
import { FALLBACK } from 'utils/localization/config'
import { useFormik } from 'formik'
import { objectToUrlParams } from 'utils/helpers'
import { useDebouncedCallback } from 'use-debounce'
import { Button, Alert, Tabs, Tab } from 'react-bootstrap'
import { useMemo, useState, useEffect, useCallback } from 'react'

const SeoPage = () => {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [modal, setModal] = useState({ open: false, data: {} })

  const [loading, setLoading] = useState(false)
  const [mLoading, setMLoading] = useState(false)

  const [filter, setFilter] = useState({ page: 1, search: '', rowsPerPage: 10 })
  const [pagination, setPagination] = useState({})

  const getInitial = () => {
    const initial = {
      pageCode: modal.data?.pageCode || '',
      pageName: modal.data?.pageName || ''
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      initial[code] = {
        title: modal.data[code]?.title || '',
        keywords: modal.data[code]?.keywords || '',
        description: modal.data[code]?.description || ''
      }
    })
    return initial
  }

  const getShape = () => {
    const shape = {
      pageCode: Yup.string().required(t('Required', 'validation')),
      pageName: Yup.string().required(t('Required', 'validation'))
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] = Yup.object().shape({
        title:
          code === FALLBACK
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired(),
        keywords:
          code === FALLBACK
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired(),
        description:
          code === FALLBACK
            ? Yup.string()
                .required(t('Required', 'validation'))
                .max(150, t('MaxSymbols', 'validation', { value: 150 }))
            : Yup.string()
                .notRequired()
                .max(150, t('MaxSymbols', 'validation', { value: 150 }))
      })
    })
    return shape
  }

  const {
    dirty,
    values,
    errors,
    touched,
    resetForm,
    handleChange,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: getInitial(),
    validationSchema: Yup.object().shape(getShape()),
    enableReinitialize: true
  })

  const getList = useDebouncedCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/admin/seo?${objectToUrlParams(filter)}`)
      setData(res.data)
      setPagination(res.pagination)
    } catch {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getList()
  }, [filter, getList])

  const handleSubmit = async (values) => {
    if (dirty) {
      try {
        setError(null)
        setMLoading(true)

        const res = await axios({
          url: `/admin/seo${modal.data?.id ? `/${modal.data.pageCode}` : ''}`,
          data: values,
          method: modal.data?.id ? 'put' : 'post'
        })

        getList()
        setModal({ ...modal, data: res.data })

        alert.success()
      } catch (error) {
        setError(error)
        alert.error()
      } finally {
        setMLoading(false)
      }
    }
  }

  const handleDelete = useCallback(
    (code) => async () => {
      if (await confirm(t('ConfirmSettingsDelete'))) {
        try {
          setLoading(true)
          await axios.delete(`/admin/seo/${code}`)
          getList()
          alert.success()
        } catch (error) {
          setError(error)
          setLoading(false)
          alert.error()
        }
      }
    },
    [getList]
  )

  const toggleModal = useCallback(
    (open, data = {}) =>
      () => {
        setModal({ open, data })
        if (!open) {
          resetForm()
          setError(null)
        }
      },
    [resetForm]
  )

  const handleChangeSearch = (value) => {
    setFilter({ ...filter, search: value })
  }

  const handleChangeTable = ({ pagination }) =>
    setFilter({ ...filter, ...pagination })

  const columns = useMemo(
    () => [
      {
        Header: t('TableTitlePageCode'),
        accessor: 'pageCode'
      },
      {
        Header: t('LabelFirstName'),
        accessor: 'pageName'
      },
      {
        Header: t('LabelTitle'),
        accessor: 'ru.title'
      },
      {
        Header: t('TableTitlePageKeywords'),
        accessor: 'ru.keywords'
      },
      {
        Header: t('LabelDescription'),
        accessor: 'ru.description'
      },
      {
        Header: '',
        id: 'actions',
        Cell: ({ row: { original } }) => (
          <CrudActions
            onEdit={toggleModal(true, original)}
            onDelete={handleDelete(original.pageCode)}
          />
        )
      }
    ],
    [toggleModal, handleDelete]
  )

  return (
    <>
      <h1>{t('SEO', 'menu')}</h1>

      <LoadingWrapper loading={loading}>
        <div className='d-flex align-items-start'>
          <div className='w-100 me-2'>
            <TextControl
              name='search'
              value={filter.search}
              onChange={handleChangeSearch}
              useFormik={false}
              placeholder={t('LabelSearch')}
            />
          </div>
          <Button onClick={toggleModal(true)}>{t('Add')}</Button>
        </div>

        <Table
          data={data}
          columns={columns}
          onChange={handleChangeTable}
          pagination={pagination}
        />
      </LoadingWrapper>

      <Modal
        open={modal.open}
        title={t('SeoSettings')}
        loading={mLoading}
        onClose={toggleModal(false)}
      >
        <div>
          <Alert variant='warning'>{t('PageCodeHint')}</Alert>

          <form onSubmit={onSubmit}>
            <ErrorMessage error={error} />

            <TextControl
              name='pageCode'
              label={t('TableTitlePageCode')}
              value={values.pageCode}
              error={touched.pageCode && errors.pageCode}
              onChange={handleChange}
              readOnly={!!modal.data.id}
              required
            />
            <TextControl
              name='pageName'
              label={t('LabelFirstName')}
              value={values.pageName}
              error={touched.pageName && errors.pageName}
              onChange={handleChange}
              required
            />

            <Tabs className='mt-4 mb-4' defaultActiveKey={FALLBACK}>
              {LANGUAGE.getLanguages().map((lang) => (
                <Tab key={lang.code} eventKey={lang.code} title={lang.name}>
                  <TextControl
                    name={`${lang.code}.title`}
                    label={t('LabelTitle')}
                    value={values[lang.code]?.title}
                    error={
                      touched?.[lang.code]?.title && errors?.[lang.code]?.title
                    }
                    onChange={handleChange}
                    required={lang.code === FALLBACK}
                  />
                  <TextControl
                    name={`${lang.code}.keywords`}
                    label={t('TableTitlePageKeywords')}
                    value={values[lang.code]?.keywords}
                    error={
                      touched?.[lang.code]?.keywords &&
                      errors?.[lang.code]?.keywords
                    }
                    onChange={handleChange}
                    required={lang.code === FALLBACK}
                  />
                  <TextControl
                    name={`${lang.code}.description`}
                    label={t('LabelDescription')}
                    value={values[lang.code]?.description}
                    error={
                      touched?.[lang.code]?.description &&
                      errors?.[lang.code]?.description
                    }
                    onChange={handleChange}
                    required={lang.code === FALLBACK}
                  />
                </Tab>
              ))}
            </Tabs>

            <div className='b-modal__footer'>
              <Button
                variant='default'
                onClick={toggleModal(false)}
                className='ms-auto'
              >
                {t('Cancel')}
              </Button>
              <Button type='submit'>{t('Save')}</Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default SeoPage
