import { useMemo } from 'react'
import clsx from 'clsx'
import { t } from 'utils/localization'
import '../../styles.scss'

const TourTypes = (props) => {
  const { onChange, value, priceType } = props
  const TYPES = [
    { type: 'isGroup', label: t('GroupTourType', 'common') },
    {
      type: 'isIndividualWithGuide',
      label: t('IndividualWithGuideTourType', 'common')
    },
    {
      type: 'isIndividualWithoutGuide',
      label: t('IndividualWithoutGuideTourType', 'common')
    }
  ]

  const availableTypes = useMemo(() => {
    return Object.entries(priceType).flatMap((type) =>
      type[1] === true ? type[0] : []
    )
  }, [priceType])

  const handleChange = (value) => () => {
    if (availableTypes.includes(value)) {
      onChange(value)
    }
  }

  return (
    <div className='b-tour-types'>
      {TYPES.map((i) => (
        <div
          key={i.type}
          className={clsx(
            'b-tour-types__button',
            i.type === value && 'b-tour-types__button--selected',
            !availableTypes.includes(i.type) && 'b-tour-types__button--disabled'
          )}
          onClick={handleChange(i.type)}
        >
          {i.label}
        </div>
      ))}
    </div>
  )
}

export default TourTypes
