import { isValidPhoneNumber } from 'react-phone-number-input'
import ControlInputGroup from 'components/ControlInputGroup'
import React, { useState, useEffect, useMemo } from 'react'
import PeopleCounter from './components/PeopleCounter'
import SelectControl from 'components/SelectControl'
import { alert } from 'components/Dialog'
import FreeDatePeopleCounter from './components/FreeDatePeopleCounter'
import FreeDatePeopleRange from './components/FreeDatePeopleRange'
import moment from 'moment'

import PeopleRange from './components/PeopleRange'
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap'
import TextControl from 'components/TextControl'
import TourTypes from './components/TourTypes'
import { useCreateOrderApi } from '../api'
import { t } from 'utils/localization'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams, useHistory } from 'react-router-dom'
import '../styles.scss'

const CURRENCY = [
  { label: 'Армянский драм', value: 'AMD' },
  { label: 'Доллар США', value: 'USD' },
  { label: 'Евро', value: 'EUR' },
  { label: 'Российский рубль', value: 'RUB' },
  { label: 'Грузинский лари', value: 'GEL' },
  { label: 'Китайский юань', value: 'CNY' },
  { label: 'Иранский риал', value: 'IRR' }
]

const CARD_TYPES = [
  { label: 'MIR', value: 'mir' },
  { label: 'ArCa, American Express, MasterCard, VISA', value: 'any' }
]

const validateDate = (date) => {
  if (moment(date, 'DD.MM.YYYY') <= moment('DD.MM.YYYY')) {
    return false
  } else return true
}

const validateTime = (date, time) => {
  if (!date) return false

  if (
    moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm') <
    moment(new Date(), 'DD.MM.YYYY HH:mm')
  ) {
    return false
  } else return true
}

const getLanguagesOptions = (languages) => {
  const options = []
  for (const lang of languages) {
    switch (lang) {
      case 'ru':
        options.push({ label: 'Русский', value: 'ru' })
        break
      case 'hy':
        options.push({ label: 'Армянский', value: 'hy' })
        break
      case 'en':
        options.push({ label: 'Английский', value: 'en' })
        break
      default:
        return null
    }
  }
  return options
}

const CreateOrderPage = () => {
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [periods, setPeriods] = useState([])
  const [availableTimes, setAvailableTimes] = useState([])
  const [selectedPeriod, setSelectedPeriod] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [guideLanguages, setGuideLanguages] = useState([])
  const [total, setTotal] = useState(0)
  const [anotherTotal, setAnotherTotal] = useState(0)
  const [tabState, setTabState] = useState('existDate')
  const [selectedCurrency, setSelectedCurrency] = useState('AMD')

  const params = useParams()
  const history = useHistory()

  const {
    fetchProducts,
    fetchProduct,
    fetchPeriods,
    fetchTimes,
    fetchNewOrder,
    fetchMoneyExchange
  } = useCreateOrderApi()

  const getProducts = async () => {
    const res = await fetchProducts()
    setProducts(res)
  }

  const getProduct = async (id) => {
    const prod = await fetchProduct(id)
    setSelectedProduct(prod)
  }

  const getTimes = async (id, date, type) => {
    const t = await fetchTimes(id, date, type)
    setAvailableTimes(t)
  }

  const getPeriods = async (id, type) => {
    const per = await fetchPeriods(id, type)
    setPeriods(per)
  }

  const getExchange = async (money) => {
    const exchange = await fetchMoneyExchange(money)
    setAnotherTotal(exchange?.[selectedCurrency])
  }

  const productOptions = useMemo(() => {
    return products?.map((prod) => ({ label: prod.title, value: prod.id }))
  }, [products])

  const dateOptions = useMemo(() => {
    return periods?.map((date) => ({ label: date.date, value: date.date }))
  }, [periods])

  const timesOptions = useMemo(() => {
    return availableTimes?.map((time) => ({
      label: time.time,
      value: time.time
    }))
  }, [availableTimes])

  // получаем все продукты
  useEffect(() => {
    getProducts()
    if (params?.id) {
      setFieldValue('productId', params.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    values,
    touched,
    setFieldValue,
    errors,
    handleSubmit: onSubmit,
    resetForm
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: {
      type: 'card',
      cardType: 'any',
      name: '',
      email: '',
      phone: '',
      currency: 'AMD',
      priceType: '',
      productId: '',
      dateStart: '',
      timeStart: '',
      adultCount: 0,
      childCount: 0,
      peopleRange: '',
      guideLanguage: '',
      childPrice: null,
      adultPrice: null,
      price: null,
      isArmenianResident: false
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('Required', 'validation')),
      email: Yup.string()
        .email(t('InvalidEmail', 'validation'))
        .required(t('Required', 'validation')),
      phone: Yup.string()
        .test('check-phone', t('InvalidPhone', 'validation'), (value) => {
          return value ? isValidPhoneNumber(value) : false
        })
        .required(t('Required', 'validation')),
      productId: Yup.number().required(t('Required', 'validation')),
      currency: Yup.string().required(t('Required', 'validation')),
      priceType: Yup.string().required(t('Required', 'validation')),
      cardType: Yup.string().required(t('Required', 'validation')),
      dateStart: Yup.string()
        .required(t('Required', 'validation'))
        .test(
          'validate-date',
          t('TimeStartEarlierCurrent', 'validation'),
          (self) => {
            return validateDate(self)
          }
        )
        .test('isvalid-date', t('InvalidDate', 'validation'), (self) => {
          return moment(self, 'DD.MM.YYYY').isValid()
        }),
      timeStart: Yup.string()
        .required(t('Required', 'validation'))
        .test(
          'validate-time',
          t('DateStartEarlierCurrent', 'validation'),
          (self, { parent }) => {
            return validateTime(parent.dateStart, self)
          }
        )
        .test('isvalid-date', t('InvalidTime', 'validation'), (self) => {
          return moment(self, 'HH:mm').isValid()
        }),
      adultCount: Yup.number().test(
        'priceType',
        t('Required', 'validation'),
        (self, { parent }) => {
          return parent.priceType === 'isGroup'
            ? parent.childCount
              ? true
              : self > 0
            : true
        }
      ),
      childCount: Yup.number().test(
        'priceType',
        t('Required', 'validation'),
        (self, { parent }) => {
          return parent.priceType === 'isGroup'
            ? parent.adultCount
              ? true
              : self > 0
            : true
        }
      ),
      peopleRange: Yup.string()
        .when('priceType', {
          is: 'isIndividualWithGuide',
          then: Yup.string().required(t('Required', 'validation'))
        })
        .when('priceType', {
          is: 'isIndividualWithoutGuide',
          then: Yup.string().required(t('Required', 'validation'))
        }),
      guideLanguage: Yup.string()
        .when('priceType', {
          is: 'isIndividualWithGuide',
          then: Yup.string().required(t('Required', 'validation'))
        })
        .when('priceType', {
          is: 'isGroup',
          then: Yup.string().required(t('Required', 'validation'))
        }),
      childPrice: Yup.mixed().test(
        'checkChildPrice',
        t('Required', 'validation'),
        (value, { parent }) => {
          return tabState === 'freeDate'
            ? parent.childCount > 0
              ? value > 0
              : true
            : true
        }
      ),
      adultPrice: Yup.mixed().test(
        'checkAdultPrice',
        t('Required', 'validation'),
        (value, { parent }) => {
          return tabState === 'freeDate'
            ? parent.adultCount > 0
              ? value > 0
              : true
            : true
        }
      ),
      price: Yup.mixed().test(
        'checkRangePrice',
        t('Required', 'validation'),
        (value, { parent }) => {
          return tabState === 'freeDate'
            ? parent.priceType !== 'isGroup'
              ? value > 0
              : true
            : true
        }
      ),
      isArmenianResident: Yup.boolean().notRequired()
    })
  })

  //! считаем конечную цену
  useEffect(() => {
    if (tabState === 'existDate') {
      if (selectedTime) {
        if (values.priceType === 'isIndividualWithGuide') {
          const [, max] = values?.peopleRange.split('-')
          setTotal(
            selectedTime?.pricing?.price.find(
              (item) => item.maxPeople === Number(max)
            )?.guidePrice[values.currency]
          )
        } else if (values.priceType === 'inIndividualWithoutGuide') {
          const [, max] = values?.peopleRange.split('-')
          setTotal(
            selectedTime?.pricing?.price.find(
              (item) => item.maxPeople === Number(max)
            )?.price[values.currency]
          )
        } else if (values.priceType === 'isGroup') {
          let childPrice = 0
          let adultPrice = 0
          if (selectedProduct.canChild) {
            childPrice =
              Number(selectedTime?.pricing?.childPrice[values.currency]) *
              Number(values.childCount)
          }
          if (values.isArmenianResident) {
            adultPrice =
              Number(
                selectedTime?.pricing.armenianResidentPrice[values.currency]
              ) * Number(values.adultCount)
          } else {
            adultPrice =
              Number(selectedTime?.pricing.adultPrice[values.currency]) *
              Number(values.adultCount)
          }
          setTotal(childPrice + adultPrice)
        }
      }
    } else if (tabState === 'freeDate') {
      if (values.priceType === 'isGroup') {
        setTotal(
          Number(values.adultCount) * Number(values.adultPrice) +
            Number(values.childCount) * Number(values.childPrice)
        )
      } else if (
        values.priceType === 'isIndividualWithGuide' ||
        values.priceType === 'isIndividualWithoutGuide'
      ) {
        setTotal(Number(values?.price))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.adultCount,
    values.childCount,
    values.peopleRange,
    values.isArmenianResident,
    values.childPrice,
    values.adultPrice,
    values.price
  ])

  //! считаем цену для выбранной валюты
  useEffect(() => {
    if (values.currency !== selectedCurrency) {
      getExchange(total)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency, total])

  //! Отправка формы
  const handleSubmit = async (value) => {
    const data = { ...value }
    if (data.childCount === '') data.childCount = 0
    if (data.adultCount === '') data.adultCount = 0
    if (data.childPrice === '') data.childPrice = 0
    if (data.adultPrice === '') data.adultPrice = 0
    const result = await fetchNewOrder(data)
    await alert('Сслыка на оплату', { value: result.redirectUrl }).then(() => {
      history.push('/orders')
    })
  }

  // получает массив строк (значения формика) и сбрысывает все значения
  const resetFields = (arrOfFields) => {
    for (const field of arrOfFields) {
      if (field === 'isArmenianResident') {
        setFieldValue(field, false)
      } else {
        setFieldValue(field, '')
      }
    }
  }

  // сбрасывает все что связано с датой и временем
  const resetTimesAndDates = () => {
    setPeriods([])
    setSelectedPeriod(null)
    setAvailableTimes([])
    setSelectedTime(null)
    setTotal(0)
    setAnotherTotal(0)
  }

  const setFields = (objectOfFields) => {
    for (let [key, value] of Object.entries(objectOfFields)) {
      setFieldValue(key, value)
    }
  }

  //! срабатывает только при изменении продукта в Select-e
  useEffect(() => {
    if (values.productId) {
      const tmp = {
        productId: values.productId,
        name: values.name,
        email: values.email,
        phone: values.phone
      }
      // сбрасываем форму чтобы небыло конфилктов
      resetForm()
      resetTimesAndDates()
      setFields(tmp)
      // получаем продукт из API по id
      getProduct(values.productId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.productId])

  //! срабатывает только при измении типа тура (Групповой/Индвивидуальны.../...)
  useEffect(() => {
    if (values.priceType && tabState === 'existDate') {
      // сбрасываем некоторые поля в форме, чтобы небыло конфилктов
      resetFields([
        'dateStart',
        'timeStart',
        'adultCount',
        'childCount',
        'peopleRange',
        'isArmenianResident',
        'guideLanguage'
      ])
      resetTimesAndDates()
      // при зменении типа обновляем список доступных языков гида
      if (values.priceType === 'isIndividualWithGuide') {
        const languages = selectedProduct?.guideTypes
          .filter((type) => type.type === 'individual')
          .map((item) => item.locale)
        const options = getLanguagesOptions(languages)
        setGuideLanguages(options)
      } else if (values.priceType === 'isGroup') {
        const languages = selectedProduct?.guideTypes
          .filter((type) => type.type === 'group')
          .map((item) => item.locale)
        const options = getLanguagesOptions(languages)
        setGuideLanguages(options)
      } else {
        setGuideLanguages([])
      }
      // пролучаем все периоды для данного типа
      getPeriods(values.productId, values.priceType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.priceType, tabState])

  //! Срабатывает при изменении даты
  useEffect(() => {
    if (values.dateStart && tabState === 'existDate') {
      //сбрасываем занчения чтобы небыло конфликтов
      resetFields([
        'timeStart',
        'adultCount',
        'childCount',
        'peopleRange',
        'isArmenianResident'
      ])
      // сохр. в стейт выбранный период (дату)
      setSelectedPeriod(periods.find((item) => item.date === values.dateStart))
      // полчуем доступное время для данного периода
      getTimes(values.productId, values.dateStart, values.priceType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dateStart, tabState])

  //! Срабатывает при изменении времени
  useEffect(() => {
    if (values.timeStart && tabState === 'existDate') {
      // сохр. в стейт выбранное время
      setSelectedTime(
        availableTimes.find((item) => item.time === values.timeStart)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.timeStart])

  // меняет значение в форме
  const handleChangeField = (name) => (value) => {
    if (name === 'isArmenianResident') {
      setFieldValue(name, value.target.checked)
    } else {
      setFieldValue(name, value.target.value)
    }
  }

  // для смены значений у селекта
  const handleChangeSelect = (name) => (value) => {
    setFieldValue(name, value)
  }

  const handleChangeCurrency = (value) => {
    setSelectedCurrency(value)
  }
  // для смены значения количества людей
  const handleChangeCount = (val) => {
    setFieldValue(val.person, val.count)
  }

  const handleOnPriceChange = (value) => {
    setFieldValue(value.person, value.price)
  }

  // при смене табов "Существующая/свободная дата"
  const handleChangeTab = (key) => {
    resetFields([
      'dateStart',
      'timeStart',
      'adultPrice',
      'adultCount',
      'childPrice',
      'childCount'
    ])
    setFieldValue('isArmenianResident', false)
    setTotal(0)
    setTabState(key)
  }

  return (
    <div>
      <h1>{t('CreateOrder', 'menu')}</h1>
      <form onSubmit={onSubmit}>
        <Row>
          <Col md={4}>
            <TextControl
              label={t('OrderUserName', 'common')}
              onChange={handleChangeField('name')}
              value={values.name}
              error={touched.name && errors.name}
            />
          </Col>
          <Col md={4}>
            <TextControl
              label={t('OrderUserEmail', 'common')}
              onChange={handleChangeField('email')}
              value={values.email}
              error={touched.email && errors.email}
            />
          </Col>
          <Col md={4}>
            <TextControl
              label={t('OrderUserPhone', 'common')}
              onChange={handleChangeField('phone')}
              value={values.phone}
              error={touched.phone && errors.phone}
            />
          </Col>
          <Col md={12}>
            <SelectControl
              label={t('OrderTour', 'common')}
              value={values.productId}
              onChange={handleChangeSelect('productId')}
              error={touched.productId && errors.productId}
              options={productOptions}
            />
            <TourTypes
              value={values.priceType}
              priceType={
                selectedProduct?.priceTypes ? selectedProduct.priceTypes : []
              }
              onChange={handleChangeSelect('priceType')}
            />
          </Col>
          <Col md={4}>
            <SelectControl
              value={values.cardType}
              options={CARD_TYPES}
              label={t('OrderCardType', 'common')}
              error={touched.cardType && errors.cardType}
              onChange={handleChangeSelect('cardType')}
            />
          </Col>
          <Col md={4}>
            <SelectControl
              value={selectedCurrency}
              options={CURRENCY}
              label={t('OrderCurrency', 'common')}
              // error={touched.currency && errors.currency}
              onChange={handleChangeCurrency}
            />
          </Col>
          <Col md={4}>
            {(values.priceType === 'isGroup' ||
              values.priceType === 'isIndividualWithGuide') && (
              <SelectControl
                label={t('OrderGuideLang', 'common')}
                value={values.guideLanguage}
                onChange={handleChangeSelect('guideLanguage')}
                error={touched.guideLanguage && errors.guideLanguage}
                options={guideLanguages}
              />
            )}
          </Col>
          <Tabs
            id='create-order-tab'
            onSelect={handleChangeTab}
            style={{ marginBottom: 20 }}
          >
            <Tab
              eventKey={'existDate'}
              title={t('OrderExistDateTabTitle', 'common')}
            >
              <Row>
                <Col md={6}>
                  <SelectControl
                    label={t('OrderDate', 'common')}
                    value={values.dateStart}
                    onChange={handleChangeSelect('dateStart')}
                    error={touched.dateStart && errors.dateStart}
                    options={dateOptions}
                  />
                </Col>
                <Col md={6}>
                  <SelectControl
                    label={t('OrderTime', 'common')}
                    value={values.timeStart}
                    onChange={handleChangeSelect('timeStart')}
                    error={touched.timeStart && errors.timeStart}
                    options={timesOptions}
                  />
                </Col>

                <Col md={6}>
                  {values.priceType === 'isGroup' && (
                    <PeopleCounter
                      isArmenian={values.isArmenianResident}
                      armenianResidentPrice={
                        selectedTime?.pricing?.armenianResidentPrice
                          ? `${
                              selectedTime?.pricing?.armenianResidentPrice?.[
                                values.currency
                              ]
                            } ${values.currency}`
                          : ''
                      }
                      adultPrice={
                        selectedTime?.pricing?.adultPrice
                          ? `${
                              selectedTime?.pricing?.adultPrice?.[
                                values.currency
                              ]
                            } ${values.currency}`
                          : ''
                      }
                      childPrice={
                        selectedTime?.pricing?.childPrice
                          ? `${
                              selectedTime?.pricing?.childPrice?.[
                                values.currency
                              ]
                            } ${values.currency}`
                          : ''
                      }
                      canChild={
                        selectedPeriod?.payChildFrom
                          ? `с ${selectedPeriod?.payChildFrom} по ${selectedPeriod?.payChildTo}`
                          : ''
                      }
                      onChange={handleChangeCount}
                      places={selectedTime?.places}
                    />
                  )}
                  {(values.priceType === 'isIndividualWithGuide' ||
                    values.priceType === 'isIndividualWithoutGuide') && (
                    <PeopleRange
                      type={values.priceType}
                      value={values.peopleRange}
                      currency={values.currency}
                      prices={selectedTime?.pricing}
                      onChange={handleChangeField('peopleRange')}
                      error={touched.peopleRange && errors.peopleRange}
                    />
                  )}
                </Col>

                <Col md={6}></Col>
                <Col md={6}>
                  {selectedTime?.pricing?.armenianResidentPrice && (
                    <ControlInputGroup
                      type='checkbox'
                      options={[
                        {
                          id: 'isArmenianResident',
                          label: t('OrderIsArmenianResident', 'common'),
                          value: values.isArmenianResident
                        }
                      ]}
                      onChange={handleChangeField('isArmenianResident')}
                      error={
                        touched.isArmenianResident && errors.isArmenianResident
                      }
                    />
                  )}
                </Col>
                <Col md={6}></Col>
              </Row>
            </Tab>
            <Tab
              title={t('OrderFreeDateTabTitle', 'common')}
              eventKey={'freeDate'}
            >
              <Row>
                <Col md={6}>
                  <TextControl
                    label={t('OrderDate', 'common')}
                    value={values.dateStart}
                    onChange={handleChangeField('dateStart')}
                    error={touched.dateStart && errors.dateStart}
                    mask={'99.99.9999'}
                    placeholder='DD.MM.YYYY'
                  />
                </Col>
                <Col md={6}>
                  <TextControl
                    value={values.timeStart}
                    onChange={handleChangeField('timeStart')}
                    error={touched.timeStart && errors.timeStart}
                    label={t('OrderTime', 'common')}
                    mask={'99:99'}
                    placeholder='ЧЧ:ММ'
                  />
                </Col>
                {values.priceType === 'isGroup' && (
                  <Col md={6}>
                    <FreeDatePeopleCounter
                      adultPrice={
                        values?.adultPrice &&
                        `${values?.adultPrice} ${values.currency}`
                      }
                      childPrice={
                        values?.childPrice &&
                        `${values?.childPrice} ${values.currency}`
                      }
                      childError={touched.childPrice && errors.childPrice}
                      adultError={touched.adultPrice && errors.adultPrice}
                      childInputPrice={values.childPrice}
                      adultInputPrice={values.adultPrice}
                      onChange={handleChangeCount}
                      onPriceChange={handleOnPriceChange}
                    />
                  </Col>
                )}
                {(values.priceType === 'isIndividualWithGuide' ||
                  values.priceType === 'isIndividualWithoutGuide') && (
                  <Col md={6}>
                    <FreeDatePeopleRange
                      inputPrice={values.price}
                      inputError={touched.price && errors.price}
                      onChange={handleChangeCount}
                      onPriceChange={handleOnPriceChange}
                    />
                  </Col>
                )}
                <Col md={6}></Col>
                <Col md={6}>
                  {values.priceType && (
                    <ControlInputGroup
                      type='checkbox'
                      options={[
                        {
                          id: 'isArmenianResident',
                          label: t('OrderIsArmenianResident', 'common'),
                          value: values.isArmenianResident
                        }
                      ]}
                      onChange={handleChangeField('isArmenianResident')}
                      error={
                        touched.isArmenianResident && errors.isArmenianResident
                      }
                    />
                  )}
                </Col>
              </Row>
            </Tab>
          </Tabs>
          <Col md={6}>
            {total > 0 && (
              <div className='b-total-price'>
                {t('OrderTotal', 'common')}:
                <span>
                  {total} {values.currency}
                </span>
                {values.currency !== selectedCurrency && (
                  <>
                    <span></span>
                    <span>
                      {anotherTotal} {selectedCurrency}
                    </span>
                  </>
                )}
              </div>
            )}
          </Col>
        </Row>
        <Button type='submit'>{t('Save', 'common')}</Button>
      </form>
    </div>
  )
}

export default CreateOrderPage
