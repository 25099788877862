import { t } from './localization'
import { formatPhoneNumberIntl } from 'react-phone-number-input/input'

export const checkUserRoles = (userRoles, allowedRoles) => {
  const intersections = userRoles.filter((value) =>
    allowedRoles.includes(value)
  )
  return intersections.length || !allowedRoles.length
}

export const chunkArray = (array, chunkSize) => {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

export const getTravelers = (data, joinSymbol = ', ') => {
  if (!data) {
    return ''
  }

  const adults = data.adults || data.adultPlaces || data.adultCount || 0
  const children = data.children || data.childPlaces || data.childCount || 0

  if (data.isIndividual) {
    return `x ${adults + children}`
  } else {
    const temp = []
    if (adults) {
      temp.push(`x ${adults} ${t('Adult')}`)
    }
    if (children) {
      temp.push(`x ${children} ${t('Child')}`)
    }
    return temp.join(joinSymbol)
  }
}

export const deleteFromArray = (array, { index, value, prop }) => {
  let list = [...array]

  if (index != null) {
    list.splice(index, 1)
  } else if (value && prop) {
    const index = list.findIndex((item) => item[prop] === value)
    if (index >= 0) {
      list.splice(index, 1)
    }
  } else if (value) {
    const index = list.findIndex((item) => item === value)
    if (index >= 0) {
      list.splice(index, 1)
    }
  }
  return list
}

export const objectToFormData = (obj, options = {}) => {
  const { printData, rootName, ignoreList, useIndexesInArray } = options

  var formData = new FormData()
  const appendFormData = (data, root) => {
    if (!ignore(root)) {
      root = root || ''
      if (data instanceof File || data instanceof Blob) {
        formData.append(root, data)
      } else if (Array.isArray(data)) {
        data.forEach((item, index) =>
          appendFormData(item, useIndexesInArray ? `${root}[${index}]` : root)
        )
      } else if (typeof data === 'object' && data) {
        for (var key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            appendFormData(data[key], root ? root + '.' + key : key)
          }
        }
      } else if (data !== null && data !== undefined) {
        formData.append(root, data)
      }
    }
  }
  const ignore = (root) => {
    return Array.isArray(ignoreList) && ignoreList.some((x) => x === root)
  }
  appendFormData(obj, rootName || '')

  if (printData) {
    for (var pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1])
    }
  }

  return formData
}

export const objectToUrlParams = (data) => {
  if (!data) {
    return ''
  }

  return Object.keys(data)
    .reduce((parts, key) => {
      if (Array.isArray(data[key]) && data[key].length) {
        parts.push(
          Object.keys(data[key])
            .map(
              (key2) => `${key}[${key2}]=${encodeURIComponent(data[key][key2])}`
            )
            .join('&')
        )
      } else if (typeof data[key] === 'string' && data[key].length) {
        parts.push(`${key}=${encodeURIComponent(data[key])}`)
      } else if (
        typeof data[key] === 'number' ||
        typeof data[key] === 'boolean'
      ) {
        parts.push(`${key}=${data[key]}`)
      }
      return parts
    }, [])
    .join('&')
}

export const getShortText = (text, characters = 200) => {
  if (text) {
    let newText = text
    if (text.length > characters) {
      newText = text.substring(0, characters) + '...'
    }
    return newText
  }
  return ''
}

export const parsePhone = (number) => {
  try {
    const formatted = formatPhoneNumberIntl(number)
    return formatted || number
  } catch {
    return number
  }
}

export const capitalize = (string) => {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}
