import axios from 'utils/axios'
import { objectToUrlParams } from 'utils/helpers'

export const useCreateOrderApi = () => {
  const fetchAllOrders = async (filter) => {
    try {
      const res = await axios.get(`/admin/orders/?${objectToUrlParams(filter)}`)
      return res
    } catch {}
  }

  const generateNewUrl = async (orderId) => {
    try {
      const res = await axios.put(`/admin/payment/${orderId}/generateurl`)
      return res.data
    } catch {}
  }

  const sendUrlToUser = async (orderId, method = 'email') => {
    try {
      const res = await axios.post(
        `/admin/payment/${orderId}/sendurl?method=${method}`
      )
      return res.data
    } catch {}
  }

  const fetchProducts = async () => {
    try {
      const res = await axios.get('public/search/tour')
      return res.data
    } catch {}
  }

  const fetchProduct = async (productId) => {
    try {
      const res = await axios.get(`public/product/${productId}`)
      return res.data
    } catch {}
  }

  const fetchPeriods = async (productId, tourType) => {
    try {
      const res = await axios.get(
        `public/product/${productId}/periods?type=${tourType}`
      )
      return res.data
    } catch {}
  }

  const fetchTimes = async (productId, dateStart, tourType) => {
    try {
      const res = await axios.get(
        `public/product/${productId}/available-time?dateStart=${dateStart}&type=${tourType}`
      )
      return res.data
    } catch {}
  }

  const fetchNewOrder = async (data) => {
    try {
      const res = await axios.post('admin/order?locale=ru', data)
      return res.data
    } catch {}
  }

  const fetchMoneyExchange = async (money) => {
    try {
      const res = await axios.get(`admin/pricelist?locale=ru&value=${money}`)
      return res.data
    } catch {}
  }

  return {
    fetchProducts,
    fetchProduct,
    fetchPeriods,
    fetchTimes,
    fetchNewOrder,
    fetchAllOrders,
    generateNewUrl,
    sendUrlToUser,
    fetchMoneyExchange
  }
}
