import clsx from 'clsx'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { useProfile } from 'utils/hooks/useContext'
import { deleteFromArray } from 'utils/helpers'
import { Badge, Collapse, ListGroup } from 'react-bootstrap'
import { useRef, useState, forwardRef, Fragment } from 'react'
import { useDisableBodyScroll, useOnClickOutside } from 'utils/hooks'

import iconArrow from 'images/select-arrow-dark.svg'
import './styles.scss'

export const MENU_LIST = [
  {
    id: 'content',
    title: 'ContentManagment',
    items: [
      {
        id: '/about',
        title: 'PageAbout'
      },
      {
        id: '/about-armenia',
        title: 'PageAboutArmenia'
      },
      {
        id: '/home',
        title: 'PageMain'
      },
      {
        id: '/terms-of-use',
        title: 'PageTermsOfUse'
      },
      {
        id: '/privacy-policy',
        title: 'PagePrivacyPolicy'
      },
      {
        id: '/files-storage',
        title: 'FileStorage'
      }
    ]
  },
  {
    id: 'users',
    title: 'UsersManagment',
    items: [
      {
        id: '/merchants',
        title: 'PageMerchants',
        section: 'partners'
      },
      {
        id: '/users',
        title: 'PageUsers',
        section: 'newUsers'
      }
    ]
  },
  {
    id: 'finance',
    title: 'Finance',
    items: [
      {
        id: '/finance',
        title: 'Finance'
      }
    ]
  },
  {
    id: 'requests',
    title: 'RequestsManagment',
    items: [
      {
        id: '/orders',
        title: 'Orders',
        section: 'orders'
      },
      {
        id: '/products',
        title: 'Products',
        section: 'products'
      },
      // {
      //   id: 'individual-requests',
      //   title: 'IndividualRequests',
      // },
      {
        id: '/many-day-tour-requests',
        title: 'ManyDayTourRequests',
        section: 'multitours'
      },
      {
        id: '/zero-requests',
        title: 'ZeroRequests',
        section: 'zeroRequests'
      },
      {
        id: '/feedback-requests',
        title: 'FeedbackRequests',
        section: 'appeals'
      },
      {
        id: '/cancel-requests',
        title: 'CancelRequests',
        section: 'cancelOrders'
      },
      {
        id: '/reviews',
        title: 'ReviewsRequests',
        section: 'reviews'
      },
      {
        id: '/product-questions',
        title: 'ProductQuestions',
        section: 'questions'
      }
    ]
  },
  {
    id: 'mailing',
    title: 'MailingManagment',
    items: [
      {
        id: '/mailing-email',
        title: 'MailingManagmentEmails'
      },
      {
        id: '/mailing-push',
        title: 'MailingManagmentPUSH'
      }
    ]
  },
  {
    id: 'settings',
    title: 'SettingsManagment',
    items: [
      {
        id: '/payment-commission',
        title: 'PaymentCommission'
      },
      {
        id: '/promotions',
        title: 'Promotions'
      },
      {
        id: '/seo',
        title: 'SEO'
      },
      {
        id: '/contracts',
        title: 'Contracts'
      }
    ]
  },
  {
    id: 'lists',
    title: 'ListsManagment',
    items: [
      {
        id: '/health-restrictions',
        title: 'HealthRestrictions'
      },
      {
        id: '/tour-components',
        title: 'TourComponents'
      },
      {
        id: '/tour-restrictions',
        title: 'TourRestrictions'
      },
      {
        id: '/product-categories',
        title: 'ProductCategories'
      }
    ]
  }
]

const SideMenu = () => {
  const [open, setOpen] = useState(false)
  const [openSections, setOpenSections] = useState(
    MENU_LIST.filter((item) => item.items.length).map((item) => item.id)
  )

  const ref = useRef()
  const { notificationCount } = useProfile()

  const toggleMenu = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (name) => () => {
    const index = openSections.indexOf(name)
    setOpenSections(
      index > -1
        ? deleteFromArray(openSections, { index })
        : [...openSections, name]
    )
  }

  useOnClickOutside(ref, handleClose)
  useDisableBodyScroll(open)

  return (
    <div className={clsx('b-drawer', open && 'b-drawer--open')}>
      <aside ref={ref} className='b-drawer__body'>
        <div className='b-drawer__container'>
          <div className='b-drawer__content'>
            <ListGroup>
              {MENU_LIST.map((item) => (
                <Fragment key={item.id}>
                  <ListGroup.Item
                    onClick={handleChange(item.id)}
                    className={openSections.includes(item.id) ? 'opened' : ''}
                  >
                    <span>{t(item.title, 'menu')}</span>
                    <img src={iconArrow} alt='' />
                  </ListGroup.Item>
                  <Collapse in={openSections.includes(item.id)}>
                    <ListGroup>
                      {item.items.map((subitem) => {
                        const badge =
                          notificationCount?.find(
                            (s) => s.section === subitem.section
                          )?.count || 0

                        return (
                          <ListGroup.Item
                            as={ListLink}
                            key={subitem.id}
                            href={subitem.id}
                            badge={badge}
                            title={t(subitem.title, 'menu')}
                            nested
                            onClick={toggleMenu}
                          />
                        )
                      })}
                    </ListGroup>
                  </Collapse>
                </Fragment>
              ))}
            </ListGroup>
          </div>
        </div>

        <button onClick={toggleMenu} className='b-drawer__toggle'>
          <div className='b-drawer__toggle-icon' />
        </button>
      </aside>

      <div
        className={clsx(
          'b-drawer__overlay',
          open && 'b-drawer__overlay--visible'
        )}
      />
    </div>
  )
}

export default SideMenu

const ListLink = forwardRef((props, ref) => {
  const { title, href, badge, nested, onClick } = props

  return (
    <Link
      to={href}
      ref={ref}
      onClick={onClick}
      className={clsx('list-group-item', nested && 'nested')}
    >
      {title}
      {!!badge && <Badge variant='primary'>{badge}</Badge>}
    </Link>
  )
})
