import * as config from './config'
import date from './date'
import i18next from 'i18next'
import languageDetector from './languageDetector'
import translationLoader from './translationLoader'

const LocaleService = {
  init: () => {
    return new Promise((resolve, reject) => {
      i18next
        .use(languageDetector)
        .use(translationLoader)
        .init(
          {
            ns: config.NAMESPACES,
            defaultNS: config.DEFAULT_NAMESPACE,
            fallbackLng: config.FALLBACK,
            interpolation: {
              escapeValue: false,
              format(value, format) {
                if (value instanceof Date) {
                  return date.format(value, format)
                }
              }
            }
          },
          (error) => {
            if (error) {
              return reject(error)
            }

            date
              .init(i18next.language)
              .then(resolve)
              .catch((error) => reject(error))
          }
        )
    })
  },
  t: (key, namespace = 'common', options) => {
    return i18next.t(`${namespace}:${key}`, options)
  },
  get locale() {
    return i18next.language
  },
  change(lang) {
    i18next.changeLanguage(lang, async (error) => {
      if (!error) {
        localStorage.setItem('locale', lang)
        window.location.reload()
      }
    })
  }
}

export const t = LocaleService.t

export default LocaleService
