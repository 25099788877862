import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import EmptyData from 'components/Empty'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import CrudActions from 'components/CrudActions'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { LANGUAGE } from 'utils/constants'
import { FALLBACK } from 'utils/localization/config'
import { useFormik } from 'formik'
import { objectToFormData } from 'utils/helpers'
import { useState, useEffect, useCallback } from 'react'
import { ListGroup, Button, Tabs, Tab, Row, Col } from 'react-bootstrap'

const GeneralSettings = () => {
  const [banners, setBanners] = useState([])
  const [loading, setLoading] = useState(false)
  const [bannerModal, setBannerModal] = useState({
    id: null,
    open: false
  })

  const getBanners = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/admin/main/banners')
      setBanners(res.data)
    } catch (error) {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getBanners()
  }, [getBanners])

  const openBannerModal = (id) => () => {
    setBannerModal({
      id: id,
      open: true
    })
  }

  const closeBannerModal = () => {
    setBannerModal({
      id: null,
      open: false
    })
  }

  return (
    <Row>
      <Col md={6}>
        <h2>{t('DragTitleVideo')}</h2>
        <VideoForm />
      </Col>
      <Col md={6}>
        <div className='d-flex align-items-start justify-content-between mb-4'>
          <h2>{t('TabTitleBanners')}</h2>
          <Button onClick={openBannerModal()}>{t('Add')}</Button>
        </div>
        <LoadingWrapper loading={loading}>
          {banners.length ? (
            <ListGroup>
              {banners.map((banner) => (
                <ListGroup.Item className='d-flex align-items-center justify-content-between'>
                  <div>
                    <img
                      alt=''
                      src={banner.banner}
                      width={100}
                      height={100}
                      className='me-2 img-thumbnail'
                    />
                    <span>
                      ({banner.sort}) {banner.title}
                    </span>
                  </div>
                  <CrudActions onEdit={openBannerModal(banner.id)} />
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <EmptyData />
          )}
        </LoadingWrapper>
      </Col>

      <BannerModal
        id={bannerModal.id}
        open={bannerModal.open}
        onClose={closeBannerModal}
        getBanners={getBanners}
        numberOfBanners={banners.length}
      />
    </Row>
  )
}

export default GeneralSettings

const VideoForm = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [video, setVideo] = useState(() => {
    const values = { commonVideo: '' }

    LANGUAGE.getLanguageCodes().forEach((code) => {
      values[code] = ''
    })

    return values
  })

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const res = await axios.get('/admin/main/video')
        setVideo(res.data)
      } catch (error) {
        /* empty */
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const handleChange = (name) => (value) => {
    setVideo({ ...video, [name]: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      await axios.put('/admin/main/video', video)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className='mt-4'>
      <LoadingWrapper loading={loading}>
        <ErrorMessage error={error} />

        <TextControl
          name='commonVideo'
          label={t('LabelCommonVideo')}
          value={video.commonVideo}
          onChange={handleChange('commonVideo')}
          useFormik={false}
        />

        <Row>
          {LANGUAGE.getLanguages().map((locale) => (
            <TextControl
              name={locale.name}
              label={locale.name}
              value={video[locale.code]}
              onChange={handleChange(locale.code)}
              useFormik={false}
            />
          ))}
        </Row>

        <Button type='submit' disabled={!video.ru && !video.commonVideo}>
          {t('Save')}
        </Button>
      </LoadingWrapper>
    </form>
  )
}

const BannerModal = (props) => {
  const { id, open, onClose, getBanners, numberOfBanners } = props

  const [error, setError] = useState(null)
  const [banner, setBanner] = useState({})
  const [loading, setLoading] = useState(false)
  const [selectedLocale, setSelectedLocale] = useState(FALLBACK)

  useEffect(() => {
    ;(async () => {
      if (id) {
        try {
          setLoading(true)
          const res = await axios.get(`/admin/main/banner/${id}`)
          setBanner(res.data)
        } catch (error) {
          /* empty */
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [id])

  const getInitial = () => {
    const shape = {
      sort: banner.sort || numberOfBanners + 1,
      commonAlt: banner.commonAlt || '',
      commonLink: banner.commonLink || '',
      commonBanner: null,
      commonLinkTitle: banner.commonLinkTitle || ''
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] = {
        alt: banner[code]?.alt || '',
        code: code,
        link: banner[code]?.link || '',
        title: banner[code]?.title || '',
        banner: null,
        linkTitle: banner[code]?.linkTitle || '',
        description: banner[code]?.description || ''
      }
    })
    return shape
  }

  const getShape = () => {
    const shape = {
      sort: Yup.number().required(t('Required', 'validation')),
      commonAlt: Yup.string().notRequired(),
      commonLink: Yup.string().notRequired(),
      commonBanner: Yup.mixed().notRequired().nullable(),
      commonLinkTitle: Yup.string().notRequired(),
      isBannerUploaded: Yup.mixed().test(
        'check-banners',
        t('Required', 'validation'),
        (_, { parent }) => {
          return (
            !!parent.commonBanner ||
            !!banner.commonBaneer ||
            !!parent[selectedLocale].banner ||
            !!banner[selectedLocale].banner
          )
        }
      )
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] = Yup.object().shape({
        alt: Yup.string().notRequired(),
        code: Yup.string().required(t('Required', 'validation')),
        link: Yup.string().notRequired(),
        title: Yup.string().notRequired(),
        banner: Yup.mixed().notRequired().nullable(),
        linkTitle: Yup.string().notRequired(),
        description: Yup.string().notRequired()
      })
    })
    return shape
  }

  const handleClose = () => {
    onClose()
    setError(null)
    setBanner({})
    resetForm()
    setSelectedLocale(FALLBACK)
  }

  const handleSubmit = async (values) => {
    try {
      setLoading(true)

      const {
        sort,
        commonAlt,
        commonLink,
        commonBanner,
        commonLinkTitle,
        ...rest
      } = values
      const { banner: localeBanner, ...locale } = rest[selectedLocale]

      await axios({
        url: `/admin/main/banner/${id || ''}`,
        method: id ? 'put' : 'post',
        data: objectToFormData({
          sort,
          locale,
          commonAlt,
          commonLink,
          commonBanner,
          localeBanner,
          commonLinkTitle
        })
      })

      getBanners()
      handleClose()
    } catch (error) {
      setError(error)
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    if (await confirm(t('ConfirmBannerDelete'))) {
      try {
        await axios.delete(`/admin/main/banner/${id}`)
        getBanners()
        handleClose()
      } catch (error) {
        alert.error(error.message)
      }
    }
  }

  const handleChangeField = (name) => (value) => {
    if (name === 'sort') {
      setFieldValue(name, parseInt(value) || 0)
    } else {
      setFieldValue(name, value)
    }
  }

  const {
    values,
    errors,
    touched,
    resetForm,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: getInitial(),
    validationSchema: Yup.object().shape(getShape()),
    enableReinitialize: true
  })

  return (
    <Modal
      open={open}
      title={t(id ? 'Edit' : 'Create')}
      onClose={handleClose}
      loading={loading}
    >
      <form onSubmit={onSubmit}>
        <ErrorMessage error={error} />

        <Row>
          <Col lg={6}>
            <TextControl
              name='sort'
              label={t('LabelSort')}
              value={values.sort}
              error={touched.sort && errors.sort}
              onChange={handleChangeField('sort')}
              useFormik={false}
            />
            <TextControl
              name='commonLink'
              label={`${t('LabelCommonVideo')} (${t('LabelTitle')})`}
              value={values.commonLink}
              onChange={handleChange}
            />
            <TextControl
              name='commonLinkTitle'
              label={t('LabelCommonVideo')}
              value={values.commonLinkTitle}
              onChange={handleChange}
            />
          </Col>
          <Col lg={6}>
            <FileUpload
              name='commonBanner'
              label={t('CommonBanner')}
              value={values.commonBanner}
              files={banner.commonBanner}
              error={errors.isBannerUploaded}
              onChange={handleChangeField('commonBanner')}
            />
            <TextControl
              name='commonAlt'
              label={t('ImageDescription')}
              value={values.commonAlt}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Tabs
          onSelect={setSelectedLocale}
          activeKey={selectedLocale}
          className='mb-3'
        >
          {LANGUAGE.getLanguages().map((locale) => (
            <Tab
              key={locale.code}
              disabled={id ? false : true}
              eventKey={locale.code}
              title={locale.name}
            >
              <Row>
                <Col lg={6}>
                  <TextControl
                    name={locale.code + '.title'}
                    label={t('LabelTitle')}
                    value={values[locale.code].title}
                    onChange={handleChange}
                  />
                  <TextControl
                    as='textarea'
                    rows={2}
                    name={locale.code + '.description'}
                    label={t('LabelDescription')}
                    value={values[locale.code].description}
                    onChange={handleChange}
                  />
                  <TextControl
                    name={locale.code + '.link'}
                    label={t('Link')}
                    value={values[locale.code].link}
                    onChange={handleChange}
                  />
                  <TextControl
                    name={locale.code + '.linkTitle'}
                    label={`${t('Link')} (${t('LabelTitle')})`}
                    value={values[locale.code].linkTitle}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg={6}>
                  <FileUpload
                    name={locale.code + '.banner'}
                    label={t('LabelBanner')}
                    value={values[locale.code].banner}
                    files={banner[locale.code]?.banner}
                    error={errors.isBannerUploaded}
                    onChange={handleChangeField([locale.code] + '.banner')}
                  />
                  <TextControl
                    name={locale.code + '.alt'}
                    label={t('ImageDescription')}
                    value={values[locale.code].alt}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </Tab>
          ))}
        </Tabs>

        <div className='b-modal__footer '>
          {!!id && (
            <Button variant='danger' onClick={handleDelete}>
              {t('Delete')}
            </Button>
          )}
          <Button type='submit'>
            {t('SaveFor', 'common', {
              locale: LANGUAGE.getNameByCode(selectedLocale)
            })}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
