import EmptyData from 'components/Empty'
import Pagination from 'components/Pagination'
import SelectControl from 'components/SelectControl'
import { t } from 'utils/localization'
import { Table } from 'react-bootstrap'
import { useEffect } from 'react'
import { useTable, usePagination } from 'react-table'

const ROWS_PER_PAGE = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 }
]

const CustomTable = (props) => {
  const {
    id,
    data = [],
    columns = [],
    onChange,
    pagination,
    tableProps,
    canShowAll,
    scrollbale
  } = props

  let reactTableProps = { data, columns, initialState: {} }

  if (tableProps) {
    const { hiddenColumns } = tableProps

    reactTableProps = {
      ...reactTableProps,
      initialState: {
        hiddenColumns: hiddenColumns || []
      }
    }
  }

  if (pagination) {
    reactTableProps = {
      ...reactTableProps,
      initialState: {
        ...reactTableProps.initialState,
        pageSize: pagination.perPage || 10,
        pageIndex: pagination.page || 0
      },
      manualPagination: true,
      pageCount: pagination.pageCount || 1
    }
  }

  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,

    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(reactTableProps, usePagination)

  useEffect(() => {
    if (onChange) {
      onChange({
        pagination: {
          page: pageIndex + 1,
          rowsPerPage: pageSize
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize])

  const handleChangePage = ({ page }) => {
    gotoPage(page - 1)
  }

  const handleChangePageSize = (value) => {
    setPageSize(value)
  }

  const renderPagination = () => {
    const styles = {
      control: (provided) => ({
        ...provided,
        width: 100,
        height: 40
      })
    }

    return (
      pagination && (
        <div className='mt-4 d-flex align-items-center justify-content-end'>
          <div className='mb-4'>
            {t('TotalPages')}: {pagination.total || data.length}
          </div>
          <div className='ms-3 me-3'>
            <Pagination data={pagination} noStyle onChange={handleChangePage} />
          </div>
          <SelectControl
            name='rowsPerPage'
            value={pageSize}
            styles={styles}
            onChange={handleChangePageSize}
            options={
              canShowAll
                ? ROWS_PER_PAGE.concat({ label: t('All'), value: 'all' })
                : ROWS_PER_PAGE
            }
          />
        </div>
      )
    )
  }

  return (
    <>
      <div
        style={{
          overflowX: scrollbale ? 'auto' : 'initial'
        }}
      >
        <Table {...getTableProps({ id })}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th key={index} {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td key={index} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {(!data || !data.length) && <EmptyData />}

      {renderPagination()}
    </>
  )
}

export default CustomTable
