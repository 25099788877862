import React from 'react'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { MENU_LIST } from 'components/SideMenu'
import { useProfile } from 'utils/hooks/useContext'
import { Card, Badge, ListGroup } from 'react-bootstrap'

import './styles.scss'

const AdminPage = () => {
  const { notificationCount } = useProfile()

  return (
    <div className='b-card-columns'>
      {MENU_LIST.map((item) => (
        <Card key={item.id}>
          <Card.Header>{t(item.title, 'menu')}</Card.Header>
          <Card.Body>
            <ListGroup variant='flush'>
              {item.items.map((subItem) => {
                const badge =
                  notificationCount?.find((s) => s.section === subItem.section)
                    ?.count || 0

                return (
                  <ListGroup.Item as={Link} to={subItem.id} key={subItem.id}>
                    {t(subItem.title, 'menu')}
                    {!!badge && (
                      <Badge variant='primary' className='ms-2'>
                        {badge}
                      </Badge>
                    )}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Card.Body>
        </Card>
      ))}
    </div>
  )
}

export default AdminPage
