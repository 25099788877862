import TextControl from 'components/TextControl'
import React, { useEffect, useState } from 'react'
import '../../styles.scss'
import { t } from 'utils/localization'

const FreeDatePeopleCounter = (props) => {
  const {
    adultPrice,
    adultInputPrice,
    childPrice,
    childInputPrice,
    childError,
    adultError,
    onPriceChange,
    onChange
  } = props

  const [adultCount, setAdultCount] = useState(0)
  const [childCount, setChildCount] = useState(0)

  useEffect(() => {
    onChange({ person: 'adultCount', count: adultCount })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adultCount])

  useEffect(() => {
    onChange({ person: 'childCount', count: childCount })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childCount])

  return (
    <div>
      {/* <div style={{ fontWeight: 600 }}>PlaceRemain {places}</div> */}
      <MemoizedCounter
        title={t('OrderAdult', 'common')}
        price={adultPrice}
        min={0}
        onChange={(val) => setAdultCount(val)}
        inputError={adultError}
        inputPrice={adultInputPrice}
        onPriceChange={(ev) =>
          onPriceChange({
            person: 'adultPrice',
            price: Number(ev?.target?.value)
          })
        }
      />
      <MemoizedCounter
        title={`${t('OrderChild', 'common')}`}
        min={0}
        price={childPrice}
        inputError={childError}
        inputPrice={childInputPrice}
        onPriceChange={(ev) =>
          onPriceChange({
            person: 'childPrice',
            price: Number(ev?.target?.value)
          })
        }
        onChange={(val) => setChildCount(val)}
      />
    </div>
  )
}

const ControlCounter = (props) => {
  const { title, price, onChange, min, inputPrice, inputError, onPriceChange } =
    props

  const [count, setCount] = useState(min)

  useEffect(() => {
    onChange(count)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  const handleMinusClick = () => {
    if (count > 0) {
      setCount(count - 1)
    }
  }

  const handlePlusClick = () => {
    setCount(count + 1)
  }

  return (
    <div className='b-free-control-counter'>
      <span className='counter-title'>{title}</span>
      <span className='price'>{price}</span>
      <span className='counter-buttons'>
        <span onClick={handleMinusClick} className='counter-buttons__button'>
          -
        </span>
        {count}
        <span onClick={handlePlusClick} className='counter-buttons__button'>
          +
        </span>
      </span>

      <TextControl
        label={t('OrderPrice', 'common')}
        value={inputPrice}
        error={inputError}
        onChange={onPriceChange}
      />
    </div>
  )
}

const MemoizedCounter = React.memo(ControlCounter)

export default FreeDatePeopleCounter
