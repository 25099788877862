import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import Empty from 'components/Empty'
import CrudActions from 'components/CrudActions'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { LANGUAGE } from 'utils/constants'
import { FALLBACK } from 'utils/localization/config'
import { useFormik } from 'formik'
import { Button, Row, Col, ListGroup } from 'react-bootstrap'
import { useState, useEffect, useCallback } from 'react'

const HealthRestrictionsList = () => {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [modal, setModal] = useState({ open: false, data: {} })
  const [loading, setLoading] = useState(false)
  const [mLoading, setMLoading] = useState(false)

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/admin/product/health-restrictions')
      setData(res.data)
    } catch (error) {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const getInitial = () => {
    const initial = {}
    LANGUAGE.getLanguageCodes().forEach((code) => {
      initial[code] = modal.data[code] || ''
    })
    return initial
  }

  const getShape = () => {
    const shape = {}
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] =
        code === FALLBACK
          ? Yup.string().required(t('Required', 'validation'))
          : Yup.string().notRequired()
    })
    return shape
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: getInitial(),
    validationSchema: Yup.object().shape(getShape()),
    enableReinitialize: true
  })

  const toggleModal =
    (open, data = {}) =>
    () => {
      setModal({ open, data })
      if (!open) {
        setError(null)
        resetForm()
      }
    }

  const handleSubmit = async (values) => {
    try {
      setError(null)
      setMLoading(true)

      await axios({
        url: `/admin/product/health-restriction/${modal.data.id || ''}`,
        data: values,
        method: modal.data.id ? 'put' : 'post'
      })

      getData()
      toggleModal(false)()
      alert.success()
    } catch (error) {
      setError(error)
      alert.error()
    } finally {
      setMLoading(false)
    }
  }

  const handleDelete = (id) => async () => {
    if (await confirm(t('ConfirmDelete'))) {
      try {
        await axios.delete(`/admin/product/health-restriction/${id}`)

        getData()
        alert.success()
      } catch (error) {
        alert.error()
      }
    }
  }

  return (
    <>
      <h1>{t('HealthRestrictions', 'menu')}</h1>

      <Button className='mb-5' onClick={toggleModal(true)}>
        {t('AddItem')}
      </Button>

      <LoadingWrapper loading={loading}>
        <Row>
          {data.length ? (
            <Col lg={6}>
              <ListGroup>
                {data.map((item) => (
                  <ListGroup.Item key={item.id}>
                    <div className='d-flex justify-content-between'>
                      <div>{item[FALLBACK]}</div>
                      <CrudActions
                        onEdit={toggleModal(true, item)}
                        onDelete={handleDelete(item.id)}
                      />
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          ) : (
            <Col lg={12}>
              <Empty />
            </Col>
          )}
        </Row>
      </LoadingWrapper>

      <Modal
        open={modal.open}
        title={t(modal.data.id ? 'EditItem' : 'AddItem')}
        loading={mLoading}
        onClose={toggleModal(false)}
      >
        <form onSubmit={onSubmit}>
          <ErrorMessage error={error} />

          {LANGUAGE.getLanguageCodes().map((code) => (
            <TextControl
              key={code}
              name={code}
              label={LANGUAGE.getNameByCode(code)}
              value={values[code]}
              error={touched[code] && errors[code]}
              onChange={handleChange}
              required={code === FALLBACK}
            />
          ))}

          <div className='b-modal__footer'>
            <Button type='submit' className='ms-auto'>
              {t('Save')}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default HealthRestrictionsList
