import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import FormGroup from 'components/FormGroup'
import FileUpload from 'components/FileUpload'
import HtmlControl from 'components/HtmlControl'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { LANGUAGE } from 'utils/constants'
import { FALLBACK } from 'utils/localization/config'
import { useFormik } from 'formik'
import { objectToFormData } from 'utils/helpers'
import { useState, useEffect, Fragment } from 'react'
import { Button, Alert, Table, Tabs, Tab, Row, Col } from 'react-bootstrap'

const SEASONS = ['winter', 'spring', 'summer', 'fall']

const PopularDirectionForm = (props) => {
  const { id, afterSubmit } = props

  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [active, setActive] = useState(FALLBACK)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        const temp = {}

        for (const locale of LANGUAGE.getLanguages()) {
          const res = await axios.get(
            `/admin/directions/${id}?locale=${locale.code}`
          )
          temp[locale.code] = res.data
        }

        isMounted && setData(temp)
      } catch (error) {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    if (id) {
      getData()
    }
    return () => {
      isMounted = false
    }
  }, [id])

  const getInitial = () => {
    const initial = {
      sort: data?.[FALLBACK]?.sort || '',
      location: {
        lat: data?.[FALLBACK]?.location?.lat || null,
        lon: data?.[FALLBACK]?.location?.lon || null,
        address: data?.[FALLBACK]?.location?.address || ''
      },
      commonImages: [],
      weather:
        data?.[FALLBACK]?.weather ||
        SEASONS.map((season) => ({
          season,
          metric: { min: '', max: '' },
          imperial: { min: '', max: '' }
        }))
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      initial[code] = {
        code,
        name: data?.[code]?.locale?.name || '',
        images: [],
        subTitle: data?.[code]?.locale?.subTitle || '',
        description: data?.[code]?.locale?.description || '',
        shortDescription: data?.[code]?.locale?.shortDescription || ''
      }
    })
    return initial
  }

  const getShape = () => {
    const shape = {
      sort: Yup.number().notRequired(),
      location: Yup.object().shape({
        lat: Yup.number().required(t('Required', 'validation')),
        lon: Yup.number().required(t('Required', 'validation')),
        address: Yup.string().required(t('Required', 'validation'))
      }),
      weather: Yup.array().required(),
      commonImages: Yup.array().notRequired()
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] = Yup.object().shape({
        code: Yup.string().required(t('Required', 'validation')),
        name:
          code === FALLBACK || code === active
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired(),
        images: Yup.array().notRequired(),
        subTitle:
          code === FALLBACK || code === active
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired(),
        description:
          code === FALLBACK || code === active
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired(),
        shortDescription:
          code === FALLBACK || code === active
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired()
      })
    })
    return shape
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: getInitial(),
    validationSchema: Yup.object().shape(getShape()),
    enableReinitialize: true
  })

  const handleSubmit = async (values) => {
    try {
      setError(null)
      setLoading(true)

      const { commonImages, location, weather, sort, ...rest } = values
      const { images: localeImages, ...locale } = rest[active]

      const formData = objectToFormData({
        id,
        sort,
        locale,
        location,
        localeImages,
        commonImages
      })

      weather.forEach((item, index) => {
        formData.append(`weather[${index}].season`, item.season)
        formData.append(`weather[${index}].metric.min`, item.metric.min)
        formData.append(`weather[${index}].metric.max`, item.metric.max)
        formData.append(`weather[${index}].imperial.min`, item.imperial.min)
        formData.append(`weather[${index}].imperial.max`, item.imperial.max)
      })

      setFieldValue('commonImages', [])
      setFieldValue(`${active}.images`, [])

      const res = await axios({
        url: '/admin/directions',
        data: formData,
        method: id ? 'put' : 'post'
      })

      setData({ ...data, [res.data.locale.code]: res.data })

      afterSubmit()
      alert.success()
    } catch (error) {
      setError(error)
      alert.error()
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (name, index) => {
    if (await confirm(t('ConfirmBannerDelete'))) {
      try {
        setError(null)
        setLoading(true)

        await axios.delete(`/admin/image/directions/${id}?image=${name}`)

        if (data[active]) {
          const newImages = [...data[active].commonImages]
          newImages.splice(index, 1)
          setData({
            ...data,
            [active]: {
              ...data[active],
              commonImages: newImages
            }
          })
        }

        alert.success()
      } catch (error) {
        setError(error)
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  const handleChangeTab = (value) => {
    setActive(value)
  }

  const handleChangeField = (name) => (value) => {
    if (name === 'location') {
      setFieldValue('location', value.data)
    } else {
      setFieldValue(name, value)
    }
  }

  const handleDeleteByLocale = async (name, index) => {
    if (await confirm(t('ConfirmBannerDelete'))) {
      try {
        setError(null)
        setLoading(true)

        await axios.delete(
          `/admin/image/directions/${id}?image=${name}&localeImage=${active}`
        )

        if (data[active]?.locale?.images) {
          const newImages = [...data[active].locale.images]
          newImages.splice(index, 1)
          setData({
            ...data,
            [active]: {
              ...data[active],
              locale: {
                ...data[active].locale,
                images: newImages
              }
            }
          })
        }

        alert.success()
      } catch (error) {
        setError(error)
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <LoadingWrapper loading={loading}>
      <form onSubmit={onSubmit}>
        {data?.[FALLBACK]?.id && (
          <Alert variant='info'>
            {t('SEOPageCode')}: <b>destination-{data?.[FALLBACK]?.id}</b>
          </Alert>
        )}

        <ErrorMessage error={error} />

        <TextControl
          type='number'
          name='sort'
          label={t('LabelSort')}
          value={values.sort}
          error={touched.sort && errors.sort}
          onChange={handleChange}
        />

        <SelectControl
          name='place'
          async
          label={t('LabelPlace')}
          error={touched?.location?.address && errors?.location?.address}
          locale={active}
          onSelect={handleChangeField('location')}
          placeholder={values.location?.address}
        />

        <FileUpload
          size='sm'
          name='commonImages'
          label={t('LabelCommonImage')}
          value={values.commonImages}
          files={data[active] ? data[active].commonImages : null}
          onDelete={handleDelete}
          onChange={handleChangeField('commonImages')}
          multiple
        />

        <Tabs
          onSelect={handleChangeTab}
          activeKey={active}
          className='mt-4 mb-4'
        >
          {LANGUAGE.getLanguages().map((lang) => (
            <Tab key={lang.code} eventKey={lang.code} title={lang.name}>
              <Row>
                <Col lg={6}>
                  <TextControl
                    name={lang.code + '.name'}
                    label={t('LabelTitle')}
                    value={values[lang.code].name}
                    error={
                      touched[lang.code] &&
                      touched[lang.code].name &&
                      errors[lang.code] &&
                      errors[lang.code].name
                    }
                    onChange={handleChange}
                  />
                  <TextControl
                    name={lang.code + '.subTitle'}
                    label={t('LabelSubtitle')}
                    value={values[lang.code].subTitle}
                    error={
                      touched[lang.code] &&
                      touched[lang.code].subTitle &&
                      errors[lang.code] &&
                      errors[lang.code].subTitle
                    }
                    onChange={handleChange}
                  />
                  <HtmlControl
                    name={lang.code + '.shortDescription'}
                    label={t('')}
                    value={values[lang.code].shortDescription}
                    error={
                      touched[lang.code] &&
                      touched[lang.code].shortDescription &&
                      errors[lang.code] &&
                      errors[lang.code].shortDescription
                    }
                    onChange={handleChangeField(
                      lang.code + '.shortDescription'
                    )}
                  />
                </Col>
                <Col lg={6}>
                  <FileUpload
                    size='sm'
                    name={lang.code + '.images'}
                    label={t('LabelImage')}
                    value={values[lang.code].images}
                    files={
                      data[lang.code] ? data[lang.code].locale.images : null
                    }
                    onDelete={handleDeleteByLocale}
                    onChange={handleChangeField([lang.code] + '.images')}
                    multiple
                  />
                  <HtmlControl
                    name={lang.code + '.description'}
                    label={t('LabelDescription')}
                    value={values[lang.code].description}
                    error={
                      touched[lang.code] &&
                      touched[lang.code].description &&
                      errors[lang.code] &&
                      errors[lang.code].description
                    }
                    onChange={handleChangeField(lang.code + '.description')}
                  />
                </Col>
              </Row>
            </Tab>
          ))}
        </Tabs>

        <FormGroup label={t('LabelWeather')}>
          <Table bordered>
            <thead>
              <tr>
                <th colSpan={2} className='text-center'>
                  {t('OptionWinter')}
                </th>
                <th colSpan={2} className='text-center'>
                  {t('OptionSpring')}
                </th>
                <th colSpan={2} className='text-center'>
                  {t('OptionSummer')}
                </th>
                <th colSpan={2} className='text-center'>
                  {t('OptionFall')}
                </th>
              </tr>
              <tr>
                {SEASONS.map((season) => (
                  <Fragment key={season}>
                    <th className='text-center'>°C</th>
                    <th className='text-center'>°F</th>
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {SEASONS.map((season, index) => (
                  <Fragment key={season}>
                    <th>
                      <TextControl
                        type='number'
                        name={`weather[${index}].metric.min`}
                        value={values.weather[index].metric.min}
                        onChange={handleChange}
                        placeholder='Min'
                      />
                      <TextControl
                        type='number'
                        name={`weather[${index}].metric.max`}
                        value={values.weather[index].metric.max}
                        onChange={handleChange}
                        placeholder='Max'
                      />
                    </th>
                    <th>
                      <TextControl
                        type='number'
                        name={`weather[${index}].imperial.min`}
                        value={values.weather[index].imperial.min}
                        onChange={handleChange}
                        placeholder='Min'
                      />
                      <TextControl
                        type='number'
                        name={`weather[${index}].imperial.max`}
                        value={values.weather[index].imperial.max}
                        onChange={handleChange}
                        placeholder='Max'
                      />
                    </th>
                  </Fragment>
                ))}
              </tr>
            </tbody>
          </Table>
        </FormGroup>

        <div className='b-modal__footer'>
          <Button type='submit'>{t('Save')}</Button>
        </div>
      </form>
    </LoadingWrapper>
  )
}

export default PopularDirectionForm
