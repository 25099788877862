import alert from 'utils/alert'
import axios from 'utils/axios'
import FileUpload from 'components/FileUpload'
import EditGeneralForm from './EditGerenalForm'
import { t } from 'utils/localization'
import { LANGUAGE } from 'utils/constants'
import { FALLBACK } from 'utils/localization/config'
import { useState } from 'react'
import { Tabs, Tab, Row, Col } from 'react-bootstrap'

const GeneralInformationTab = () => {
  const [banner, setBanner] = useState(null)
  const [uploaded, setUploaded] = useState(null)

  const afterSubmit = (data) => {
    setBanner(null)
    setUploaded(data ? data.commonBanner : null)
  }

  const handleDelete = async (file) => {
    try {
      await axios.delete(`/admin/about-bustourma/banner?image=${file}`)
      setUploaded(null)
    } catch {
      alert.error()
    }
  }

  const getCommonData = () => {
    return { commonBanner: banner }
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          <FileUpload
            label={t('CommonBanner')}
            files={uploaded}
            value={banner}
            onChange={setBanner}
            onDelete={handleDelete}
          />
        </Col>
      </Row>

      <Tabs className='mt-4 mb-3' defaultActiveKey={FALLBACK}>
        {LANGUAGE.getLanguages().map((locale) => (
          <Tab key={locale.code} eventKey={locale.code} title={locale.name}>
            <EditGeneralForm
              locale={locale.code}
              afterSubmit={afterSubmit}
              getCommonData={getCommonData}
            />
          </Tab>
        ))}
      </Tabs>
    </>
  )
}

export default GeneralInformationTab
