import React from 'react'
import Breadcrumb from 'react-router-dynamic-breadcrumbs'
import { t } from 'utils/localization'
import { MENU_LIST } from 'components/SideMenu'

const Breadcrumbs = () => {
  const routes = (() => {
    const result = {}
    MENU_LIST.forEach((item) => {
      item.items.forEach((subitem) => {
        result[subitem.id] = t(subitem.title, 'menu')
      })
    })
    return result
  })()

  return <Breadcrumb rootName={t('AdminPage', 'menu')} mappedRoutes={routes} />
}

export default Breadcrumbs
