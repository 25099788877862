import React, { useEffect, useState } from 'react'
import '../../styles.scss'
import { t } from 'utils/localization'

const PeopleCounter = (props) => {
  const {
    canChild,
    adultPrice,
    childPrice,
    armenianResidentPrice,
    isArmenian,
    places,
    onChange
    // priceTypes
  } = props

  const [remain, setRemain] = useState(0)
  const [adultCount, setAdultCount] = useState(0)
  const [childCount, setChildCount] = useState(0)

  useEffect(() => {
    onChange({ person: 'adultCount', count: adultCount })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adultCount])

  useEffect(() => {
    onChange({ person: 'childCount', count: childCount })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childCount])

  useEffect(() => {
    setRemain(places)
  }, [places])

  useEffect(() => {
    if (adultCount !== 0 || childCount !== 0) {
      setRemain(Number(places) - Number(adultCount) - Number(childCount))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adultCount, childCount])

  return (
    <div>
      <div style={{ fontWeight: 600 }}>
        {t('OrderPlaceRemain', 'common')} {places}
      </div>
      <MemoizedCounter
        title={t('OrderAdult', 'common')}
        price={isArmenian ? armenianResidentPrice : adultPrice}
        min={0}
        max={remain}
        onChange={(val) => setAdultCount(val)}
      />
      {canChild && (
        <MemoizedCounter
          title={`${t('OrderChild', 'common')} (${canChild})`}
          min={0}
          price={childPrice}
          max={remain}
          onChange={(val) => setChildCount(val)}
        />
      )}
    </div>
  )
}

const ControlCounter = (props) => {
  const { title, price, onChange, min, max } = props

  const [count, setCount] = useState(min)

  useEffect(() => {
    onChange(count)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  const handleMinusClick = () => {
    if (count > 0) {
      setCount(count - 1)
    }
  }

  const handlePlusClick = () => {
    if (max > 0) {
      setCount(count + 1)
    }
  }

  return (
    <div className='b-control-counter'>
      <span className='counter-title'>{title}</span>
      <span className='price'>{price}</span>
      <span className='counter-buttons'>
        <span onClick={handleMinusClick} className='counter-buttons__minus'>
          -
        </span>
        {count}
        <span onClick={handlePlusClick} className='counter-buttons__plus'>
          +
        </span>
      </span>
    </div>
  )
}

const MemoizedCounter = React.memo(ControlCounter)

export default PeopleCounter
