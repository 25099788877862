import { t } from 'utils/localization'
import CategoryList from 'components/CategoryList'

const ProductRestrictionsList = () => {
  return (
    <>
      <h1>{t('TourRestrictions', 'menu')}</h1>
      <CategoryList baseUrl='restrictions' />
    </>
  )
}

export default ProductRestrictionsList
