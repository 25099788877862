import axios from 'utils/axios'
import Table from 'components/Table'
import TextControl from 'components/TextControl'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { useIsMounted } from 'utils/hooks'
import { objectToUrlParams } from 'utils/helpers'
import { useDebouncedCallback } from 'use-debounce'
import { useMemo, useState, useEffect } from 'react'
import { Button, Badge, Alert, Row, Col } from 'react-bootstrap'

const MerchantsPage = () => {
  const isMounted = useIsMounted()

  const OPTIONS_COMMON = [
    { label: t('All'), value: 'null' },
    { label: t('Yes'), value: 'true' },
    { label: t('No'), value: 'false' }
  ]

  const [data, setData] = useState([])
  const [states, setStates] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [newRequests, setNewRequests] = useState(0)
  const [filter, setFilter] = useState({
    page: 1,
    type: 'null',
    search: '',
    states: [],
    isTrusted: 'null',
    rowsPerPage: 10,
    inBlackList: 'null',
    isSuspension: 'null',
    isFormCompleted: 'null'
  })

  const getData = useDebouncedCallback(async () => {
    try {
      isMounted && setLoading(true)
      const res = await axios.get(
        `/admin/merchants?${objectToUrlParams(filter)}`
      )
      if (isMounted) {
        setData(res.data)
        setPagination(res.pagination)
      }
    } catch {
      if (isMounted) {
        setData([])
        setPagination({})
      }
    } finally {
      isMounted && setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getData()
  }, [filter, getData])

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get('/admin/new-merchants')
        setNewRequests(res.data || 0)
      } catch (error) {
        /* empty */
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get('/public/check/merchant/states')
        setStates(
          res.data.map((item) => ({
            value: item.code,
            label: item.title
          }))
        )
      } catch (error) {
        /* empty */
      }
    }
    getData()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        Cell: ({ value }) => (
          <Link to={`/merchants/${value}`}>
            <span className='text-bold'>{`# ${value}`}</span>
          </Link>
        )
      },
      {
        Header: t('TableName'),
        accessor: 'firstName',
        Cell: ({
          row: {
            original: { id, companyTitle, directorFirstName, directorLastName }
          }
        }) => (
          <div>
            {directorFirstName && (
              <div>
                <Link href={`/admin/merchants/detail?id=${id}`}>
                  <span className='text-bold'>
                    {directorFirstName + ' ' + directorLastName}
                  </span>
                </Link>
              </div>
            )}
            {companyTitle && (
              <div className='text-secondary'>{companyTitle}</div>
            )}
          </div>
        )
      },
      {
        Header: t('TableTitleContacts'),
        accessor: 'email',
        Cell: ({
          row: {
            original: { email, phone }
          }
        }) => (
          <div>
            {email && (
              <div>
                <Link href={`mailto:${email}`}>{email}</Link>
              </div>
            )}
            {phone && (
              <div className='mt-1'>
                <Link href={`tel:${phone}`}>{phone}</Link>
              </div>
            )}
          </div>
        )
      },
      {
        Header: t('LabelStatus'),
        accessor: 'state.title'
      },
      {
        Header: '',
        id: 'badges',
        Cell: ({
          row: {
            original: { isTrusted, inBlackList, isSuspension }
          }
        }) => (
          <div>
            {isTrusted && (
              <Badge variant='success' className='mr-1 mb-1'>
                {t('BadgeTrusted')}
              </Badge>
            )}
            {!isTrusted && (
              <Badge variant='danger' className='mr-1 mb-1'>
                {t('BadgeNotTrusted')}
              </Badge>
            )}
            {inBlackList && (
              <Badge variant='dark' className='mr-1 mb-1'>
                {t('BadgeBlackList')}
              </Badge>
            )}
            {isSuspension && (
              <Badge variant='warning' className='mr-1 mb-1'>
                {t('BadgeSuspension')}
              </Badge>
            )}
          </div>
        )
      }
    ],
    []
  )

  const handleChange = (name) => (value) => {
    setFilter({ ...filter, [name]: value, page: 1 })
  }

  const handleShowNewRequests = () => {
    setFilter({
      page: 1,
      type: 'null',
      search: '',
      states: ['waitCheck'],
      isTrusted: 'null',
      rowsPerPage: 10,
      inBlackList: 'null',
      isSuspension: 'null',
      isFormCompleted: 'null'
    })
  }

  const handleChangePagination = ({ pagination }) => {
    setFilter({ ...filter, ...pagination })
  }

  return (
    <>
      <h1>{t('PageMerchants', 'menu')}</h1>

      {!!newRequests && (
        <Alert variant='success'>
          <Alert.Heading>{t('Attention')}</Alert.Heading>
          <div className='mb-2'>
            {newRequests} {t('NewRequests')}
          </div>
          <Button
            size='sm'
            variant='outline-success'
            onClick={handleShowNewRequests}
          >
            {t('Show')}
          </Button>
        </Alert>
      )}

      <LoadingWrapper loading={loading}>
        <Row>
          <Col lg={9}>
            <TextControl
              label={t('LabelSearch')}
              value={filter.search}
              onChange={handleChange('search')}
              useFormik={false}
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              label={t('LabelProductType')}
              value={filter.type}
              onChange={handleChange('type')}
              options={[
                { label: t('All'), value: 'null' },
                { label: t('OptionCompany'), value: 'company' },
                { label: t('OptionIndividual'), value: 'individual' }
              ]}
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              label={t('LabelStatus')}
              value={filter.states}
              options={states}
              onChange={handleChange('states')}
              multiple
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              label={t('LabelTrusted')}
              value={filter.isTrusted}
              options={OPTIONS_COMMON}
              onChange={handleChange('isTrusted')}
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              label={t('LabelBlackList')}
              value={filter.inBlackList}
              options={OPTIONS_COMMON}
              onChange={handleChange('inBlackList')}
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              label={t('LabelSuspended')}
              value={filter.isSuspension}
              options={OPTIONS_COMMON}
              onChange={handleChange('LabelSuspended')}
            />
          </Col>
        </Row>
        <Table
          data={data}
          columns={columns}
          onChange={handleChangePagination}
          pagination={pagination}
        />
      </LoadingWrapper>
    </>
  )
}

export default MerchantsPage
