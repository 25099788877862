import axios from 'utils/axios'
import alert from 'utils/alert'
import Table from 'components/Table'
import Modal from 'components/Modal'
import FileUpload from 'components/FileUpload'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Button } from 'react-bootstrap'
import { confirm } from 'components/Dialog'
import { objectToFormData } from 'utils/helpers'
import { useMemo, useState, useEffect, useCallback } from 'react'

const FileStoragePage = () => {
  const [open, setOpen] = useState(false)
  const [files, setFiles] = useState([])
  const [error, setError] = useState(null)
  const [values, setValues] = useState([])
  const [loading, setLoading] = useState(false)

  const getFiles = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/admin/images')
      setFiles(res.data)
    } catch (error) {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getFiles()
  }, [getFiles])

  const toggleModal = (show) => () => {
    setOpen(show)
    if (!show) {
      setError(null)
      setValues([])
    }
  }

  const copyToClipboard = (text) => () => {
    navigator.clipboard.writeText(text)
  }

  const handleSubmit = async () => {
    if (values.length) {
      try {
        setError(null)
        await axios.post('/admin/image', objectToFormData({ images: values }))

        getFiles()
        toggleModal(false)()
        alert.success()
      } catch (error) {
        setError(error)
        alert.error()
      }
    }
  }

  const handleDelete = useCallback(
    (name) => async () => {
      if (await confirm(t('ConfirmFileDelete'))) {
        try {
          await axios.delete(`/admin/image?image=${name}`)

          getFiles()
          toggleModal(false)()
          alert.success()
        } catch (error) {
          setError(error)
          alert.error()
        }
      }
    },
    [getFiles]
  )

  const columns = useMemo(
    () => [
      {
        Header: '№',
        Cell: ({
          row: {
            original: { id, title }
          }
        }) => (
          <a href={title} data-fancybox={'preview-' + id}>
            <img
              alt=''
              src={title}
              title={title}
              width={100}
              height={100}
              style={{ objectFit: 'cover' }}
            />
          </a>
        )
      },
      {
        Header: t('TabelTitleLink'),
        accessor: 'title',
        Cell: ({ value }) => (
          <div>
            <span
              title='Copy to clipboard'
              onClick={copyToClipboard(value)}
              className='touchable me-2'
            >
              <svg
                height='15pt'
                viewBox='-40 0 512 512'
                width='15pt'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='m271 512h-191c-44.113281 0-80-35.886719-80-80v-271c0-44.113281 35.886719-80 80-80h191c44.113281 0 80 35.886719 80 80v271c0 44.113281-35.886719 80-80 80zm-191-391c-22.054688 0-40 17.945312-40 40v271c0 22.054688 17.945312 40 40 40h191c22.054688 0 40-17.945312 40-40v-271c0-22.054688-17.945312-40-40-40zm351 261v-302c0-44.113281-35.886719-80-80-80h-222c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h222c22.054688 0 40 17.945312 40 40v302c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm0 0' />
              </svg>
            </span>
            <span>
              <a rel='noreferrer' href={value} target='_blank'>
                {value}
              </a>
            </span>
          </div>
        )
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({
          row: {
            original: { title }
          }
        }) => <Button onClick={handleDelete(title)}>{t('Delete')}</Button>
      }
    ],
    [handleDelete]
  )

  return (
    <LoadingWrapper loading={loading}>
      <h1>{t('FileStorage', 'menu')}</h1>

      <Button className='mb-5' onClick={toggleModal(true)}>
        {t('Upload')}
      </Button>

      <Table data={files} columns={columns} />

      <Modal
        open={open}
        title={t('Upload')}
        onClose={toggleModal(false)}
        footer={
          <Button variant='primary' onClick={handleSubmit}>
            {t('Save')}
          </Button>
        }
      >
        <ErrorMessage error={error} />
        <FileUpload name='files' value={values} multiple onChange={setValues} />
      </Modal>
    </LoadingWrapper>
  )
}

export default FileStoragePage
