import * as Yup from 'yup'
import axios from 'utils/axios'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import HtmlControl from 'components/HtmlControl'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { LANGUAGE } from 'utils/constants'
import { useFormik } from 'formik'
import { objectToFormData } from 'utils/helpers'
import { useState, useEffect } from 'react'
import { Button, Tabs, Tab, Row, Col } from 'react-bootstrap'

const AboutArmeniaPage = () => {
  const [banner, setBanner] = useState(null)
  const [uploaded, setUploaded] = useState(null)

  const afterSubmit = (data) => {
    setBanner(null)
    setUploaded(data ? data.commonBanner : null)
  }

  const getCommonData = () => {
    return { commonBanner: banner }
  }

  return (
    <>
      <h1>{t('PageAboutArmenia', 'menu')}</h1>

      <Row>
        <Col lg={6}>
          <FileUpload
            label={t('CommonBanner')}
            files={uploaded}
            value={banner}
            onChange={setBanner}
          />
        </Col>
      </Row>

      <Tabs
        className='mt-4 mb-4'
        defaultActiveKey={LANGUAGE.getLanguages()[0].code}
      >
        {LANGUAGE.getLanguages().map((locale) => (
          <Tab key={locale.code} eventKey={locale.code} title={locale.name}>
            <EditForm
              locale={locale.code}
              afterSubmit={afterSubmit}
              getCommonData={getCommonData}
            />
          </Tab>
        ))}
      </Tabs>
    </>
  )
}

export default AboutArmeniaPage

const EditForm = (props) => {
  const { locale, afterSubmit, getCommonData } = props

  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: {
      title: data.title || '',
      banner: null,
      description: data.description || ''
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(t('Required', 'validation')),
      description: Yup.string().notRequired(),
      banner: Yup.mixed().notRequired()
    }),
    enableReinitialize: true
  })

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        const res = await axios.get(`/public/about-armenia?locale=${locale}`)
        if (isMounted) {
          setData(res.data)
          afterSubmit(res.data)
        }
      } catch (error) {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    getData()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const handleSubmit = async (values) => {
    try {
      setError(null)
      setLoading(true)

      const data = objectToFormData({ ...values, locale, ...getCommonData() })
      const res = await axios.put('/admin/about-armenia', data)

      setData(res.data)
      afterSubmit(res.data)
      setFieldValue('banner', null)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeField = (name) => (value) => {
    setFieldValue(name, value)
  }

  return (
    <LoadingWrapper loading={loading}>
      <ErrorMessage error={error} />
      <form onSubmit={onSubmit}>
        <Row>
          <Col lg={6}>
            <TextControl
              name='title'
              label={t('LabelTitle')}
              value={values.title}
              error={touched.title && errors.title}
              onChange={handleChange}
              required
            />
            <HtmlControl
              name='description'
              label={t('LabelDescription')}
              value={values.description}
              onChange={handleChangeField('description')}
            />
          </Col>
          <Col lg={6}>
            <FileUpload
              label={t('LabelBanner')}
              value={values.banner}
              files={data.banner}
              onChange={handleChangeField('banner')}
            />
          </Col>
        </Row>
        <Button type='submit'>
          {t('SaveFor', 'common', { locale: LANGUAGE.getNameByCode(locale) })}
        </Button>
      </form>
    </LoadingWrapper>
  )
}
