import * as Yup from 'yup'
import axios from 'utils/axios'
import Loading from 'components/Loading'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import SelectPhoneControl from 'components/SelectPhoneControl'
import LocaleService, { t } from 'utils/localization'
import { useState } from 'react'
import { useFormik } from 'formik'
import { useProfile } from 'utils/hooks/useContext'
import { Tab, Tabs, Button } from 'react-bootstrap'
import { isValidPhoneNumber } from 'react-phone-number-input'

import './styles.scss'

const Login = (props) => {
  const { showForm } = props

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('email')

  const { login } = useProfile()

  const {
    errors,
    values,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: {
      phone: '',
      email: '',
      rawPhone: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(t('Required', 'validation')),
      email: Yup.string()
        .email(t('InvalidEmail', 'validation'))
        .when('password', (_, shema) => {
          return activeTab === 'email'
            ? shema.required(t('Required', 'validation'))
            : shema.notRequired()
        }),
      phone: Yup.string()
        .when('password', (_, shema) => {
          return activeTab === 'phone'
            ? shema.required(t('Required', 'validation'))
            : shema.notRequired()
        })
        .test('check-phone', t('InvalidPhone', 'validation'), (value) => {
          return value && activeTab === 'phone'
            ? isValidPhoneNumber(value)
            : true
        }),
      rememberMe: Yup.boolean()
    })
  })

  const userLogin = (() => {
    if (activeTab === 'email') {
      return values.email
    } else if (activeTab === 'phone') {
      return values.rawPhone
    } else {
      return ''
    }
  })()

  const isSubmitDisabled = (() => {
    if (!values.password.trim()) {
      return true
    }
    if (activeTab === 'email') {
      if (!values.email.trim()) {
        return true
      }
    } else if (activeTab === 'phone') {
      if (!values.phone.trim()) {
        return true
      }
    } else {
      return false
    }
  })()

  const handleSubmit = async (values) => {
    try {
      setError(null)
      setLoading(true)

      const { email, phone, ...rest } = values

      const res = await axios.post(
        '/user/authorization',
        Object.assign(rest, activeTab === 'phone' ? { phone } : { email })
      )

      login(res.data)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChangePhone = (phone, rawPhone) => {
    setFieldValue('phone', phone)
    setFieldValue('rawPhone', rawPhone)
  }

  return (
    <div className='b-login'>
      {showForm && (
        <Loading loading={loading}>
          <div className='b-login__container'>
            <form onSubmit={onSubmit}>
              <h2>{t('Title', 'signin')}</h2>

              <ErrorMessage error={error} />

              <Tabs
                onSelect={setActiveTab}
                activeKey={activeTab}
                className='mb-3'
              >
                <Tab title={t('Email', 'signin')} eventKey='email'>
                  <TextControl
                    name='email'
                    value={values.email}
                    error={touched.email && errors.email}
                    onChange={handleChange}
                    placeholder={t('Email', 'signin')}
                  />
                </Tab>
                <Tab title={t('Phone', 'signin')} eventKey='phone'>
                  <SelectPhoneControl
                    name='phone'
                    error={touched.phone && errors.phone}
                    value={values.phone}
                    onChange={handleChangePhone}
                    placeholder={t('Phone', 'signin')}
                  />
                </Tab>
              </Tabs>

              <TextControl
                type='password'
                name='password'
                value={values.password}
                error={touched.password && errors.password}
                onChange={handleChange}
                className='mt-3'
                placeholder={t('Password', 'signin')}
              />

              <div className='d-grid gap-2 mt-4'>
                <Button type='submit' disabled={isSubmitDisabled}>
                  {t('Title', 'signin')}
                </Button>

                <div className='text-center'>
                  <a
                    rel='noreferrer'
                    href={`${process.env.REACT_APP_HOST}/${LocaleService.locale}/signup`}
                    target='_blank'
                  >
                    {t('Signup', 'signin')}
                  </a>
                </div>

                <div className='text-center'>
                  <a
                    rel='noreferrer'
                    href={`${process.env.REACT_APP_HOST}/${LocaleService.locale}/recovery?type=${activeTab}&login=${userLogin}`}
                    target='_blank'
                  >
                    {t('Forget', 'signin')}
                  </a>
                </div>
              </div>
            </form>
          </div>
        </Loading>
      )}
    </div>
  )
}

export default Login
