import Seo from './components/Seo'
import Tabs from './components/Tabs'
import axios from 'utils/axios'
import Status from './components/Status'
import Actions from './components/Actions'
import Comments from './components/Comments'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import ProductEditForm from './components/EditForm'
import LocaleService, { t } from 'utils/localization'
import { useToggle } from 'utils/hooks'
import { cloneDeep } from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap'

const ProductPage = () => {
  const params = useParams()

  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [status, setStatus] = useState({})
  const [actions, setActions] = useState([])
  const [isActive, setIsActive] = useState(false)
  const [locales, setLocales] = useState([])
  const [loading, setLoading] = useState(false)
  const [mainLocale, setMainLocale] = useState('')
  const [translations, setTranslations] = useState([])
  const [selectedLocale, setSelectedLocale] = useState('')

  const product = data[selectedLocale] || {}
  const [showEdit, toggleShowEdit] = useToggle(false)
  const history = useHistory()

  const getState = useCallback(async () => {
    if (params.id) {
      try {
        const res = await axios.get(`/admin/check/product/${params.id}/state`)
        setStatus(res.data.state)
        setActions(
          res.data.actions.map((action) => ({
            label: action.title,
            value: action.code
          }))
        )
      } catch (error) {
        /* empty */
      }
    }
  }, [params.id])

  const getProduct = useCallback(async () => {
    if (params.id) {
      try {
        setLoading(true)
        const res = await axios.get(`/admin/check/product/${params.id}`)
        const { translations, data: productInfo, ...productData } = res.data

        const locale = productInfo.locale.locale
        let values = { [locale]: productInfo }

        setLocales(productData.locales)
        setMainLocale(locale)
        setSelectedLocale(locale)

        if (translations?.length) {
          translations.forEach((item) => {
            const newValues = cloneDeep(values[locale])
            newValues.productType.title = item.title
            newValues.eventDetails.description = item.description
            newValues.eventDetails.events = newValues.eventDetails.events.map(
              (event) => {
                const translated = item.events.find((e) => event.id === e.id)
                return translated ? { ...event, ...translated } : event
              }
            )
            newValues.locationDetails.departure.description =
              item.departureDescription
            newValues.locationDetails.arrival.description =
              item.arrivalDescription

            if (newValues.locationDetails) {
              if (newValues.locationDetails.group) {
                newValues.locationDetails.group.address =
                  item.groupLocationAddress
              }
              if (newValues.locationDetails.individual) {
                newValues.locationDetails.individual.address =
                  item.individualLocationAddress
              }
            }

            values[item.locale] = cloneDeep(newValues)
          })
        }

        setData(values)
        setTranslations(translations)
        setIsActive(res.data.isActive)
      } catch (error) {
        /* empty */
      } finally {
        setLoading(false)
      }
    }
  }, [params.id])

  useEffect(() => {
    getState()
  }, [getState])

  useEffect(() => {
    getProduct()
  }, [getProduct])

  const handleSelectLocale = (lang) => () => {
    setSelectedLocale(lang)
  }

  const afterProductUpdated = (newData, newLocale) => {
    const newProductData = cloneDeep(data)

    newData.forEach((item) => {
      const newValues = cloneDeep(newProductData[LocaleService.locale])
      newValues.productType.title = item.title
      newValues.eventDetails.description = item.description
      newValues.eventDetails.events = newValues.eventDetails.events.map(
        (event) => {
          const translated = item.events.find((e) => event.id === e.id)
          return translated ? { ...event, ...translated } : event
        }
      )

      if (newValues.locationDetails) {
        if (newValues.locationDetails.group) {
          newValues.locationDetails.group.address = item.groupLocationAddress
        }
        if (newValues.locationDetails.individual) {
          newValues.locationDetails.individual.address =
            item.individualLocationAddress
        }
      }

      newProductData[item.locale] = cloneDeep(newValues)
    })

    setData(newProductData)

    if (!locales.includes(newLocale)) {
      setLocales([...locales, newLocale])
    }
  }

  const afterProductImagesUpdated = (newFiles) => {
    const newProductData = {}
    Object.keys(data).forEach((locale) => {
      newProductData[locale] = cloneDeep(data[locale])
      newProductData[locale].photo = {
        ...newFiles,
        images: newFiles.commonImages
      }
    })
    setData(newProductData)
  }

  const redirectToCreateOrder = () => {
    history.push(`/create-order/${params.id}`)
  }

  return (
    <LoadingWrapper loading={loading}>
      <div className='mb-5 d-flex align-items-start justify-content-between'>
        <div>
          <h1 className='m-0'>{`${t('LabelProduct')} № ${params.id}`}</h1>
          <Status {...status} />
        </div>
        <div>
          <Button onClick={toggleShowEdit}>
            {showEdit ? t('Cancel') : t('Edit')}
          </Button>
          {isActive && (
            <Button onClick={redirectToCreateOrder} className='ms-3'>
              {t('CreateOrder', 'menu')}
            </Button>
          )}
        </div>
      </div>

      {showEdit ? (
        <ProductEditForm
          id={+params.id}
          product={product}
          locales={locales}
          baseLocale={mainLocale}
          afterSubmit={afterProductUpdated}
          translations={translations}
          afterFilesSubmit={afterProductImagesUpdated}
        />
      ) : (
        <>
          <ErrorMessage error={error} />

          <section>
            <Actions
              id={params.id}
              actions={actions}
              loading={loading}
              getState={getState}
              setError={setError}
              setLoading={setLoading}
            />
          </section>

          <section className='mt-4'>
            <Comments id={params.id} />
          </section>

          <section className='mt-4'>
            <Seo
              id={params.id}
              title={
                data?.[LocaleService.locale]
                  ? data[LocaleService.locale].productType?.title
                  : data?.ru?.productType?.title || ''
              }
              locales={locales}
              mainLocale={mainLocale}
            />
          </section>

          <section className='mt-4'>
            <h3 className='mb-4'>{t('SubtitleAboutProduct')}</h3>
            <ButtonGroup toggle className='mb-3'>
              {locales.map((lang) => (
                <ToggleButton
                  key={lang}
                  size='sm'
                  type='radio'
                  variant='outline-primary'
                  checked={selectedLocale === lang}
                  onClick={handleSelectLocale(lang)}
                >
                  {t(lang)}
                </ToggleButton>
              ))}
            </ButtonGroup>

            <Tabs data={product} />
          </section>
        </>
      )}
    </LoadingWrapper>
  )
}

export default ProductPage
