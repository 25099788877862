import React from 'react'
import { t } from 'utils/localization'
import { Modal, Button } from 'react-bootstrap'
import { confirmable, createConfirmation } from 'react-confirm'

const Confirmation = (props) => {
  const { show, text, okLabel, cancelLabel, proceed } = props

  const handleCancel = () => {
    proceed(false)
  }

  const handleConfirm = () => {
    proceed(true)
  }

  return (
    <div className='static-modal'>
      <Modal show={show} onHide={handleCancel} backdrop keyboard>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant='outline-primary' onClick={handleCancel}>
            {cancelLabel || t('No')}
          </Button>
          <Button onClick={handleConfirm} size='sm'>
            {okLabel || t('Yes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default function confirm(text, options = {}) {
  return createConfirmation(confirmable(Confirmation))({
    text,
    ...options
  })
}
