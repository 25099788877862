import React, { useMemo, useState, useEffect } from 'react'
import axios from 'utils/axios'
import Table from 'components/Table'
import TextControl from 'components/TextControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { useIsMounted } from 'utils/hooks'
import { objectToUrlParams } from 'utils/helpers'
import { useDebouncedCallback } from 'use-debounce'
import { Button, Badge, Tabs, Tab } from 'react-bootstrap'

const UsersPage = () => {
  const isMounted = useIsMounted()

  const [roles, setRoles] = useState([])
  const [staff, setStaff] = useState([])
  const [clients, setClients] = useState([])
  const [loadingStaff, setLoadingStaff] = useState(false)
  const [loadingClients, setLoadingClients] = useState(false)
  const [paginationStaff, setPaginationStaff] = useState({})
  const [paginationClients, setPaginationClients] = useState({})

  const [filterStaff, setFilterStaff] = useState({
    page: 1,
    search: '',
    rowsPerPage: 10
  })
  const [filterClients, setFilterClients] = useState({
    page: 1,
    search: '',
    rowsPerPage: 10
  })

  const getStaff = useDebouncedCallback(async () => {
    try {
      isMounted && setLoadingStaff(true)
      const res = await axios.get(
        `/admin/users/staff?${objectToUrlParams(filterStaff)}`
      )
      if (isMounted) {
        setStaff(res.data)
        setPaginationStaff(res.pagination)
      }
    } catch {
      setStaff([])
      setPaginationStaff({})
    } finally {
      isMounted && setLoadingStaff(false)
    }
  }, 500)

  const getClients = useDebouncedCallback(async () => {
    try {
      isMounted && setLoadingClients(true)
      const res = await axios.get(
        `/admin/users/clients?${objectToUrlParams(filterClients)}`
      )
      if (isMounted) {
        setClients(res.data)
        setPaginationClients(res.pagination)
      }
    } catch {
      setClients([])
      setPaginationClients({})
    } finally {
      isMounted && setLoadingClients(false)
    }
  }, 500)

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        const res = await axios.get('/admin/roles')
        if (res.data && isMounted) {
          setRoles(res.data)
        }
      } catch {
        /* empty */
      }
    }
    getData()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    getStaff()
  }, [filterStaff, getStaff])

  useEffect(() => {
    getClients()
  }, [filterClients, getClients])

  const handleChangeFilterStaff = (name) => (value) => {
    if (name === 'pagination') {
      setFilterStaff({ ...filterStaff, ...value.pagination })
    } else {
      setFilterStaff({ ...filterStaff, [name]: value, page: 1 })
    }
  }

  const handleChangeFilterClients = (name) => (value) => {
    if (name === 'pagination') {
      setFilterClients({ ...filterClients, ...value.pagination })
    } else {
      setFilterClients({ ...filterClients, [name]: value, page: 1 })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        Cell: ({ value }) => (
          <Link to={`/users/${value}`}>
            <span className='text-bold'>{`# ${value}`}</span>
          </Link>
        )
      },
      {
        Header: t('TableTitleName'),
        accessor: 'fullName',
        Cell: ({
          row: {
            original: { id, fullName }
          }
        }) => (
          <Link to={`/users/${id}`}>
            <span className='text-bold'>{fullName}</span>
          </Link>
        )
      },
      {
        Header: t('TableTitleContacts'),
        accessor: 'email',
        Cell: ({
          row: {
            original: { email, phone }
          }
        }) => (
          <div>
            {email && (
              <div>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            )}
            {phone && (
              <div className='mt-1'>
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
            )}
          </div>
        )
      },
      {
        Header: t('TableTitleRoles'),
        accessor: 'roles',
        Cell: ({ value }) => {
          if (value && value.length) {
            let rolesArray = []
            value.forEach((item) => {
              const temp = roles.find((role) => role.id === item)
              if (temp) {
                rolesArray.push(temp.title)
              }
            })
            return rolesArray.join(', ')
          }
          return ''
        }
      },
      {
        Header: '',
        accessor: 'inBlackList',
        Cell: ({
          row: {
            original: { selfDeactivation, inBlackList, isSuspension }
          }
        }) => (
          <div>
            {inBlackList && (
              <Badge variant='dark'>{t('LabelInBlackList')}</Badge>
            )}
            {isSuspension && (
              <Badge variant='warning'>{t('LabelIsSuspension')}</Badge>
            )}
            {selfDeactivation && (
              <Badge variant='secondary'>{t('LabelSelfDeactivation')}</Badge>
            )}
          </div>
        )
      }
    ],
    [roles]
  )

  return (
    <>
      <h1>{t('PageUsers', 'menu')}</h1>

      <Button as={Link} to='/users/new' className='mb-5'>
        {t('AddUser')}
      </Button>

      <Tabs defaultActiveKey='clients' className='mb-3'>
        <Tab eventKey='clients' title={t('TabTitleClients')}>
          <LoadingWrapper loading={loadingClients}>
            <TextControl
              value={filterClients.search}
              onChange={handleChangeFilterClients('search')}
              useFormik={false}
              placeholder={t('LabelSearch')}
            />
            <Table
              data={clients}
              columns={columns}
              onChange={handleChangeFilterClients('pagination')}
              pagination={paginationClients}
              tableProps={{ hiddenColumns: ['roles'] }}
            />
          </LoadingWrapper>
        </Tab>
        <Tab eventKey='staff' title={t('TabTitleStaff')}>
          <LoadingWrapper loading={loadingStaff}>
            <TextControl
              value={filterStaff.search}
              onChange={handleChangeFilterStaff('search')}
              useFormik={false}
              placeholder={t('LabelSearch')}
            />
            <Table
              data={staff}
              columns={columns}
              onChange={handleChangeFilterStaff('pagination')}
              pagination={paginationStaff}
            />
          </LoadingWrapper>
        </Tab>
      </Tabs>
    </>
  )
}

export default UsersPage
