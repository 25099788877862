import axios from 'utils/axios'
import Table from 'components/Table'
import TextControl from 'components/TextControl'
import DateControl from 'components/DateControl'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { useIsMounted } from 'utils/hooks'
import { objectToUrlParams } from 'utils/helpers'
import { useDebouncedCallback } from 'use-debounce'
import { useMemo, useState, useEffect } from 'react'
import { Button, Alert, Row, Col, FormCheck } from 'react-bootstrap'

const ProductsPage = () => {
  const isMounted = useIsMounted()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const OPTIONS_TYPES = [
    { label: t('Tour', 'common'), value: 'tour' },
    { label: t('Event', 'common'), value: 'event' },
    { label: t('Transfer', 'common'), value: 'transfer' }
  ]

  const [data, setData] = useState([])
  const [states, setStates] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [newRequests, setNewRequests] = useState(0)
  const [filter, setFilter] = useState({
    page: 1,
    types: [],
    states: [],
    search: '',
    dateEnd: '',
    dateStart: '',
    merchants: [],
    rowsPerPage: 10,
    withdrawnFromSale: false
  })

  const getData = useDebouncedCallback(async () => {
    try {
      isMounted && setLoading(true)
      const res = await axios.get(
        `/admin/check/products?${objectToUrlParams(filter)}`
      )
      if (isMounted) {
        setData(res.data)
        setPagination(res.pagination)
      }
    } catch {
      if (isMounted) {
        setData([])
        setPagination({})
      }
    } finally {
      isMounted && setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getData()
  }, [filter, getData])

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get('/admin/check/products/new-count')
        setNewRequests(res.data || 0)
      } catch (error) {
        /* empty */
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get('/public/check/product/states')
        setStates(res.data)
      } catch (error) {
        /* empty */
      }
    }
    getData()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        Cell: ({ value }) => (
          <Link to={`/products/${value}`}>
            <span className='text-bold'>{`# ${value}`}</span>
          </Link>
        )
      },
      {
        Header: t('LabelProductType'),
        accessor: 'type',
        Cell: ({ value }) => {
          const type = OPTIONS_TYPES.find((t) => t.value === value)
          return <div>{type?.label || '-'}</div>
        }
      },
      {
        Header: t('LabelTitle'),
        accessor: 'title'
      },
      {
        Header: t('Partner'),
        accessor: 'merchant',
        Cell: ({
          value: { id, companyTitle, directorFirstName, directorLastName }
        }) => (
          <div>
            <Link href={`/admin/merchants/detail?id=${id}`} newTab>
              {directorFirstName && (
                <div className='text-bold'>
                  {directorFirstName + ' ' + directorLastName}
                </div>
              )}
              {companyTitle && (
                <div className='text-secondary'>{companyTitle}</div>
              )}
            </Link>
          </div>
        )
      },
      {
        Header: t('LabelStatus'),
        accessor: 'state',
        Cell: ({ value }) => {
          const status = states.find((state) => state.code === value)
          return status && <div>{status.title}</div>
        }
      }
    ],
    [states, OPTIONS_TYPES]
  )

  const handleChange = (name) => (value) => {
    if (name === 'pagination') {
      setFilter({ ...filter, ...value.pagination })
    } else {
      setFilter({ ...filter, [name]: value, page: 1 })
    }
  }

  const handleShowNewRequests = () => {
    setFilter({
      page: 1,
      types: [],
      states: ['new', 'waitCheck'],
      search: '',
      dateEnd: '',
      dateStart: '',
      merchants: [],
      rowsPerPage: 10
    })
  }

  return (
    <>
      <h1>{t('Products', 'menu')}</h1>

      {!!newRequests && (
        <Alert variant='success'>
          <Alert.Heading>{t('Attention')}</Alert.Heading>
          <div className='mb-2'>
            {newRequests} {t('NewRequests')}
          </div>
          <Button
            size='sm'
            variant='outline-success'
            onClick={handleShowNewRequests}
          >
            {t('Show')}
          </Button>
        </Alert>
      )}

      <LoadingWrapper loading={loading}>
        <Row>
          <Col lg={9}>
            <TextControl
              label={t('LabelSearch')}
              value={filter.search}
              onChange={handleChange('search')}
              useFormik={false}
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              label={t('LabelProductType')}
              value={filter.types}
              options={OPTIONS_TYPES}
              onChange={handleChange('types')}
              multiple
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              label={t('LabelStatus')}
              value={filter.states}
              options={states.map((state) => ({
                label: state.title,
                value: state.code
              }))}
              onChange={handleChange('states')}
              multiple
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              url='/admin/merchants?search='
              name='merchant'
              keys={{ label: 'companyTitle', value: 'id' }}
              async
              label={t('Partner')}
              value={filter.merchants}
              onChange={handleChange('merchants')}
              multiple
            />
          </Col>
          <Col lg={3}>
            <DateControl
              label={t('LabelDateStart')}
              value={filter.dateStart}
              onChange={handleChange('dateStart')}
            />
          </Col>
          <Col lg={3}>
            <DateControl
              label={t('LabelDateEnd')}
              value={filter.dateEnd}
              onChange={handleChange('dateEnd')}
            />
          </Col>
          <Col lg={12} className='d-flex justify-content-end'>
            <FormCheck
              id='withdrawnFromSale'
              type='checkbox'
              custom
              label={t('ShowOnlyOutOfStock')}
              checked={filter.withdrawnFromSale}
              onChange={(event) =>
                handleChange('withdrawnFromSale')(event.target.checked)
              }
            />
          </Col>
        </Row>
        <Table
          data={data}
          columns={columns}
          onChange={handleChange('pagination')}
          pagination={pagination}
        />
      </LoadingWrapper>
    </>
  )
}

export default ProductsPage
