import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import Empty from 'components/Empty'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import LocaleService, { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { FALLBACK } from 'utils/localization/config'
import { Button, Row, Col, Card } from 'react-bootstrap'
import { useState, useEffect, useCallback } from 'react'

const PopularProducts = () => {
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(false)

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/admin/main/popular?locale=${FALLBACK}`)
      setData(res.data)
    } catch (error) {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const toggleModal = (show) => () => {
    setOpen(show)
    if (!show) {
      setValue(null)
    }
  }

  const handleSubmit = async () => {
    if (value) {
      try {
        setLoading(true)

        await axios.post('/admin/main/popular', {
          locale: LocaleService.locale,
          productId: value
        })

        getData()
        toggleModal(false)()
        alert.success()
      } catch (error) {
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  const handleDelete = (id) => async () => {
    if (await confirm(t('ConfirmProductDelete'))) {
      try {
        setLoading(true)

        await axios.delete(`/admin/main/popular/${id}`)

        getData()
        alert.success()
      } catch (error) {
        alert.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <LoadingWrapper loading={loading}>
      <Button className='mb-4' onClick={toggleModal(true)}>
        {t('Add')}
      </Button>
      <Row>
        {data.map((item) => (
          <Col lg={4} key={item.id}>
            <Card className='mb-2'>
              {item.cover && (
                <Card.Img
                  alt={item.title}
                  src={item.cover}
                  style={{ height: 200, objectFit: 'cover' }}
                  variant='top'
                />
              )}
              <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                {item.description && <Card.Text>{item.description}</Card.Text>}
                <Button onClick={handleDelete(item.id)} variant='danger'>
                  {t('Delete')}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {!data.length && (
          <Col lg={12}>
            <Empty />
          </Col>
        )}
      </Row>

      <Modal
        open={open}
        title={t('Add')}
        footer={<Button onClick={handleSubmit}>{t('Save')}</Button>}
        onClose={toggleModal(false)}
      >
        <SelectControl
          url={'/admin/main/products?title='}
          name='popularProducts'
          keys={{ value: 'id', label: 'title' }}
          async
          label={t('LabelProduct')}
          onChange={setValue}
        />
      </Modal>
    </LoadingWrapper>
  )
}

export default PopularProducts
