import axios from 'axios'
import alerts from './alert'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_HOST}/api/v1`,
  timeout: 120000
})

const errorHandler = async (error) => {
  const { response } = error

  if (typeof localStorage != 'undefined') {
    const token = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refresh_token')

    const tokenExpiredHeader = response?.headers['token-expired']
    const tokenExpired = tokenExpiredHeader && tokenExpiredHeader === 'true'

    if (
      response?.status === 401 &&
      !error.config._retry &&
      refreshToken &&
      tokenExpired
    ) {
      const originalRequest = error.config
      originalRequest._retry = false

      return axiosInstance
        .post('/token/refresh', { token, refreshToken })
        .then((res) => {
          if (res.data) {
            const { token, refreshToken } = res.data

            if (token) {
              localStorage.getItem('token', token)
              localStorage.getItem('refresh_token', refreshToken)

              axiosInstance.defaults.headers.common[
                'Authorization'
              ] = `Bearer ${token}`
              originalRequest.headers['Authorization'] = `Bearer ${token}`
            }
          }
          return axiosInstance(originalRequest)
        })
        .catch((err) => {
          // AuthService.logout(store.dispatch)
          return Promise.reject(err)
        })
    }
  }

  const errorObject = response?.data?.error || error

  if (response?.data?.error?.message) {
    alerts.error(response.data.error.message)
  }

  return Promise.reject(errorObject)
}

const resHandler = (response) => {
  const { data } = response

  return Promise.resolve({
    data: data?.result || null,
    pagination: data?.pagination || null
  })
}

const reqHandler = (config) => {
  config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

  if (!config.url.includes('&locale') && !config.url.includes('?locale')) {
    const symbol = config.url.includes('?') ? '&' : '?'
    const locale = localStorage.getItem('locale')

    config.url = `${config.url}${symbol}locale=${locale}`
  }

  return config
}

axiosInstance.interceptors.request.use(reqHandler)
axiosInstance.interceptors.response.use(resHandler, errorHandler)

export default axiosInstance
