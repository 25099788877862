export const FALLBACK = 'ru'

export const NAMESPACES = ['menu', 'common', 'signin', 'validation']

export const DEFAULT_NAMESPACE = 'common'

export const SUPPORTED_LOCALES = {
  en: {
    name: 'English',
    moment: 'en',
    momentLocaleLoader: () => Promise.resolve(),
    translationFileLoader: () => require('../../lang/en.json')
  },
  ru: {
    name: 'Russian',
    moment: 'ru',
    momentLocaleLoader: () => import('moment/locale/ru'),
    translationFileLoader: () => require('../../lang/ru.json')
  },
  hy: {
    name: 'Armeanian',
    moment: 'hy-am',
    momentLocaleLoader: () => import('moment/locale/hy-am'),
    translationFileLoader: () => require('../../lang/hy.json')
  }
}
