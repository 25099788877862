import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import alert from 'utils/alert'
import axios from 'utils/axios'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import HtmlControl from 'components/HtmlControl'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { FALLBACK } from 'utils/localization/config'
import { LANGUAGE } from 'utils/constants'
import { useFormik } from 'formik'
import { objectToFormData } from 'utils/helpers'
import { Button, Tabs, Tab, Row, Col } from 'react-bootstrap'

const PrivacyPolicyPage = () => {
  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [active, setActive] = useState(FALLBACK)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const res = await axios.get('/admin/privacy-policy')
        setData(res.data)
      } catch (error) {
        /* empty */
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const getInitial = () => {
    const shape = {
      banner: null
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] = {
        text: data[code] ? data[code].text || '' : '',
        title: data[code] ? data[code].title || '' : ''
      }
    })
    return shape
  }

  const getShape = () => {
    const shape = {
      banner: data.banner
        ? Yup.mixed().notRequired()
        : Yup.mixed().required(t('Required', 'validation'))
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] = Yup.object().shape({
        text:
          code === FALLBACK || code === active
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired(),
        title:
          code === FALLBACK || code === active
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired()
      })
    })
    return shape
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: getInitial(),
    validationSchema: Yup.object().shape(getShape()),
    enableReinitialize: true
  })

  const handleSubmit = async (values) => {
    try {
      setError(null)
      setLoading(true)

      const res = await axios.put(
        '/admin/privacy-policy',
        objectToFormData(values)
      )

      setData(res.data)
      setFieldValue('banner', null)
      alert.success()
    } catch (error) {
      setError(error)
      alert.error()
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    if (await confirm(t('ConfirmBannerDelete'))) {
      try {
        setError(null)
        setLoading(true)

        await axios.delete(
          `/admin/image/privacy-policy?image=${data.banner}&type=banner`
        )
        setData({ ...data, banner: null })

        alert.success()
      } catch (error) {
        setError(error)
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  const handleSelectTab = (value) => {
    setActive(value)
  }

  const handleChangeField = (name) => (value) => {
    setFieldValue(name, value)
  }

  return (
    <>
      <h1>{t('PagePrivacyPolicy', 'menu')}</h1>

      <LoadingWrapper loading={loading}>
        <form onSubmit={onSubmit}>
          <ErrorMessage error={error} />

          <Row>
            <Col lg={6}>
              <Tabs
                onSelect={handleSelectTab}
                activeKey={active}
                className='mb-4'
              >
                {LANGUAGE.getLanguages().map((locale) => (
                  <Tab
                    key={locale.code}
                    title={locale.name}
                    eventKey={locale.code}
                  >
                    <TextControl
                      name={`${locale.code}.title`}
                      label={t('LabelTitle')}
                      value={values[locale.code].title}
                      error={
                        touched?.[locale.code] && errors[locale.code]?.title
                      }
                      onChange={handleChange}
                      required
                    />
                    <HtmlControl
                      name={`${locale.code}.text`}
                      label={t('LabelText')}
                      value={values[locale.code].text}
                      error={
                        touched?.[locale.code] && errors[locale.code]?.text
                      }
                      onChange={handleChangeField(`${locale.code}.text`)}
                      required
                    />
                  </Tab>
                ))}
              </Tabs>
            </Col>
            <Col lg={6}>
              <FileUpload
                label={t('LabelBanner')}
                value={values.banner}
                error={touched.banner && errors.banner}
                files={data.banner}
                onDelete={handleDelete}
                onChange={handleChangeField('banner')}
                required
              />
            </Col>
          </Row>
          <Button type='submit'>{t('Save')}</Button>
        </form>
      </LoadingWrapper>
    </>
  )
}

export default PrivacyPolicyPage
