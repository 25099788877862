import axios from 'utils/axios'
import alert from 'utils/alert'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { LANGUAGE } from 'utils/constants'
import { deleteFromArray } from 'utils/helpers'
import { useState, useEffect } from 'react'
import { Row, Col, Tab, Tabs, Card, Button, Form } from 'react-bootstrap'
import {
  swap,
  GridItem,
  GridDropZone,
  GridContextProvider
} from 'react-grid-dnd'

import '../styles.scss'

const ProductEditForm = (props) => {
  const {
    id,
    product,
    baseLocale,
    afterSubmit,
    translations,
    afterFilesSubmit
  } = props

  const [files, setFiles] = useState([])
  const [values, setValues] = useState({})
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(baseLocale)

  useEffect(() => {
    if (product?.photo) {
      setFiles(
        product.photo.images.map((image) => ({
          src: image,
          isCover: image === product.photo.coverOriginal,
          isUploaded: true
        })) || []
      )
    }
  }, [product])

  useEffect(() => {
    const parsedValues = {}
    const baseValues = {
      title: '',
      events: [],
      description: ''
    }

    translations.forEach((translation) => {
      const { locale, ...rest } = translation
      parsedValues[locale] = rest

      if (locale === baseLocale) {
        baseValues.events = rest.events.map((event) => ({
          id: event.id,
          title: '',
          description: ''
        }))
      }
    })

    LANGUAGE.getLanguageCodes().forEach((locale) => {
      if (!parsedValues[locale]) {
        parsedValues[locale] = baseValues
      } else if (
        parsedValues[locale].events.length !== baseValues.events.length
      ) {
        const events = baseValues.events.map((e) => {
          const createdEvent = parsedValues[locale].events.find(
            (ce) => ce.id === e.id
          )
          return createdEvent || e
        })
        parsedValues[locale].events = events
      }
    })

    setValues(parsedValues)
  }, [baseLocale, translations])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)

      const payload = [
        {
          locale: activeTab,
          ...values[activeTab]
        }
      ]

      const res = await axios.put(`/admin/product/${id}/translations`, payload)
      afterSubmit(res.data, activeTab)
    } catch (error) {
      alert.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (locale, name) => (value) => {
    const newLocaleData = { ...values[locale] }
    newLocaleData[name] = value
    setValues({ ...values, [locale]: newLocaleData })
  }

  const handleChangeEvent = (locale, index, name) => (value) => {
    const newLocaleData = { ...values[locale] }
    newLocaleData.events[index][name] = value
    setValues({ ...values, [locale]: newLocaleData })
  }

  const handleUploadFiles = async () => {
    try {
      setLoading(true)

      const formData = new FormData()

      files.forEach((image, index) => {
        if (image.file) {
          formData.append(`file-${index}`, image.file)
          if (image.isCover) {
            formData.append('cover', image.file)
          }
        } else if (image.src) {
          formData.append(`files.file-${index}`, image.src)
          if (image.isCover) {
            formData.append('cover', image.src)
          }
        }
      })

      const res = await axios.post(`/admin/product/${id}/images`, formData)

      afterFilesSubmit(res.data)
    } catch {
      /* empty */
    } finally {
      setLoading(false)
    }
  }

  const handleAddFiles = (uploaded) => {
    setFiles([
      ...files,
      ...uploaded.map((file, index) => ({
        src: URL.createObjectURL(file),
        file: file,
        isCover: files.length === 0 && index === 0,
        isUploaded: false
      }))
    ])
  }

  const handleDeleteFile = (file, index) => async () => {
    if (file.file) {
      const newList = deleteFromArray(files, { index })
      if (file.isCover && newList.length) {
        newList[0].isCover = true
      }
      setFiles(newList)
    } else {
      if (id && (await confirm(t('ConfirmDeleteImage')))) {
        try {
          await axios.delete(`/admin/product/${id}/images?&image=${file.src}`)
          const newList = deleteFromArray(files, { index })
          if (file.isCover && newList.length) {
            newList[0].isCover = true
          }
          setFiles(newList)
        } catch {
          alert.error(t('ErrorDelete', 'validation'))
        }
      }
    }
  }

  const handleChangeFile = (index) => (event) => {
    setFiles(
      files.map((image, idx) => ({
        ...image,
        isCover: event.target.checked ? (idx === index ? true : false) : false
      }))
    )
  }

  const handlePhotosChange = (_, sourceIndex, targetIndex) => {
    const nextState = swap(files, sourceIndex, targetIndex)
    setFiles(nextState)
  }

  return (
    <LoadingWrapper loading={loading}>
      <form onSubmit={handleSubmit}>
        <Tabs
          onSelect={setActiveTab}
          className='mt-4 mb-4'
          activeKey={activeTab}
        >
          {LANGUAGE.getLanguageCodes().map((locale) => (
            <Tab key={locale} eventKey={locale} title={t(locale)}>
              <TextControl
                label={t('LabelProductName')}
                value={values[locale]?.title}
                onChange={handleChange(locale, 'title')}
                useFormik={false}
              />

              <TextControl
                as='textarea'
                rows={6}
                label={t('EventDescription')}
                value={values[locale]?.description}
                onChange={handleChange(locale, 'description')}
                useFormik={false}
              />

              {product?.productType?.isGroup &&
                product?.locationDetails?.group?.customerMeetingType ===
                  'fixedLocation' && (
                  <TextControl
                    label={t('LabelAddressGroup')}
                    value={values[locale]?.groupLocationAddress}
                    onChange={handleChange(locale, 'groupLocationAddress')}
                    useFormik={false}
                  />
                )}

              {product?.productType?.isIndividual &&
                product?.locationDetails?.individual?.customerMeetingType ===
                  'fixedLocation' && (
                  <TextControl
                    label={t('LabelAddressIndividual')}
                    value={values[locale]?.individualLocationAddress}
                    onChange={handleChange(locale, 'individualLocationAddress')}
                    useFormik={false}
                  />
                )}

              <label className='form-label'>{t('LabelEvents')}</label>
              <Row>
                {values[locale]?.events?.map((event, index) => (
                  <Col md={4} key={event.id}>
                    <Card>
                      <Card.Body>
                        <Card.Title>№ {index + 1}</Card.Title>

                        <TextControl
                          label={t('LabelName')}
                          value={values[locale]?.events?.[index]?.title}
                          onChange={handleChangeEvent(locale, index, 'title')}
                          useFormik={false}
                        />

                        <TextControl
                          label={t('Address')}
                          value={values[locale]?.events?.[index]?.address}
                          onChange={handleChangeEvent(locale, index, 'address')}
                          useFormik={false}
                        />

                        <TextControl
                          as='textarea'
                          rows={4}
                          label={t('LabelDescription')}
                          value={values[locale]?.events?.[index]?.description}
                          onChange={handleChangeEvent(
                            locale,
                            index,
                            'description'
                          )}
                          useFormik={false}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>
          ))}
        </Tabs>

        <Button type='submit' className='mt-5'>
          {t('SaveFor', 'common', {
            locale: LANGUAGE.getNameByCode(activeTab)
          })}
        </Button>
      </form>

      <hr className='mt-5 mb-5' />

      <FileUpload
        label={t('SectionPhoto')}
        onChange={handleAddFiles}
        multiple
      />

      <div className='b-photos'>
        <GridContextProvider onChange={handlePhotosChange}>
          <GridDropZone
            id='photos'
            style={{ height: Math.ceil(files.length / 4) * 120 }}
            rowHeight={120}
            boxesPerRow={4}
          >
            {files.map((file, index) => (
              <GridItem key={file.src}>
                <div className='b-photos__image'>
                  <img src={file.src} alt='' />

                  <div className='b-photos__select'>
                    <Form.Check
                      id={file.src}
                      type='checkbox'
                      custom
                      checked={file.isCover}
                      onChange={handleChangeFile(index)}
                      disabled={files.length === 1}
                    />
                  </div>

                  <button
                    type='button'
                    onClick={handleDeleteFile(file, index)}
                    className='b-photos__delete'
                  >
                    ×
                  </button>
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      </div>
      <Button
        onClick={handleUploadFiles}
        disabled={!files.length}
        className='mt-5'
      >
        {t('Save')}
      </Button>
    </LoadingWrapper>
  )
}

export default ProductEditForm
