import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import Actions from './components/Actions'
import Comments from './components/Comments'
import TextControl from 'components/TextControl'
import DateControl from 'components/DateControl'
import ErrorMessage from 'components/Error'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import ControlInputGroup from 'components/ControlInputGroup'
import { t } from 'utils/localization'
import { useFormik } from 'formik'
import { objectToFormData } from 'utils/helpers'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useHistory, useParams } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { Button, Badge, Tabs, Tab, Row, Col } from 'react-bootstrap'

const MerchantPage = () => {
  const params = useParams()
  const history = useHistory()

  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState([])

  const ValidationSchemaTab1 = Yup.object().shape({
    type: Yup.string().required(t('Required', 'validation')),
    contractDate: Yup.string().required(t('Required', 'validation')),
    companyRegNum: Yup.string().required(t('Required', 'validation')),
    companyIdentificationNo: Yup.string().required(t('Required', 'validation')),
    contractNumberTelcell: Yup.string().required(t('Required', 'validation')),
    contractNumberBustourma: Yup.string().required(t('Required', 'validation')),
    directorSocialId: Yup.string().required(t('Required', 'validation')),
    companyAddress: Yup.string()
      .nullable()
      .required(t('Required', 'validation')),
    publicCompanyTitle: Yup.string().required(t('Required', 'validation')),
    companyTitle: Yup.string().required(t('Required', 'validation')),
    juridicalType: Yup.string().required(t('Required', 'validation')),
    INN: Yup.string()
      .matches(/^[0-9]{8}$/, t('InvalidTIN', 'validation'))
      .required(t('Required', 'validation')),
    directorFirstName: Yup.string().required(t('Required', 'validation')),
    directorLastName: Yup.string().required(t('Required', 'validation')),
    companySite: Yup.string().notRequired(),
    physicalCompanyAddress: Yup.string().notRequired(),
    smallAvatar: Yup.mixed().notRequired(),
    phone: Yup.string().test(
      'check-phone',
      t('InvalidPhone', 'validation'),
      (value) => {
        return value ? isValidPhoneNumber(value) : false
      }
    ),
    email: Yup.string().email(t('InvalidEmail', 'validation'))
  })

  const ValidationSchemaTab2 = Yup.object().shape({
    bankEmail: Yup.string()
      .email(t('InvalidEmail', 'validation'))
      .notRequired(),
    bankINN: Yup.string().notRequired(),
    bankTitle: Yup.string().notRequired(),
    bankAccount: Yup.string().notRequired(),
    bankClientName: Yup.string().notRequired()
  })

  const formikTab1 = useFormik({
    onSubmit: (values) => handleSubmit(values, 1),
    initialValues: {
      isTrusted: data.isTrusted || false,
      type: data.type || 'company',
      INN: data.INN || '',
      companySite: data.companySite || '',
      companyTitle: data.companyTitle || '',
      juridicalType: data.juridicalType || '',
      companyAddress: data.companyAddress || null,
      directorSocialId: data.directorSocialId || '',
      directorLastName: data.directorLastName || '',
      directorFirstName: data.directorFirstName || '',
      publicCompanyTitle: data.publicCompanyTitle || '',
      contractDate: data.contractDate || '',
      companyRegNum: data.companyRegNum || '',
      physicalCompanyAddress: data.physicalCompanyAddress || '',
      companyIdentificationNo: data.companyIdentificationNo || '',
      contractNumberTelcell: data.contractNumberTelcell || '',
      contractNumberBustourma: data.contractNumberBustourma || '',
      smallAvatar: null,
      email: data.email || '',
      phone: data.phone || '+'
    },
    validationSchema: ValidationSchemaTab1,
    enableReinitialize: true
  })

  const formikTab2 = useFormik({
    onSubmit: (values) => handleSubmit(values, 3),
    initialValues: {
      bankClientName: data.bankClientName || '',
      bankEmail: data.bankEmail || '',
      bankINN: data.bankINN || '',
      bankTitle: data.bankTitle || '',
      bankAccount: data.bankAccount || ''
    },
    validationSchema: ValidationSchemaTab2,
    enableReinitialize: true
  })

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        const res = await axios.get(`/admin/merchant/${params.id}`)
        isMounted && setData(res.data)
      } catch {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    if (params.id) {
      getData()
    }
    return () => {
      isMounted = false
    }
  }, [params.id])

  const getComments = useCallback(async () => {
    if (params.id) {
      try {
        const res = await axios.get(
          `/admin/check/merchant/${params.id}/comment`
        )
        setComments(res.data)
      } catch (error) {
        /* empty */
      }
    }
  }, [params.id])

  useEffect(() => {
    getComments()
  }, [getComments])

  const afterStatusChange = (merchant) => {
    setData({ ...data, ...merchant })
    getComments()
  }

  const handleSubmit = async (values, tab) => {
    try {
      setError(null)
      setLoading(true)

      const url = `/admin/merchant/${params.id || ''}`
      const data = objectToFormData(values)
      const method = 'put'
      // const method = id ? 'put' : 'post'

      const res = await axios({ url, data, method })

      if (params.id) {
        if (res.data) {
          setData(res.data)

          if (tab === 1) {
            formikTab1.setFieldValue('avatar', null)
            formikTab1.setFieldValue('smallAvatar', null)
          }
        }
        alert.success()
      } else {
        history.replace(`/merchants/${res.data.id}`)
      }
    } catch (error) {
      setError(error)
      alert.error()
    } finally {
      setLoading(false)
    }
  }

  const renderStatus = () => {
    if (data.state) {
      const color =
        data.state.code === 'waitCheck'
          ? 'secondary'
          : data.state.code === 'reject'
          ? 'danger'
          : data.state.code === 'approve'
          ? 'success'
          : 'default'

      return (
        <Badge variant={color} className='ml-3'>
          {data.state.title}
        </Badge>
      )
    }
    return null
  }

  const handleChangeFirstTab = (name) => (value) => {
    if (name === 'phone') {
      const val = value.indexOf('+') !== 0 ? '+' + value : value
      formikTab1.setFieldValue(name, val)
    } else {
      formikTab1.setFieldValue(name, value)
    }
  }

  return (
    <>
      <div className='mb-5'>
        <div className='d-flex align-items-center'>
          <h1 className='m-0'>
            {t(params.id ? 'EditMerchant' : 'CreateMerchant')}
          </h1>
          {renderStatus()}
        </div>
        {data.isTrusted === false && (
          <Badge variant='danger'>{t('BadgeNotTrusted')}</Badge>
        )}
        {data.inBlackList === true && (
          <Badge variant='dark' className='ml-1'>
            {t('BadgeBlackList')}
          </Badge>
        )}
        {data.isSuspension === true && (
          <Badge variant='warning' className='ml-1'>
            {t('BadgeSuspension')}
          </Badge>
        )}
      </div>

      <div className='mb-5'>
        <Row>
          <Actions data={data} afterSubmit={afterStatusChange} />

          <Comments id={data.id} comments={comments} />
        </Row>
      </div>

      <LoadingWrapper loading={loading}>
        <ErrorMessage error={error} />

        <Tabs className='mb-5' defaultActiveKey='general'>
          <Tab title={t('TabBasicInformation')} eventKey='general'>
            <form onSubmit={formikTab1.handleSubmit}>
              <Row>
                <Col lg={12}>
                  <div className='d-flex justify-content-between'>
                    <ControlInputGroup
                      type='checkbox'
                      onChange={formikTab1.handleChange}
                      options={[
                        {
                          id: 'isTrusted',
                          label: t('LabelIsTrusted'),
                          value: formikTab1.values.isTrusted
                        }
                      ]}
                    />

                    {!!data.certificate && (
                      <a
                        rel='noreferrer'
                        href={data.certificate}
                        target='_blank'
                      >
                        {t('LabelCertificate')}
                      </a>
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <ControlInputGroup
                    name='type'
                    label={t('LabelProductType')}
                    value={formikTab1.values.type}
                    error={formikTab1.touched.type && formikTab1.errors.type}
                    onChange={formikTab1.handleChange}
                    options={[
                      { label: t('OptionCompany'), value: 'company' },
                      { label: t('OptionIndividual'), value: 'individual' }
                    ]}
                  />
                </Col>
                <Col lg={6} />
                <Col lg={6}>
                  <TextControl
                    name='directorSocialId'
                    label={t('LabelSocialNumber')}
                    value={formikTab1.values.directorSocialId}
                    error={
                      formikTab1.touched.directorSocialId &&
                      formikTab1.errors.directorSocialId
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <DateControl
                    name='contractDate'
                    label={t('LabelContractDate')}
                    value={formikTab1.values.contractDate}
                    error={
                      formikTab1.touched.contractDate &&
                      formikTab1.errors.contractDate
                    }
                    onChange={handleChangeFirstTab('contractDate')}
                  />
                  <TextControl
                    name='companyRegNum'
                    label={t('LabelCompanyRegNum')}
                    value={formikTab1.values.companyRegNum}
                    error={
                      formikTab1.touched.companyRegNum &&
                      formikTab1.errors.companyRegNum
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    name='companyIdentificationNo'
                    label={t('LabelCompanyIdentificationNo')}
                    value={formikTab1.values.companyIdentificationNo}
                    error={
                      formikTab1.touched.companyIdentificationNo &&
                      formikTab1.errors.companyIdentificationNo
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    name='contractNumberTelcell'
                    label={t('LabelContractNumberTelcell')}
                    value={formikTab1.values.contractNumberTelcell}
                    error={
                      formikTab1.touched.contractNumberTelcell &&
                      formikTab1.errors.contractNumberTelcell
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    name='contractNumberBustourma'
                    label={t('LabelContractNumberBustourma')}
                    value={formikTab1.values.contractNumberBustourma}
                    error={
                      formikTab1.touched.contractNumberBustourma &&
                      formikTab1.errors.contractNumberBustourma
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    name='companyAddress'
                    label={t('LabeLAddress')}
                    value={formikTab1.values.companyAddress}
                    error={
                      formikTab1.touched.companyAddress &&
                      formikTab1.errors.companyAddress
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    name='physicalCompanyAddress'
                    label={t('LabelPhysicalAddress')}
                    value={formikTab1.values.physicalCompanyAddress}
                    error={
                      formikTab1.touched.physicalCompanyAddress &&
                      formikTab1.errors.physicalCompanyAddress
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    name='publicCompanyTitle'
                    label={t('LabelPublicCompanyName')}
                    value={formikTab1.values.publicCompanyTitle}
                    error={
                      formikTab1.touched.publicCompanyTitle &&
                      formikTab1.errors.publicCompanyTitle
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    name='companyTitle'
                    label={t('LabelPublicName')}
                    value={formikTab1.values.companyTitle}
                    error={
                      formikTab1.touched.companyTitle &&
                      formikTab1.errors.companyTitle
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <SelectControl
                    name='juridicalType'
                    label={t('LabelCompanyType')}
                    value={formikTab1.values.juridicalType}
                    error={
                      formikTab1.touched.juridicalType &&
                      formikTab1.errors.juridicalType
                    }
                    onChange={handleChangeFirstTab('juridicalType')}
                    options={[
                      { label: t('LegalEntityTypeIP'), value: 'ip' },
                      { label: t('LegalEntityTypeOOO'), value: 'ooo' },
                      { label: t('LegalEntityTypeZAO'), value: 'zao' }
                    ]}
                  />
                  <TextControl
                    mask='99999999'
                    name='INN'
                    label={t('LabelTIN')}
                    value={formikTab1.values.INN}
                    error={formikTab1.touched.INN && formikTab1.errors.INN}
                    onChange={formikTab1.handleChange}
                  />
                </Col>
                <Col lg={6}>
                  <TextControl
                    name='directorFirstName'
                    label={t('LabelFirstNameDirector')}
                    value={formikTab1.values.directorFirstName}
                    error={
                      formikTab1.touched.directorFirstName &&
                      formikTab1.errors.directorFirstName
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    name='directorLastName'
                    label={t('LabelLastNameDirector')}
                    value={formikTab1.values.directorLastName}
                    error={
                      formikTab1.touched.directorLastName &&
                      formikTab1.errors.directorLastName
                    }
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    name='phone'
                    label={t('LabelPhone')}
                    value={formikTab1.values.phone}
                    error={formikTab1.touched.phone && formikTab1.errors.phone}
                    onChange={handleChangeFirstTab('phone')}
                    useFormik={false}
                  />
                  <TextControl
                    name='email'
                    label={t('LabelEmail')}
                    value={formikTab1.values.email}
                    error={formikTab1.touched.email && formikTab1.errors.email}
                    onChange={handleChangeFirstTab('email')}
                    useFormik={false}
                  />
                  <TextControl
                    name='companySite'
                    label={t('LabelWebsite')}
                    value={formikTab1.values.companySite}
                    onChange={formikTab1.handleChange}
                  />
                  <TextControl
                    label={t('Logo')}
                    value={formikTab1.values.smallAvatar}
                    files={data.smallAvatar}
                    onChange={handleChangeFirstTab('smallAvatar')}
                  />
                </Col>
              </Row>
              <div className='mt-5'>
                <Button type='submit'>{t('Save')}</Button>
              </div>
            </form>
          </Tab>
          <Tab title={t('TabBankInformation')} eventKey='bank'>
            <form onSubmit={formikTab2.handleSubmit}>
              <Row>
                <Col lg={6}>
                  <TextControl
                    name='bankTitle'
                    label={t('LabelBankTitle')}
                    value={formikTab2.values.bankTitle}
                    error={
                      formikTab2.touched.bankTitle &&
                      formikTab2.errors.bankTitle
                    }
                    onChange={formikTab2.handleChange}
                  />
                  <TextControl
                    name='bankClientName'
                    label={t('LabelBankClientName')}
                    value={formikTab2.values.bankClientName}
                    error={
                      formikTab2.touched.bankClientName &&
                      formikTab2.errors.bankClientName
                    }
                    onChange={formikTab2.handleChange}
                  />
                  <TextControl
                    name='bankEmail'
                    label={t('LabelBankEmail')}
                    value={formikTab2.values.bankEmail}
                    error={
                      formikTab2.touched.bankEmail &&
                      formikTab2.errors.bankEmail
                    }
                    onChange={formikTab2.handleChange}
                  />
                  <TextControl
                    name='bankAccount'
                    label={t('LabelBankAccount')}
                    value={formikTab2.values.bankAccount}
                    error={
                      formikTab2.touched.bankAccount &&
                      formikTab2.errors.bankAccount
                    }
                    onChange={formikTab2.handleChange}
                  />
                  <TextControl
                    name='bankINN'
                    label={t('LabelTIN')}
                    value={formikTab2.values.bankINN}
                    error={
                      formikTab2.touched.bankINN && formikTab2.errors.bankINN
                    }
                    onChange={formikTab2.handleChange}
                  />
                </Col>
              </Row>
              <div className='mt-5'>
                <Button type='submit'>{t('Save')}</Button>
              </div>
            </form>
          </Tab>
        </Tabs>
      </LoadingWrapper>
    </>
  )
}

export default MerchantPage
