export const LANGUAGE = {
  Russian: {
    iso: 'ru_RU',
    code: 'ru',
    name: 'RUS',
    icon: '&#x2690;',
    calendar: 'ru'
  },
  Armenian: {
    iso: 'hy_AM',
    code: 'hy',
    name: 'ARM',
    icon: '&#x2690;',
    calendar: 'hy-am'
  },
  English: {
    iso: 'en_US',
    code: 'en',
    name: 'ENG',
    icon: '&#x2690;',
    calendar: 'en'
  },
  // Chinese: {
  //   iso: 'zh_CN',
  //   code: 'zh',
  //   name: 'CHN',
  //   icon: '&#x2690;'
  // },
  // Farsi: {
  //   iso: 'fa_IR',
  //   code: 'fa',
  //   name: 'IRN',
  //   icon: '&#x2690;'
  // },
  getLanguage(code) {
    let languages = Object.values(this)
    return languages.find((l) => l.code === code)
  },
  getLanguages() {
    let languages = Object.values(this)
    languages.splice(languages.length - 6, 6)
    return languages
  },
  getLanguageCodes() {
    let languages = Object.values(this)
    languages.splice(languages.length - 6, 6)
    return languages.map((lang) => lang.code)
  },
  getNameByCode(code) {
    const language = Object.values(this).find((item) => item.code === code)
    return language ? language.name : code
  },
  getIsoNameByCode(code) {
    const language = Object.values(this).find((item) => item.code === code)
    return language ? language.iso : code
  },
  getMomentLocaleByCode(code) {
    return MOMENT_LOCALE[code] || 'ru'
  }
}

export const MOMENT_LOCALE = {
  hy: 'hy-am',
  en: 'en',
  ru: 'ru',
  zh: 'zh-cn',
  fa: 'en'
}

export const USER_ROLES = {
  Admin: 'admin'
}

export const STATUS_COLORS = {
  new: 'primary',
  work: 'secondary',
  reject: 'danger',
  waitUser: 'warning',
  accept: 'success',
  rejectUser: 'danger'
}
