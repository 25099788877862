import { t } from 'utils/localization'
import { useState } from 'react'
import { Collapse, Button, Card, Col } from 'react-bootstrap'

const Comments = (props) => {
  const { comments } = props

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    setVisible(!visible)
  }

  return !comments.langth ? null : (
    <Col md={6}>
      <Button variant='link' onClick={toggleVisible}>
        {t(`${visible ? 'Hide' : 'Show'}Comments`)}
      </Button>
      <Collapse in={visible}>
        <div>
          {comments.map((comment) => (
            <Card key={comment.id} className='mb-2 mt-4'>
              <Card.Body>
                <Card.Title>{comment.admin}</Card.Title>
                <Card.Text>{comment.text}</Card.Text>
                <Card.Text>
                  <small className='text-muted'>{comment.createdAt}</small>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Collapse>
    </Col>
  )
}

export default Comments
