import clsx from 'clsx'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import LocaleService from 'utils/localization'
import { Form } from 'react-bootstrap'
import { forwardRef } from 'react'
import { RequiredSpan } from 'components/Error'

import './styles.scss'

const DateControl = (props) => {
  const {
    name,
    label,
    value,
    error,
    onChange,
    required,
    className,
    placeholder,
    ...rest
  } = props

  let date = null
  if (moment(value, 'DD.MM.YYYY').isValid()) {
    date = moment(value, 'DD.MM.YYYY')
  }

  const handleChange = (value) => {
    if (onChange) {
      onChange(value ? moment(value).format('DD.MM.YYYY') : '')
    }
  }

  return (
    <Form.Group className={clsx('mb-4', className)}>
      {label && (
        <Form.Label>
          {label}
          {required ? <RequiredSpan /> : ''}
        </Form.Label>
      )}

      <DatePicker
        id={name}
        error={error}
        locale={LocaleService.locale}
        selected={date ? date.toDate() : undefined}
        onChange={handleChange}
        dateFormat='dd.MM.yyyy'
        isClearable
        customInput={<CustomDatePicker error={error} />}
        placeholderText={placeholder}
        {...rest}
      />

      {error && (
        <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default DateControl

const CustomDatePicker = forwardRef(({ value, error, onClick }, ref) => {
  return (
    <Form.Control
      ref={ref}
      value={value}
      onClick={onClick}
      isInvalid={!!error}
    />
  )
})
