/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'utils/axios'
import alert from 'utils/alert'
import Table from 'components/Table'
import TextControl from 'components/TextControl'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { confirm } from 'components/Dialog'
import { useProfile } from 'utils/hooks/useContext'
import { useIsMounted } from 'utils/hooks'
import { Badge, Row, Col } from 'react-bootstrap'
import { useDebouncedCallback } from 'use-debounce'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { objectToUrlParams, getShortText, parsePhone } from 'utils/helpers'

const ProductQuestionsPage = () => {
  const isMounted = useIsMounted()
  const { loadAdminNotifications } = useProfile()

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [filter, setFilter] = useState({
    page: 1,
    states: [],
    search: '',
    rowsPerPage: 10
  })

  const STATUSES = {
    new: t('StatusesNew'),
    close: t('StatusesClose')
  }

  const STATUS_COLORS = {
    new: 'primary',
    close: 'secondary'
  }

  const getList = useDebouncedCallback(async () => {
    try {
      isMounted && setLoading(true)
      const res = await axios.get(
        `/admin/product/questions/?${objectToUrlParams(filter)}`
      )
      if (isMounted) {
        setList(res.data.map((item) => ({ ...item, showAllText: false })))
        setPagination(res.pagination)
      }
    } catch {
      setList([])
      setPagination({})
    } finally {
      isMounted && setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getList()
  }, [filter, getList])

  const handleShowText = useCallback(
    (id, show) => (event) => {
      event.preventDefault()

      const index = list.findIndex((item) => item.id === id)
      if (index > -1) {
        let newList = [...list]
        newList[index].showAllText = show
        setList(newList)
      }
    },
    [list]
  )

  const handleSubmit = useCallback(
    (id) => async (event) => {
      event.preventDefault()

      if (await confirm(t('ConfirmMessage'))) {
        try {
          await axios.put(`/admin/product/question/${id}`)

          loadAdminNotifications()
          getList()
          alert.success()
        } catch (error) {
          alert.error()
        }
      }
    },
    [getList, loadAdminNotifications]
  )

  const handleChangeFilter = (name) => (value) => {
    if (name === 'pagination') {
      setFilter({ ...filter, ...value.pagination })
    } else {
      setFilter({ ...filter, [name]: value, page: 1 })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        Cell: ({ value }) => (
          <span className='text-bold'>{`#\xa0${value}`}</span>
        )
      },
      {
        Header: t('Sender'),
        accessor: 'name',
        Cell: ({ value, row }) => (
          <div>
            <div>{value}</div>
            {row.original.email && (
              <div>
                <a href={`mailto:${row.original.email}`}>
                  {row.original.email}
                </a>
              </div>
            )}
            {row.original.phone && (
              <div>
                <a href={`tel:${row.original.phone}`}>
                  {parsePhone(row.original.phone)}
                </a>
              </div>
            )}
          </div>
        )
      },
      {
        Header: t('LabelText'),
        accessor: 'text',
        Cell: ({
          value,
          row: {
            original: { id, showAllText }
          }
        }) => {
          const truncated = getShortText(value)

          return (
            <div>
              <div>{showAllText ? value : truncated}</div>
              {truncated !== value && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href='#' onClick={handleShowText(id, !showAllText)}>
                  <strong>{t(showAllText ? 'HideText' : 'ShowText')}</strong>
                </a>
              )}
            </div>
          )
        }
      },
      {
        Header: t('LabelProduct'),
        accessor: 'product',
        Cell: ({ value }) => (
          <div>
            <Link to={`/products/${value.id}`} target='_blank'>
              <span className='text-bold'>{value.title}</span>
            </Link>
          </div>
        )
      },
      {
        Header: t('Partner'),
        accessor: 'product.merchant',
        Cell: ({ value }) => (
          <div>
            <Link to={`/merchants/${value.id}`} target='_blank'>
              <span className='text-bold'>{value.publicCompanyTitle}</span>
            </Link>
            <div>
              <a href={`tel:${value.phone}`}>{parsePhone(value.phone)}</a>
            </div>
          </div>
        )
      },
      {
        Header: t('LabelStatus'),
        accessor: 'state',
        Cell: ({ value }) => {
          const status = STATUSES[value]
          return status ? (
            <Badge variant={STATUS_COLORS[value]}>{status}</Badge>
          ) : null
        }
      },
      {
        Header: '',
        id: 'action',
        Cell: ({
          row: {
            original: { id, state }
          }
        }) =>
          state !== 'close' && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href='#' onClick={handleSubmit(id)}>
              {t('CloseRequest')}
            </a>
          )
      }
    ],
    [STATUSES, handleSubmit, STATUS_COLORS, handleShowText]
  )

  return (
    <>
      <h1>{t('ProductQuestions', 'menu')}</h1>

      <LoadingWrapper loading={loading}>
        <Row>
          <Col lg={6}>
            <TextControl
              name='search'
              value={filter.search}
              onChange={handleChangeFilter('search')}
              multiple
              useFormik={false}
              placeholder={t('LabelSearch')}
            />
          </Col>
          <Col lg={6}>
            <SelectControl
              name='status'
              value={filter.states}
              options={Object.entries(STATUSES).map(([value, label]) => ({
                value,
                label
              }))}
              onChange={handleChangeFilter('states')}
              multiple
              useFormik={false}
              placeholder={t('SelectStatus')}
            />
          </Col>
        </Row>
        <Table
          data={list}
          columns={columns}
          onChange={handleChangeFilter('pagination')}
          pagination={pagination}
        />
      </LoadingWrapper>
    </>
  )
}

export default ProductQuestionsPage
