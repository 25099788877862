import { Badge } from 'react-bootstrap'

const COLOR = {
  reject: 'danger',
  suspend: 'danger',
  approve: 'success',
  waitCheck: 'secondary'
}

const Status = (props) => {
  const { code, title } = props

  return <Badge variant={COLOR[code]}>{title}</Badge>
}

export default Status
