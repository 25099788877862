import axios from 'utils/axios'
import Table from 'components/Table'
import Rating from 'components/Rating'
import TextControl from 'components/TextControl'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { useIsMounted } from 'utils/hooks'
import { objectToUrlParams } from 'utils/helpers'
import { useDebouncedCallback } from 'use-debounce'
import { Button, Alert, Row, Col } from 'react-bootstrap'
import { useMemo, useState, useEffect } from 'react'

const ReviewsPage = () => {
  const isMounted = useIsMounted()

  const [loading, setLoading] = useState(false)
  const [reviews, setReviews] = useState([])
  const [statuses, setStatuses] = useState([])
  const [pagination, setPagination] = useState({})
  const [newRequests, setNewRequests] = useState(0)
  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    states: [],
    rating: [],
    products: [],
    rowsPerPage: 10
  })

  const getReviews = useDebouncedCallback(async () => {
    try {
      isMounted && setLoading(true)
      const res = await axios.get(`/admin/reviews?${objectToUrlParams(filter)}`)
      if (isMounted) {
        setReviews(res.data)
        setPagination(res.pagination)
      }
    } catch {
      setReviews([])
      setPagination({})
    } finally {
      isMounted && setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getReviews()
  }, [filter, getReviews])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await axios.get('/admin/reviews/new')
        setNewRequests(res.data || 0)
      } catch (error) {
        /* empty */
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await axios.get('/admin/reviews/states')
        setStatuses(
          res.data.map((item) => ({
            label: item.title,
            value: item.code
          }))
        )
      } catch (error) {
        /* empty */
      }
    })()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        Cell: ({ value }) => (
          <Link to={`/reviews/${value}`}>
            <span className='text-bold'>{`# ${value}`}</span>
          </Link>
        )
      },
      {
        Header: t('TableUser'),
        accessor: 'user',
        Cell: ({ value }) => (
          <Link to={`/users/${value.id}`} target='_blank'>
            <span className='text-bold'>{value.fullName || `# ${value}`}</span>
          </Link>
        )
      },
      {
        Header: t('TableText'),
        accessor: 'text'
      },
      {
        Header: t('TableRating'),
        accessor: 'rating'
      },
      {
        Header: t('LabelStatus'),
        accessor: 'state'
      }
    ],
    []
  )

  const handleChange = (name) => (value) => {
    setFilter({ ...filter, [name]: value, page: 1 })
  }

  const handleShowNewRequests = () => {
    setFilter({
      page: 1,
      search: '',
      states: ['new'],
      rating: [],
      products: [],
      rowsPerPage: 10
    })
  }

  const handleChangePagination = ({ pagination }) => {
    setFilter({ ...filter, ...pagination })
  }

  return (
    <>
      <h1>{t('ReviewsRequests', 'menu')}</h1>

      {!!newRequests && (
        <Alert variant='success'>
          <Alert.Heading>{t('Attention')}</Alert.Heading>
          <div className='mb-2'>
            {newRequests} {t('NewRequests')}
          </div>
          <Button
            size='sm'
            variant='outline-success'
            onClick={handleShowNewRequests}
          >
            {t('Show')}
          </Button>
        </Alert>
      )}

      <LoadingWrapper loading={loading}>
        <Row>
          <Col lg={6}>
            <TextControl
              label={t('LabelSearch')}
              value={filter.search}
              onChange={handleChange('search')}
              useFormik={false}
            />
          </Col>
          <Col lg={6}>
            <SelectControl
              label={t('LabelStatus')}
              value={filter.states}
              options={statuses}
              onChange={handleChange('states')}
              multiple
            />
          </Col>
          <Col lg={6}>
            <SelectControl
              label={t('LabelRating')}
              value={filter.rating}
              onChange={handleChange('rating')}
              multiple
              options={[
                { label: <Rating value={5} />, value: 5 },
                { label: <Rating value={4} />, value: 4 },
                { label: <Rating value={3} />, value: 3 },
                { label: <Rating value={2} />, value: 2 },
                { label: <Rating value={1} />, value: 1 }
              ]}
            />
          </Col>
          <Col lg={6}>
            <SelectControl
              url={'/admin/main/products?&title='}
              name='products'
              async
              label={t('LabelProduct')}
              keys={{ value: 'id', label: 'title' }}
              onChange={handleChange('products')}
              multiple
            />
          </Col>
        </Row>
        <Table
          data={reviews}
          columns={columns}
          onChange={handleChangePagination}
          pagination={pagination}
        />
      </LoadingWrapper>
    </>
  )
}

export default ReviewsPage
