import { t } from 'utils/localization'
import { Form } from 'react-bootstrap'

import './styles.scss'

const PreviewGroup = (props) => {
  const { label, value } = props

  const BOOL_ANSWERS = {
    true: t('Yes'),
    false: t('No')
  }

  const getValue = () => {
    let result = ''
    if (value != null) {
      if (typeof value === 'string' || typeof value === 'number') {
        return value
      }
      if (typeof value === 'boolean') {
        return BOOL_ANSWERS[value]
      }
      if (typeof value === 'object') {
        return value.address || value
      }
    }
    return result
  }

  return (
    <Form.Group className='b-preview-group mb-4'>
      {label && <Form.Label>{label}</Form.Label>}
      <div className='form-control'>
        <b>{getValue()}</b>
      </div>
    </Form.Group>
  )
}

export default PreviewGroup
