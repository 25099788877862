import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import LocaleService from 'utils/localization'
import { Form } from 'react-bootstrap'
import {
  parsePhoneNumber,
  getCountryCallingCode,
  formatPhoneNumberIntl
} from 'react-phone-number-input/input'

import './styles.scss'

const SelectPhoneControl = (props) => {
  const { error, label, onChange, defaultCountry = 'AM', ...rest } = props

  const [country, setCountry] = useState(defaultCountry)

  const handleChange = (number = '') => {
    const parsed = parsePhoneNumber(number)
    onChange(
      parsed?.number || number,
      number.replace(`+${getCountryCallingCode(country)}`, '')
    )
  }

  return (
    <Form.Group className='b-select-phone'>
      {label && <label className='control-label'>{label}</label>}
      <PhoneInput
        {...rest}
        error={error}
        onChange={handleChange}
        className={error ? 'is-invalid' : ''}
        inputComponent={InputPhone}
        currentCountry={country}
        defaultCountry={defaultCountry}
        onCountryChange={setCountry}
        countrySelectComponent={SelectPhone}
      />
      <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
    </Form.Group>
  )
}

export default SelectPhoneControl

const SelectPhone = React.forwardRef((props, ref) => {
  const { value, onChange, onFocus, onBlur } = props

  const lang =
    LocaleService.locale === 'ru'
      ? require('react-phone-number-input/locale/ru.json')
      : require('react-phone-number-input/locale/en.json')

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <Form.Select
      ref={ref}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={handleChange}
    >
      {['RU', 'AM'].map((country) => (
        <option key={country} value={country}>
          {`${lang[country]} +${getCountryCallingCode(country)}`}
        </option>
      ))}
    </Form.Select>
  )
})

const InputPhone = React.forwardRef((props, ref) => {
  const { value, error, currentCountry, ...rest } = props

  let number = parsePhoneNumber(value || '')
  if (!number) {
    number = parsePhoneNumber(
      `+${getCountryCallingCode(currentCountry)}${value}`
    )
  }

  let formatted = formatPhoneNumberIntl(number?.number) || value
  if (formatted) {
    formatted = formatted.replace(
      `+${getCountryCallingCode(currentCountry)} `,
      ''
    )
  }

  return (
    <Form.Control ref={ref} value={formatted} isInvalid={!!error} {...rest} />
  )
})
