import axios from 'utils/axios'
import alert from 'utils/alert'
import SectionCard from './MainPageSectionCard'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { useState } from 'react'
import { Button, Row, Col } from 'react-bootstrap'

import {
  swap,
  GridItem,
  GridDropZone,
  GridContextProvider
} from 'react-grid-dnd'

const DisplaySettings = (props) => {
  const { items, setSettings } = props

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleChange = (index) => (value) => {
    const values = [...items]
    values[index].visible = value
    setSettings(values)
  }

  const handleSubmit = async () => {
    const show = items.filter((item) => item.visible).map((item) => item.id)
    const order = items.map((item) => item.id)
    const data = { show, order }

    try {
      setError(null)
      setLoading(true)

      await axios.post('/admin/settings/main', data)

      alert.success()
    } catch (error) {
      setError(error)
      alert.error()
    } finally {
      setLoading(false)
    }
  }

  const handleChangeSort = (_, sourceIndex, targetIndex) => {
    const nextState = swap(items, sourceIndex, targetIndex)
    setSettings(nextState)
  }

  return (
    <LoadingWrapper loading={loading}>
      <ErrorMessage error={error} />
      <Row>
        <Col lg={6}>
          <GridContextProvider onChange={handleChangeSort}>
            <GridDropZone
              id='blocks'
              style={{ height: items.length * 100 }}
              rowHeight={100}
              boxesPerRow={1}
            >
              {items.map((item, index) => (
                <GridItem key={item.id}>
                  <SectionCard data={item} onChange={handleChange(index)} />
                </GridItem>
              ))}
            </GridDropZone>
          </GridContextProvider>
        </Col>
      </Row>
      <Button className='mt-5' onClick={handleSubmit}>
        {t('Save')}
      </Button>
    </LoadingWrapper>
  )
}

export default DisplaySettings
