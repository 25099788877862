import { t } from 'utils/localization'
import CategoryList from 'components/CategoryList'

const ProductCategoriesList = () => {
  return (
    <>
      <h1>{t('ProductCategories', 'menu')}</h1>
      <CategoryList
        baseUrl='types'
        withSubCategories
        defaultCategories={[
          {
            id: 'tour',
            ru: 'Тур',
            en: 'Tour',
            fa: 'Tour',
            hy: 'Tour',
            zh: 'Tour'
          },
          {
            id: 'transfer',
            ru: 'Транспорт',
            en: 'Transport',
            fa: 'Transport',
            hy: 'Transport',
            zh: 'Transport'
          },
          {
            id: 'event',
            ru: 'Мероприятия',
            en: 'Events',
            fa: 'Events',
            hy: 'Events',
            zh: 'Events'
          }
        ]}
      />
    </>
  )
}

export default ProductCategoriesList
