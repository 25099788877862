import axios from 'utils/axios'
import alert from 'utils/alert'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Button, Row, Col } from 'react-bootstrap'
import { useState, useEffect } from 'react'

const PromotionSettings = () => {
  const [value, setValue] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const res = await axios.get('/admin/first-discount')
        setValue(res.data)
      } catch {
        /* empty */
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (value) {
      try {
        setError(null)
        setLoading(true)

        const res = await axios.put('/admin/first-discount', { value })
        setValue(res.data)

        alert.success()
      } catch (error) {
        setError(error)
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  const handleChange = (val) => {
    const newValue = parseInt(val) || ''
    if (!newValue || (newValue > 0 && newValue <= 100)) {
      setValue(newValue)
    }
  }

  return (
    <>
      <h1>{t('Promotions', 'menu')}</h1>

      <LoadingWrapper loading={loading}>
        <Row>
          <Col md={6}>
            <ErrorMessage error={error} />

            <form onSubmit={handleSubmit}>
              <TextControl
                name='value'
                value={value}
                label={t('DiscountValue')}
                onChange={handleChange}
                useFormik={false}
                placeholder={t('EnterDiscountValue')}
              />

              <Button type='submit'>{t('Save')}</Button>
            </form>
          </Col>
        </Row>
      </LoadingWrapper>
    </>
  )
}

export default PromotionSettings
