import axios from 'utils/axios'
import SocketService from 'utils/socket'
import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react'
import alerts from 'utils/alert'
import { t } from 'utils/localization'

const AppContext = createContext({
  storage: {},
  profile: {
    user: null,
    roles: [],
    login: () => {
      /* empty */
    },
    logout: () => {
      /* empty */
    },
    loading: true,
    notifications: [],
    readNotifications: () => {
      /* empty */
    }
  }
})

export const ProvideAppContext = ({ children }) => {
  const storage = useProvideStorage()
  const profile = useProvideProfile()
  return (
    <AppContext.Provider value={{ storage, profile }}>
      {children}
    </AppContext.Provider>
  )
}

export const useStorage = () => {
  const { storage } = useContext(AppContext)
  return storage
}

export const useProfile = () => {
  const { profile } = useContext(AppContext)
  return profile
}

const useProvideStorage = () => {
  const [roles, setRoles] = useState([])

  const loadRoles = useCallback(async () => {
    if (!roles.length) {
      try {
        const res = await axios.get('')
        setRoles(res.data)
      } catch {
        /* empty */
      }
    }
  }, [roles])

  return {
    roles,
    loadRoles
  }
}

const useProvideProfile = () => {
  const [user, setUser] = useState(null)
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(true)
  const [notifications, setNotifications] = useState([])
  const [notificationCount, setNotificationCount] = useState([])

  const addNotification = useCallback((notification) => {
    setNotifications((notifications) => [notification, ...notifications])
  }, [])

  const readNotifications = useCallback(async (ids) => {
    try {
      setNotifications((notifications) =>
        notifications.map((notification) => ({
          ...notification,
          state: ids.includes(notification.id) ? 'read' : notification.state
        }))
      )

      axios.put('/user/notifications/read', { notifications: ids })
    } catch {
      /* empty */
    }
  }, [])

  const loadAdminNotifications = useCallback(async (ids) => {
    try {
      const res = await axios.get('/admin/number-applications')
      setNotificationCount(res.data)
    } catch {
      /* empty */
    }
  }, [])

  const logout = useCallback(async () => {
    localStorage.setItem('token', '')
    localStorage.setItem('refresh_token', '')
    SocketService.close()
    setUser(null)
    setRoles([])
    setNotifications([])
  }, [])

  const login = useCallback(
    async (data) => {
      let user = {}
      let roles = []
      let notifications = []

      if (data.token) {
        localStorage.setItem('token', data.token)
        localStorage.setItem('refresh_token', data.refreshToken)

        user = data.user
        roles = data.user.roles
      } else if (data.personalInformation) {
        user = data.personalInformation
        roles = data.roles || []
        notifications = data.notifications || []
      } else {
        user = data
      }

      if (!roles.length) {
        alerts.error(t('ErrorForbidden', 'signin'))
        logout()
        return
      }

      SocketService.init(addNotification)

      setUser(user)
      setRoles(roles)
      setNotifications(notifications)
      loadAdminNotifications()
    },
    [logout, addNotification, loadAdminNotifications]
  )

  const loadUser = useCallback(async () => {
    try {
      const res = await axios.get('/user')
      login(res.data)
    } catch {
      /* empty */
    }
  }, [login])

  const load = useCallback(async () => {
    try {
      setLoading(true)

      const token = localStorage.getItem('token')
      if (token) {
        await loadUser()
      }
    } catch {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [loadUser])

  useEffect(() => {
    load()
  }, [load])

  return {
    user,
    roles,
    login,
    logout,
    loading,
    notifications,
    notificationCount,
    readNotifications,
    loadAdminNotifications
  }
}
