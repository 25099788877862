import axios from 'utils/axios'
import alert from 'utils/alert'
import TextControl from 'components/TextControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Button, Row, Col } from 'react-bootstrap'
import { useState, useEffect } from 'react'

const LandingSettings = () => {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const res = await axios.get('/public/landing')
        setValue(res.data)
      } catch {
        /* empty */
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (value && value.trim()) {
      try {
        const res = await axios.put('admin/merchant-landing', {
          link: value
        })
        setValue(res.data ? res.data.link : '')

        alert.success()
      } catch {
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <LoadingWrapper loading={loading}>
      <Row>
        <Col lg={6}>
          <form onSubmit={handleSubmit}>
            <TextControl
              name='link'
              label={t('DragTitleVideo')}
              value={value}
              onChange={setValue}
              required
              useFormik={false}
            />
            <Button type='submit'>{t('Save')}</Button>
          </form>
        </Col>
      </Row>
    </LoadingWrapper>
  )
}

export default LandingSettings
