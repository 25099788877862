import PartnersTab from './components/PartnersTab'
import GeneralInformationTab from './components/GeneralInformationTab'
import { t } from 'utils/localization'
import { Tabs, Tab } from 'react-bootstrap'

const AboutPage = () => {
  return (
    <>
      <h1>{t('PageAbout', 'menu')}</h1>

      <Tabs defaultActiveKey='info' className='mb-4'>
        <Tab eventKey='info' title={t('GeneralInformation')}>
          <GeneralInformationTab />
        </Tab>
        <Tab eventKey='partners' title={t('PartnersList')}>
          <PartnersTab />
        </Tab>
      </Tabs>
    </>
  )
}

export default AboutPage
