import axios from 'utils/axios'
import alert from 'utils/alert'
import TextControl from 'components/TextControl'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { useState, useEffect } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { deleteFromArray, objectToUrlParams } from 'utils/helpers'

const MailingEmailsPage = () => {
  const [filter, setFilter] = useState({})
  const [values, setValues] = useState({
    ageEnd: '',
    ageStart: '',
    countries: [],
    products: []
  })
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const [recipients, setRecipients] = useState([])

  useEffect(() => {
    if (values.ageEnd || values.ageStart) {
      setRecipients((recipients) =>
        recipients.includes('age') ? recipients : [...recipients, 'age']
      )
    } else {
      setRecipients((recipients) =>
        deleteFromArray(recipients, { value: 'age' })
      )
    }

    if (values.countries.length) {
      setRecipients((recipients) =>
        recipients.includes('country') ? recipients : [...recipients, 'country']
      )
    } else {
      setRecipients((recipients) =>
        deleteFromArray(recipients, { value: 'country' })
      )
    }
  }, [values.ageEnd, values.ageStart, values.countries])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await axios.get('/public/user/lk/countries')
        setCountries(res.data.map((value) => ({ label: value, value })))
      } catch {
        /* empty */
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const res = await axios.get('/admin/sending/email/recipients')

        let result = {}
        res.data.forEach((item) => (result[item.code] = item.title))
        setFilter(result)
      } catch {
        /* empty */
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await axios.post(
        `/admin/sending/email?${objectToUrlParams({
          ...values,
          recipients
        })}`
      )
      alert.success(t('SuccessMailing'))
    } catch (error) {
      alert.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeArray = (value) => (event) => {
    setRecipients(
      event.target.checked
        ? [...recipients, value]
        : deleteFromArray(recipients, { value })
    )
  }

  const handleChangeValue = (name) => (value) => {
    setValues({ ...values, [name]: value })
  }

  return (
    <LoadingWrapper loading={loading}>
      <h1>{t('MailingManagmentEmails', 'menu')}</h1>

      <section className='mb-5'>
        <h3 className='mb-3'>{t('SetFilter')}</h3>

        <div>
          <Row>
            {!!filter['allUser'] && (
              <Col md={12} className='mb-4'>
                <Form.Check
                  id='allUser'
                  label={filter['allUser']}
                  checked={recipients.includes('allUser')}
                  onChange={handleChangeArray('allUser')}
                />
              </Col>
            )}
            {!!filter['woman'] && (
              <Col md={4} className='mb-4'>
                <Form.Check
                  id='woman'
                  label={filter['woman']}
                  checked={recipients.includes('woman')}
                  onChange={handleChangeArray('woman')}
                />
              </Col>
            )}
            {!!filter['man'] && (
              <Col md={4} className='mb-4'>
                <Form.Check
                  id='man'
                  label={filter['man']}
                  checked={recipients.includes('man')}
                  onChange={handleChangeArray('man')}
                />
              </Col>
            )}
          </Row>
          <Row>
            {!!filter['userWatchingNotPay'] && (
              <Col md={4} className='mb-4'>
                <Form.Check
                  id='userWatchingNotPay'
                  label={filter['userWatchingNotPay']}
                  checked={recipients.includes('userWatchingNotPay')}
                  onChange={handleChangeArray('userWatchingNotPay')}
                />
              </Col>
            )}
            {!!filter['userNotWatchingNotPay'] && (
              <Col md={4} className='mb-4'>
                <Form.Check
                  id='userNotWatchingNotPay'
                  label={filter['userNotWatchingNotPay']}
                  checked={recipients.includes('userNotWatchingNotPay')}
                  onChange={handleChangeArray('userNotWatchingNotPay')}
                />
              </Col>
            )}
            {!!filter['userWatchingEmail'] && (
              <Col md={4} className='mb-4'>
                <Form.Check
                  id='userWatchingEmail'
                  label={filter['userWatchingEmail']}
                  checked={recipients.includes('userWatchingEmail')}
                  onChange={handleChangeArray('userWatchingEmail')}
                />
              </Col>
            )}
            {!!filter['age'] && (
              <Col md={6} className='mb-4'>
                <div className='mb-2'>{filter['age']}</div>

                <div className='d-flex align-items-center'>
                  <p className='me-2 mb-4'>{t('AgeFrom')}</p>
                  <TextControl
                    type='number'
                    value={values.ageStart}
                    onChange={handleChangeValue('ageStart')}
                    disabled={recipients.includes('allUser')}
                    useFormik={false}
                  />

                  <div className='me-3' />

                  <p className='me-2 mb-4'>{t('AgeTo')}</p>
                  <TextControl
                    type='number'
                    value={values.ageEnd}
                    onChange={handleChangeValue('ageEnd')}
                    disabled={recipients.includes('allUser')}
                    useFormik={false}
                  />
                </div>
              </Col>
            )}
            {!!filter['country'] && (
              <Col md={6} className='mb-4'>
                <SelectControl
                  name='country'
                  label={filter['country']}
                  value={values.countries}
                  options={countries}
                  multiple
                  onChange={handleChangeValue('countries')}
                  isDisabled={recipients.includes('allUser')}
                  placeholder={t('SelectCountries')}
                />
              </Col>
            )}
          </Row>
        </div>
      </section>

      <section className='mb-5'>
        <h3 className='mb-3'>{t('SetProducts')}</h3>

        <SelectControl
          url={'/admin/main/products?title='}
          name='products'
          keys={{ value: 'id', label: 'title' }}
          async
          multiple
          onChange={handleChangeValue('products')}
          placeholder={t('SearchProduct')}
        />
      </section>

      <Button
        onClick={handleSubmit}
        disabled={!recipients.length || !values.products.length}
      >
        {t('SendMessages')}
      </Button>
    </LoadingWrapper>
  )
}

export default MailingEmailsPage
