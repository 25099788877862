import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import TextControl from 'components/TextControl'
import SelectControl from 'components/SelectControl'
import { t } from 'utils/localization'
import { useState } from 'react'
import { useProfile } from 'utils/hooks/useContext'
import { Row, Col, Button } from 'react-bootstrap'

const Actions = (props) => {
  const { loadAdminNotifications } = useProfile()
  const { id, actions, getState, setError, loading, setLoading } = props

  const [action, setAction] = useState('')
  const [reason, setReason] = useState('')
  const [showReason, setShowReason] = useState(false)

  const toggleModal = (open) => () => {
    setShowReason(false)
    if (!open) {
      setReason('')
    }
  }

  const handleClick = () => {
    if (action === 'reject' || action === 'waitRework') {
      setShowReason(true)
    } else {
      handleSubmit()
    }
  }

  const handleSubmit = async () => {
    if (id && action) {
      try {
        setError(null)
        setLoading(true)

        await axios.put(`/admin/check/product/${id}?state=${action}`, {
          reason
        })
        getState()
        setAction(null)
        setShowReason(false)

        loadAdminNotifications()
        alert.success()
      } catch (error) {
        setError(error)
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <h3>{t('SubtitleActions')}</h3>

      <Row>
        <Col lg={6}>
          <SelectControl
            name='action'
            value={action}
            options={actions}
            onChange={setAction}
          />
        </Col>
        <Col lg={1}>
          <Button disabled={!action} onClick={handleClick}>
            {t('Save')}
          </Button>
        </Col>
      </Row>

      <Modal
        open={showReason}
        title={actions.find((a) => a.value === action)?.label}
        loading={loading}
        onClose={toggleModal(false)}
      >
        <div>
          <TextControl
            as='textarea'
            rows={6}
            name='reason'
            label={t('LabelReason')}
            value={reason}
            onChange={setReason}
            useFormik={false}
          />

          <div className='b-modal__footer'>
            <Button
              onClick={handleSubmit}
              disabled={!reason.trim()}
              className='ms-auto'
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Actions
