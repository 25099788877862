import { useEffect, useState } from 'react'

export const useIsMounted = () => {
  const [isMounted, setisMounted] = useState(false)
  useEffect(() => {
    setisMounted(true)
    return () => setisMounted(false)
  }, [])
  return isMounted
}

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      if (handler) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export const useDisableBodyScroll = (condition) => {
  useEffect(() => {
    document.querySelector('body').style.overflow = condition
      ? 'hidden'
      : 'visible'
  }, [condition])
}

export const useToggle = (defaultValue) => {
  const [value, setValue] = useState(defaultValue)

  const toggleValue = (value) => {
    setValue((currentValue) =>
      typeof value === 'boolean' ? value : !currentValue
    )
  }

  return [value, toggleValue]
}
