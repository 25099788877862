import clsx from 'clsx'
import axios from 'utils/axios'
import alert from 'utils/alert'
import moment from 'moment'
import Loading from 'components/Loading'
import TextControl from 'components/TextControl'
import { t } from 'utils/localization'
import { Card, Collapse, Button } from 'react-bootstrap'
import { useState, useEffect, useCallback } from 'react'

import '../styles.scss'

const ProductComments = (props) => {
  const { id } = props

  const [data, setData] = useState([])
  const [show, setShow] = useState(false)
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)

  const getComments = useCallback(async () => {
    if (id) {
      try {
        setLoading(true)
        const res = await axios.get(`/admin/check/comment/product/${id}`)
        setData(res.data)
      } catch (error) {
        setData([])
      } finally {
        setLoading(false)
      }
    }
  }, [id])

  useEffect(() => {
    getComments()
  }, [getComments])

  const toggleVisibility = () => {
    setShow(!show)
  }

  const handleSendComment = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      await axios.post(`/admin/check/comment/product/${id}`, {
        text: value
      })
      setValue('')
      getComments()
    } catch {
      setLoading(false)
      alert.error(t('SendingError'), 'validation')
    }
  }

  return (
    <>
      <div className='d-flex'>
        <h3 className='mb-0'>{t('SubtitleComments')}</h3>
        <Button variant='link' onClick={toggleVisibility}>
          {t(show ? 'Hide' : 'Show')}
        </Button>
      </div>
      <Collapse in={show}>
        <div>
          <Loading loading={loading}>
            <form onSubmit={handleSendComment} className='mt-4'>
              <Card className='chat'>
                <Card.Body style={{ maxHeight: 330, overflow: 'auto' }}>
                  {data.map((comment) => (
                    <Comment key={comment.id} {...comment} />
                  ))}
                </Card.Body>
                <Card.Footer>
                  <div className='d-flex align-items-start'>
                    <TextControl
                      as='textarea'
                      value={value}
                      onChange={setValue}
                      useFormik={false}
                      className='mb-0 me-3 flex-grow-1'
                    />
                    <Button disabled={!value.trim()} type='submit'>
                      {t('Send')}
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </form>
          </Loading>
        </div>
      </Collapse>
    </>
  )
}

const Comment = (props) => {
  const { text, sender, createdAt } = props

  const parseDateTime = (value) => {
    const dateTime = moment(value)
    return dateTime.isValid() ? dateTime.format('DD.MM.YYYY HH:mm') : ''
  }

  return (
    <div className={clsx('b-chat__inner', sender.isAdmin && 'b-chat__sender')}>
      <div>
        <small className='text-secondary'>{parseDateTime(createdAt)}</small>
      </div>
      <div className='mb-1'>
        <b>{sender.fullName}</b>
      </div>
      <div className='b-chat__text'>{text}</div>
    </div>
  )
}

export default ProductComments
