import axios from 'utils/axios'
import Table from 'components/Table'
import moment from 'moment'
import TextControl from 'components/TextControl'
import DateControl from 'components/DateControl'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { useIsMounted } from 'utils/hooks'
import { Row, Col, Button } from 'react-bootstrap'
import { objectToUrlParams } from 'utils/helpers'
import { useDebouncedCallback } from 'use-debounce'
import { useMemo, useState, useEffect } from 'react'

const CancelRequestsPage = () => {
  const isMounted = useIsMounted()

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [managers, setManagers] = useState([])
  const [statuses, setStatuses] = useState([])
  const [initiators, setInitiators] = useState([])
  const [pagination, setPagination] = useState({})
  const [filter, setFilter] = useState({
    page: 1,
    client: '',
    states: [],
    admins: [],
    merchant: '',
    initiators: [],
    rowsPerPage: 10,
    datePaymentEnd: '',
    dateDecisionEnd: '',
    datePaymentStart: '',
    dateDecisionStart: ''
  })

  const getList = useDebouncedCallback(async () => {
    try {
      isMounted && setLoading(true)
      const res = await axios.get(
        `/admin/cancel-order/requests/?${objectToUrlParams(filter)}`
      )
      if (isMounted) {
        setList(res.data)
        setPagination(res.pagination)
      }
    } catch {
      setList([])
      setPagination({})
    } finally {
      isMounted && setLoading(false)
    }
  }, 500)

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        const res = await axios.get('/admin/cancel-order/states')
        isMounted && setStatuses(res.data)
      } catch {
        /* empty */
      }
      try {
        const res = await axios.get('/admin/cancel-order/admins')
        isMounted && setManagers(res.data)
      } catch {
        /* empty */
      }
      try {
        const res = await axios.get('/admin/cancel-order/initiators')
        isMounted && setInitiators(res.data)
      } catch {
        /* empty */
      }
    }
    getData()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    getList()
  }, [filter, getList])

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        Cell: ({ value }) => (
          <Link to={`/cancel-requests/${value}`}>
            <span className='text-bold'>{`#\xa0${value}`}</span>
          </Link>
        )
      },
      {
        Header: t('LabelProduct'),
        Cell: ({
          row: {
            original: { type, title }
          }
        }) => (
          <div>
            <b>{type}:&nbsp;</b>
            <div>{title}</div>
          </div>
        )
      },
      {
        Header: t('TableTitleProductClient'),
        accessor: 'client.fullName'
      },
      {
        Header: t('Partner'),
        accessor: 'merchant.title'
      },
      {
        Header: t('LabelStatus'),
        accessor: 'state'
      },
      {
        Header: t('LabelDatePayment'),
        accessor: 'datePayment'
      },
      {
        Header: t('TableTitleDateCompletion'),
        accessor: 'dateDecision'
      },
      {
        Header: t('TableTitleInitiator'),
        accessor: 'initiator.title'
      },
      {
        Header: t('TableTitleExecutor'),
        accessor: 'admin'
      }
    ],
    []
  )

  const handleChange = (name) => (value) => {
    if (name === 'pagination') {
      setFilter({ ...filter, ...value.pagination })
    } else {
      setFilter({ ...filter, [name]: value, page: 1 })
    }
  }

  const handleDownloadXLSX = () => {
    import('xlsx').then((XLSX) => {
      const ws = XLSX.utils.table_to_sheet(
        document.getElementById('cancel-requests')
      )
      const wb = XLSX.utils.book_new()
      const wscols = [
        { wch: 10 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 25 }
      ]
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, 'Bustourma')
      XLSX.writeFile(
        wb,
        `${t('ExcelTitle')} ${moment().format('DD.MM.YYYY HH:mm')}.xlsx`
      )
    })
  }

  return (
    <>
      <h1>{t('CancelRequests', 'menu')}</h1>

      <LoadingWrapper loading={loading}>
        <Row>
          <Col lg={6}>
            <TextControl
              name='merchant'
              label={t('Partner')}
              value={filter.merchant}
              onChange={handleChange('merchant')}
              multiple
              useFormik={false}
            />
          </Col>
          <Col lg={6}>
            <TextControl
              name='client'
              label={t('TableTitleProductClient')}
              value={filter.client}
              onChange={handleChange('client')}
              multiple
              useFormik={false}
            />
          </Col>
          <Col lg={3}>
            <DateControl
              label={t('LabelDatePayment')}
              value={filter.datePaymentStart}
              onChange={handleChange('datePaymentStart')}
              placeholder={t('AgeFrom')}
            />
          </Col>
          <Col lg={3}>
            <DateControl
              label={<p className='mt-4' />}
              value={filter.datePaymentEnd}
              onChange={handleChange('datePaymentEnd')}
              className='mt-4'
              placeholder={t('AgeTo')}
            />
          </Col>
          <Col lg={3}>
            <DateControl
              label={t('TableTitleDateCompletion')}
              value={filter.dateDecisionStart}
              onChange={handleChange('dateDecisionStart')}
              placeholder={t('AgeFrom')}
            />
          </Col>
          <Col lg={3}>
            <DateControl
              label={<p className='mt-4' />}
              value={filter.dateDecisionEnd}
              onChange={handleChange('dateDecisionEnd')}
              className='mt-4'
              placeholder={t('AgeTo')}
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              name='status'
              label={t('LabelStatus')}
              value={filter.states}
              options={statuses.map((status) => ({
                label: status.title,
                value: status.code
              }))}
              onChange={handleChange('states')}
              multiple
              useFormik={false}
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              name='initiator'
              label={t('LabelInitiator')}
              value={filter.initiators}
              options={initiators.map((initiator) => ({
                label: initiator.title,
                value: initiator.code
              }))}
              onChange={handleChange('initiators')}
              multiple
              useFormik={false}
            />
          </Col>
          <Col lg={3}>
            <SelectControl
              name='admin'
              label={t('TableTitleExecutor')}
              value={filter.admins}
              options={managers.map((manager) => ({
                label: manager.fullName,
                value: manager.id
              }))}
              onChange={handleChange('admins')}
              multiple
              useFormik={false}
            />
          </Col>
        </Row>
        <div className='d-flex justify-content-end mb-2'>
          <Button onClick={handleDownloadXLSX}>{t('GetExcel')}</Button>
        </div>
        <Table
          id='cancel-requests'
          data={list}
          columns={columns}
          onChange={handleChange('pagination')}
          pagination={pagination}
          canShowAll
        />
      </LoadingWrapper>
    </>
  )
}

export default CancelRequestsPage
