import React from 'react'
import '../../styles.scss'
import ControlInputGroup from 'components/ControlInputGroup'

const PeopleRange = (props) => {
  const { prices, onChange, error, value, type, currency } = props

  if (!prices?.price) {
    return null
  }

  const priceTyepe = type === 'isIndividualWithGuide' ? 'guidePrice' : 'price'
  const currentPrices = prices.price
    .filter((item) => !!item[priceTyepe])
    .map((item) => ({
      ...item,
      price: `${item[priceTyepe][currency]} ${currency}`
    }))

  return (
    <div>
      {currentPrices.map((item, index) => (
        <div key={index} className='b-people-range'>
          <ControlInputGroup
            type='radio'
            value={value}
            options={[
              {
                id: `peopleRange${index}`,
                label: `Цена за ${item.minPeople}-${item.maxPeople} человек`,
                value: `${item.minPeople}-${item.maxPeople}`
              }
            ]}
            onChange={onChange}
            error={error}
          />
          <span className='price'>{item.price}</span>
        </div>
      ))}
    </div>
  )
}

export default PeopleRange
