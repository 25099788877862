import { t } from 'utils/localization'
import { Tabs, Tab } from 'react-bootstrap'
import { useState, useEffect, useCallback } from 'react'
import axios from 'utils/axios'
import LoadingWrapper from 'components/Loading'
import SpecialOffersTab from './components/SpecialOffersTab'
import GeneralSettingsTab from './components/GeneralSettingsTab'
import LandingSettingsTab from './components/LandingSettingsTab'
import BannersSettingsTab from './components/BannersSettingsTab'
import DisplaySettingsTab from './components/DisplaySettingsTab'
import PopularProductsTab from './components/PopularProductsTab'
import PopularDirectionsTab from './components/PopularDirectionsTab'

const HomePage = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [banners, setBanners] = useState([])
  const [settings, setSettings] = useState([])

  useEffect(() => {
    const titles = {
      video: t('DragTitleVideo'),
      recent: t('DragTitleRecent'),
      special: t('DragTitleSpecial'),
      products: t('DragTitleProducts'),
      favorites: t('DragTitleFavorites'),
      directions: t('DragTitleDestinations')
    }

    if (data) {
      const { order, show } = data
      const array = []

      order.forEach((item) => {
        let text = titles[item]

        if (item.includes('banner')) {
          const id = item.split(':')[1]
          text = `${t('LabelBanner')} № ${id}`
        }

        array.push({
          id: item,
          text: text,
          visible: show.includes(item)
        })
      })

      banners.forEach((banner) => {
        if (!order.find((item) => item === 'banner:' + banner.id)) {
          array.push({
            id: 'banner:' + banner.id,
            text: `${t('LabelBanner')} № ${banner.id}`,
            visible: show.includes('banner:' + banner.id)
          })
        }
      })

      setSettings(array)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, banners])

  const getBanners = useCallback(async () => {
    try {
      const res = await axios.get('/admin/banners')
      setBanners(res.data)
    } catch (error) {
      /* emptry */
    }
  }, [])

  const getSettings = useCallback(async () => {
    try {
      const res = await axios.get('/admin/settings/main')
      setData(res.data)
    } catch (error) {
      /* emptry */
    }
  }, [])

  const getData = useCallback(async () => {
    let isMounted = true

    isMounted && setLoading(true)
    await getBanners(false)
    await getSettings(false)
    isMounted && setLoading(false)

    return () => {
      isMounted = false
    }
  }, [getBanners, getSettings])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <LoadingWrapper loading={loading}>
      <h1>{t('PageMain', 'menu')}</h1>

      <Tabs defaultActiveKey='generalSettings' className='mb-5' mountOnEnter>
        <Tab eventKey='generalSettings' title={t('TabTitleGeneral')}>
          <GeneralSettingsTab />
        </Tab>
        <Tab eventKey='displaySettings' title={t('TabTitleDisplay')}>
          <DisplaySettingsTab items={settings} setSettings={setSettings} />
        </Tab>
        <Tab eventKey='popularDirections' title={t('DragTitleDestinations')}>
          <PopularDirectionsTab />
        </Tab>
        <Tab eventKey='popularProducts' title={t('TabTitleProducts')}>
          <PopularProductsTab />
        </Tab>
        <Tab eventKey='specialOffers' title={t('DragTitleSpecial')}>
          <SpecialOffersTab />
        </Tab>
        <Tab eventKey='bannersSettings' title={t('TabTitleBanners')}>
          <BannersSettingsTab banners={banners} getData={getData} />
        </Tab>
        <Tab eventKey='landingSettings' title={t('TabTitleLanging')}>
          <LandingSettingsTab />
        </Tab>
      </Tabs>
    </LoadingWrapper>
  )
}

export default HomePage
