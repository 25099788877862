import React, { useState, useEffect } from 'react'
import Pagination from 'react-js-pagination'

const CustomPagination = (props) => {
  const { data, range, onChange, noStyle } = props

  const [pagination, setPagination] = useState({ page: 1, perPage: 10 })

  useEffect(() => {
    if (data) {
      setPagination(data)
    }
  }, [data])

  const handleChange = (page) => {
    const data = { ...pagination, page }
    onChange ? onChange(data) : setPagination(data)
  }

  const getControlElement = (value) => {
    return noStyle ? (
      value
    ) : (
      <span className='custom-pagination-edge'>{value}</span>
    )
  }

  return pagination.pageCount > 1 ? (
    <Pagination
      onChange={handleChange}
      activePage={pagination.page}
      totalItemsCount={pagination.total}
      itemsCountPerPage={pagination.perPage}
      pageRangeDisplayed={10}
      hideFirstLastPages={pagination.pageCount < range}
      itemClass='page-item'
      linkClass='page-link'
      innerClass='pagination mb-4'
      prevPageText={getControlElement('‹')}
      nextPageText={getControlElement('›')}
      firstPageText={getControlElement('«')}
      lastPageText={getControlElement('»')}
    />
  ) : null
}

export default CustomPagination
