import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

export const LoadingSpinner = () => {
  return <div className='b-loading__icon'></div>
}

const LoadingInner = () => {
  return (
    <div className='b-loading__inner'>
      <div className='b-loading__spinner'>
        <LoadingSpinner />
      </div>
    </div>
  )
}

export const GeneralLoadingWrapper = (props) => {
  const { loading } = props

  return (
    loading && (
      <div className='b-loading__general'>
        <LoadingInner />
      </div>
    )
  )
}

const LoadingWrapper = (props) => {
  const { children, loading } = props
  return (
    <div className={loading ? 'b-loading__wrapper' : ''}>
      <div>{children}</div>
      {loading && <LoadingInner />}
    </div>
  )
}

LoadingWrapper.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.any
}

export default LoadingWrapper
