import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import Empty from 'components/Empty'
import CrudActions from 'components/CrudActions'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { FALLBACK } from 'utils/localization/config'
import { LANGUAGE } from 'utils/constants'
import { useFormik } from 'formik'
import { chunkArray } from 'utils/helpers'
import { useState, useEffect, useCallback } from 'react'
import { Row, Col, Tab, Tabs, Button, ListGroup } from 'react-bootstrap'

const CategoryList = (props) => {
  const { baseUrl, defaultCategories, withSubCategories } = props

  const [cError, setCError] = useState(null)
  const [sError, setSError] = useState(null)

  const [loading, setLoading] = useState(false)
  const [cLoading, setCLoading] = useState(false)
  const [sLoading, setSLoading] = useState(false)

  const [category, setCategory] = useState({})
  const [categories, setCategories] = useState([])

  const [categoryModal, setCategoryModal] = useState({ open: false, id: null })
  const [subCategoryModal, setSubCategoryModal] = useState({
    open: false,
    data: {}
  })

  const getCategory = async (id) => {
    try {
      setCLoading(true)
      const res = await axios.get(`/admin/product/${baseUrl}/category/${id}`)

      let data = res.data
      if (defaultCategories) {
        const temp = defaultCategories.find((item) => item.id === id)
        if (temp) {
          data = { ...data, ...temp }
        }
      }

      setCategory(data)
    } catch (error) {
      /* empty */
    } finally {
      setCLoading(false)
    }
  }

  const getCategories = useCallback(async () => {
    if (defaultCategories) {
      setCategories([defaultCategories])
    } else {
      try {
        setLoading(true)
        const res = await axios.get(`/admin/product/${baseUrl}/categories`)

        const chunkSize =
          res.data.length > 10
            ? Math.round(res.data.length / 3)
            : res.data.length

        setCategories(chunkArray(res.data, chunkSize))
      } catch (error) {
        /* empty */
      } finally {
        setLoading(false)
      }
    }
  }, [baseUrl, defaultCategories])

  useEffect(() => {
    getCategories()
  }, [getCategories])

  const getInitialCategory = () => {
    const initial = {}
    LANGUAGE.getLanguageCodes().forEach((code) => {
      initial[code] = category[code] || ''
    })
    return initial
  }

  const getShapeCategory = () => {
    const shape = {}
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] =
        code === FALLBACK
          ? Yup.string().required(t('Required', 'validation'))
          : Yup.string().notRequired()
    })
    return shape
  }

  const getInitialSubCategory = () => {
    const initial = {
      categoryId: category.id || ''
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      initial[code] = subCategoryModal.data[code] || ''
    })
    return initial
  }

  const getShapeSubCategory = () => {
    const shape = {
      categoryId: Yup.mixed().required(t('Required', 'validation'))
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] =
        code === FALLBACK
          ? Yup.string().required(t('Required', 'validation'))
          : Yup.string().notRequired()
    })
    return shape
  }

  const cFormik = useFormik({
    onSubmit: (values) => handleSubmitCategory(values),
    initialValues: getInitialCategory(),
    validationSchema: Yup.object().shape(getShapeCategory()),
    enableReinitialize: true
  })

  const sFormik = useFormik({
    onSubmit: (values) => handleSubmitSubCategory(values),
    initialValues: getInitialSubCategory(),
    validationSchema: Yup.object().shape(getShapeSubCategory()),
    enableReinitialize: true
  })

  const handleChangeCategory = (value) => {
    sFormik.setFieldValue('categoryId', value)
  }

  const handleSubmitCategory = async (data) => {
    try {
      setCError(null)
      setCLoading(true)

      const url = `/admin/product/${baseUrl}/category/${categoryModal.id || ''}`
      const method = categoryModal.id ? 'put' : 'post'

      await axios({ url, method, data })

      getCategories()
      toggleCategoryModal(false)()
      alert.success()
    } catch (error) {
      setCError(error)
      alert.error()
    } finally {
      setCLoading(false)
    }
  }

  const handleSubmitSubCategory = async (data) => {
    try {
      setSError(null)
      setSLoading(true)

      const url = `/admin/product/${baseUrl}/category/item/${
        subCategoryModal.data.id || ''
      }`
      const method = subCategoryModal.data.id ? 'put' : 'post'

      await axios({ url, method, data })

      if (categoryModal.open) {
        getCategory(categoryModal.id)
      }
      toggleSubCategoryModal(false)()
      alert.success()
    } catch (error) {
      setSError(error)
      alert.error()
    } finally {
      setSLoading(false)
    }
  }

  const toggleCategoryModal =
    (open, id = null) =>
    async () => {
      setCategoryModal({ open, id })

      if (open && id) {
        getCategory(id)
      } else {
        setCError(null)
        setCategory({})
        cFormik.resetForm()
      }
    }

  const toggleSubCategoryModal =
    (open, data = {}) =>
    () => {
      setSubCategoryModal({ open, data })
      if (!open) {
        setSError(null)
        sFormik.resetForm()
      }
    }

  const handleDeleteCategory = (id) => async () => {
    if (await confirm(t('ConfirmDeleteCategory'))) {
      try {
        await axios.delete(`/admin/product/${baseUrl}/category/${id}`)

        getCategories()
        alert.success()
      } catch (error) {
        alert.error()
      }
    }
  }

  const handleDeleteSubCategory = (id) => async () => {
    if (await confirm(t('ConfirmDelete'))) {
      try {
        await axios.delete(`/admin/product/${baseUrl}/category/item/${id}`)

        getCategory(categoryModal.id)
        alert.success()
      } catch (error) {
        alert.error()
      }
    }
  }

  const getOptions = () => {
    const options = []
    categories.forEach((chuck) => {
      chuck.forEach((item) => {
        options.push({
          label: item[FALLBACK],
          value: item.id
        })
      })
    })
    return options
  }

  return (
    <>
      <div className='mb-5'>
        {!defaultCategories && (
          <Button onClick={toggleCategoryModal(true)} className='me-2'>
            {t('AddItem')}
          </Button>
        )}

        {withSubCategories && (
          <Button onClick={toggleSubCategoryModal(true)}>
            {t('AddSubCategory')}
          </Button>
        )}
      </div>

      <LoadingWrapper loading={loading}>
        {categories.length ? (
          <ListGroup>
            <Row>
              {categories.map((chunk, index) => (
                <Col lg={4} key={index}>
                  {chunk.map((item) => (
                    <ListGroup.Item key={item.id}>
                      <div className='d-flex justify-content-between'>
                        <div>{item[FALLBACK]}</div>
                        <CrudActions
                          onOpen={
                            defaultCategories &&
                            toggleCategoryModal(true, item.id)
                          }
                          onEdit={
                            !defaultCategories &&
                            toggleCategoryModal(true, item.id)
                          }
                          onDelete={
                            !defaultCategories && handleDeleteCategory(item.id)
                          }
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </Col>
              ))}
            </Row>
          </ListGroup>
        ) : (
          <Empty />
        )}
      </LoadingWrapper>

      <Modal
        open={categoryModal.open}
        title={t(categoryModal.id ? 'EditItem' : 'AddItem')}
        loading={cLoading}
        onClose={toggleCategoryModal(false)}
      >
        <Tabs
          className='mb-3'
          defaultActiveKey={defaultCategories ? 'items' : 'data'}
        >
          <Tab title={t('TabData')} eventKey='data'>
            <form onSubmit={cFormik.handleSubmit}>
              <ErrorMessage error={cError} />

              {LANGUAGE.getLanguageCodes().map((code) => (
                <TextControl
                  key={code}
                  name={code}
                  label={LANGUAGE.getNameByCode(code)}
                  value={cFormik.values[code]}
                  error={cFormik.touched[code] && cFormik.errors[code]}
                  onChange={cFormik.handleChange}
                  required={code === FALLBACK}
                />
              ))}

              {!defaultCategories && (
                <div className='custom-modal-footer'>
                  <Button type='submit' className='ml-auto'>
                    {t('SaveItem', 'adminListsPage')}
                  </Button>
                </div>
              )}
            </form>
          </Tab>

          {withSubCategories && (
            <Tab
              title={t('TabItems')}
              eventKey='items'
              disabled={!categoryModal.id}
            >
              <Button onClick={toggleSubCategoryModal(true)} className='mb-3'>
                {t('AddSubCategory')}
              </Button>

              {category.items && !!category.items.length ? (
                <ListGroup>
                  {category.items.map((item) => (
                    <ListGroup.Item key={item.id}>
                      <div className='d-flex justify-content-between'>
                        <div>{item[FALLBACK]}</div>
                        <CrudActions
                          onEdit={toggleSubCategoryModal(true, item)}
                          onDelete={handleDeleteSubCategory(item.id)}
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <Empty />
              )}
            </Tab>
          )}
        </Tabs>
      </Modal>

      {withSubCategories && (
        <Modal
          open={subCategoryModal.open}
          title={t(subCategoryModal.data.id ? 'EditItem' : 'AddItem')}
          loading={sLoading}
          onClose={toggleSubCategoryModal(false)}
        >
          <form onSubmit={sFormik.handleSubmit}>
            <ErrorMessage error={sError} />

            <SelectControl
              name='categoryId'
              label={t('TabData')}
              value={sFormik.values.categoryId}
              error={sFormik.touched.categoryId && sFormik.errors.categoryId}
              options={getOptions()}
              onChange={handleChangeCategory}
              required
              isDisabled={!!categoryModal.id}
            />

            {LANGUAGE.getLanguageCodes().map((code) => (
              <TextControl
                key={code}
                name={code}
                label={LANGUAGE.getNameByCode(code)}
                value={sFormik.values[code]}
                error={sFormik.touched[code] && sFormik.errors[code]}
                onChange={sFormik.handleChange}
                required={code === FALLBACK}
              />
            ))}

            <div className='custom-modal-footer'>
              <Button type='submit' className='ml-auto'>
                {t('Save')}
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </>
  )
}

export default CategoryList
