import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { LANGUAGE } from 'utils/constants'
import { FALLBACK } from 'utils/localization/config'
import { useFormik } from 'formik'
import { objectToFormData } from 'utils/helpers'
import { Button, Tabs, Tab } from 'react-bootstrap'
import { useState, useEffect } from 'react'

const SpecialOfferForm = (props) => {
  const { id, afterSubmit } = props

  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [active, setActive] = useState(FALLBACK)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        let temp = {}

        for (const locale of LANGUAGE.getLanguages()) {
          const res = await axios.get(
            `/admin/main/special/${id}?locale=${locale.code}`
          )
          temp[locale.code] = res.data
        }

        isMounted && setData(temp)
      } catch (error) {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    if (id) {
      getData()
    }
    return () => {
      isMounted = false
    }
  }, [id])

  const getInitial = () => {
    const initial = {
      sort: data[FALLBACK]?.sort || '',
      productId: data[FALLBACK]?.product.id || undefined,
      commonImage: null
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      initial[code] = {
        code,
        title: data[code] ? data[code].locale.title || '' : '',
        image: null
      }
    })
    return initial
  }

  const getShape = () => {
    const shape = {
      sort: Yup.number().notRequired(),
      productId: Yup.number().required(t('Required', 'validation')),
      commonImage: data[active]?.commonImage
        ? Yup.mixed().notRequired()
        : Yup.mixed().required(t('Required', 'validation'))
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] = Yup.object().shape({
        code: Yup.string().required(t('Required', 'validation')),
        title:
          code === FALLBACK || code === active
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired(),
        image: Yup.mixed().notRequired()
      })
    })
    return shape
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: getInitial(),
    validationSchema: Yup.object().shape(getShape()),
    enableReinitialize: true
  })

  const handleSubmit = async (values) => {
    try {
      setError(null)
      setLoading(true)

      const { sort, productId, commonImage, ...rest } = values
      const { image: localeImage, ...locale } = rest[active]

      const payload = {
        id,
        sort,
        locale,
        productId,
        localeImage,
        commonImage
      }

      const res = await axios({
        url: '/admin/main/special',
        data: objectToFormData(payload),
        method: id ? 'put' : 'post'
      })

      setData({ ...data, [active]: res.data })

      afterSubmit()
      alert.success()
    } catch (error) {
      setError(error)
      alert.error()
    } finally {
      setLoading(false)
    }
  }

  const handleChangeTab = (value) => {
    setActive(value)
  }

  const handleChangeField = (name) => (value) => {
    setFieldValue(name, value)
  }

  return (
    <LoadingWrapper loading={loading}>
      <form onSubmit={onSubmit}>
        <ErrorMessage error={error} />

        <TextControl
          type='number'
          name='sort'
          label={t('LabelSort')}
          value={values.sort}
          error={touched.sort && errors.sort}
          onChange={handleChange}
        />

        <SelectControl
          url={'/admin/main/products?title='}
          name='productId'
          label={t('LabelProduct')}
          async
          keys={{ value: 'id', label: 'title' }}
          error={touched.productId && errors.productId}
          locale={active}
          onChange={handleChangeField('productId')}
          placeholder={data?.[active]?.product?.title}
        />

        <FileUpload
          size='sm'
          name='commonImage'
          label={t('LabelCommonImage')}
          value={values.commonImage}
          error={touched.commonImage && errors.commonImage}
          files={data[active] ? data[active].commonImage : null}
          onChange={handleChangeField('commonImage')}
        />

        <Tabs
          onSelect={handleChangeTab}
          activeKey={active}
          className='mt-4 mb-4'
        >
          {LANGUAGE.getLanguages().map((locale) => (
            <Tab key={locale.code} eventKey={locale.code} title={locale.name}>
              <TextControl
                name={locale.code + '.title'}
                label={t('LabelTitle')}
                value={values[locale.code].title}
                error={
                  touched[locale.code] &&
                  touched[locale.code].title &&
                  errors[locale.code] &&
                  errors[locale.code].title
                }
                onChange={handleChange}
              />
              <FileUpload
                size='sm'
                name={locale.code + '.image'}
                label={t('LabelImage')}
                value={values[locale.code].image}
                files={data[locale.code]?.locale.image || null}
                onChange={handleChangeField([locale.code] + '.image')}
              />
            </Tab>
          ))}
        </Tabs>
        <div className='b-modal__footer'>
          <Button type='submit'>{t('Save')}</Button>
        </div>
      </form>
    </LoadingWrapper>
  )
}

export default SpecialOfferForm
