import axios from 'utils/axios'
import alert from 'utils/alert'
import PreviewGroup from 'components/PreviewGroup'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { useProfile } from 'utils/hooks/useContext'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { getTravelers, parsePhone } from 'utils/helpers'
import { Button, Badge, Card, Row, Col } from 'react-bootstrap'

const ReviewsPage = () => {
  const params = useParams()
  const { loadAdminNotifications } = useProfile()

  const [action, setAction] = useState(null)
  const [review, setReview] = useState({})
  const [loading, setLoading] = useState(false)
  const [actions, setActions] = useState([])

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        const res = await axios.get(`/admin/review/${params.id}`)
        if (isMounted) {
          setReview(res.data)
          setActions(
            res.data.actions.map((action) => ({
              label: action.title,
              value: action.code
            }))
          )
        }
      } catch {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    if (params.id) {
      getData()
    }
    return () => {
      isMounted = false
    }
  }, [params.id])

  const paymentStatus =
    review.isMerchantUnhold === null
      ? t('LabelPaymentStatusPending')
      : review.isMerchantUnhold === true
      ? t('LabelPaymentStatusToMerchant')
      : review.isMerchantUnhold === false
      ? t('LabelPaymentStatusToClient')
      : '-'

  const handleSubmit = async () => {
    if (action) {
      try {
        setLoading(true)

        const res = await axios.put(
          `/admin/review/${params.id}/action?action=${action}`
        )

        setReview({ ...review, ...res.data })
        setAction(null)
        setActions(
          res.data.actions.map((action) => ({
            label: action.title,
            value: action.code
          }))
        )
        alert.success()

        loadAdminNotifications()
      } catch (error) {
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  const renderStatus = () => {
    if (review.state) {
      const color =
        review.state.code === 'new'
          ? 'secondary'
          : review.state.code === 'reject'
          ? 'danger'
          : review.state.code === 'approve'
          ? 'success'
          : 'default'
      return (
        <Badge variant={color} className='ms-3'>
          {review.state.title}
        </Badge>
      )
    }
    return null
  }

  return (
    <>
      <div className='mb-5'>
        <div className='d-flex align-items-center'>
          <h1 className='m-0'>
            {t('Review')} № {params.id}
          </h1>
          {renderStatus()}
        </div>
      </div>

      <div className='mb-5'>
        <Row>
          <Col lg={6}>
            <SelectControl
              name='action'
              value={action}
              options={actions}
              onChange={setAction}
            />
          </Col>
          <Col lg={1}>
            <Button onClick={handleSubmit} disabled={!action}>
              {t('Save')}
            </Button>
          </Col>
        </Row>
      </div>

      <LoadingWrapper loading={loading}>
        <Row>
          <Col md={6}>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('Review')}</Card.Header>
              <Card.Body>
                <PreviewGroup
                  label={t('LabelPaymentStatus')}
                  value={paymentStatus}
                />
                <PreviewGroup label={t('LabelRating')} value={review.rating} />
                <PreviewGroup
                  label={t('LabelDate')}
                  value={review.dateCreate}
                />
                <PreviewGroup label={t('TableText')} value={review.text} />
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('LabelAttachments')}</Card.Header>
              <Card.Body>
                {review?.images?.length ? (
                  <div className='b-dropzone'>
                    <div className='b-preview__container'>
                      {review.images.map((image, index) => (
                        <div className='b-preview__wrapper' key={index}>
                          <div className='b-preview' style={{ height: 200 }}>
                            <div className='b-preview__inner'>
                              <span className='b-preview__item'>
                                <a href={image} data-fancybox='preview-photos'>
                                  <img src={image} alt={image} />
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  t('NoPhoto')
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('TableUser')}</Card.Header>
              {!!review.user && (
                <Card.Body>
                  <PreviewGroup
                    label={t('LabelFirstName')}
                    value={
                      <Link to={`/users/${review.user.id}`} target='_blank'>
                        <span className='text-bold'>
                          {review.user.fullName || `#${review.user.id}`}
                        </span>
                      </Link>
                    }
                  />
                  <PreviewGroup
                    label={t('LabelEmail')}
                    value={
                      review.user.email ? (
                        <a href={`mailto:${review.user.email}`}>
                          {review.user.email}
                        </a>
                      ) : (
                        '-'
                      )
                    }
                  />
                  <PreviewGroup
                    label={t('LabelPhone')}
                    value={
                      review.user.phone ? (
                        <a href={`tel:${review.user.phone}`}>
                          {parsePhone(review.user.phone)}
                        </a>
                      ) : (
                        '-'
                      )
                    }
                  />
                </Card.Body>
              )}
            </Card>
          </Col>

          <Col md={6}>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('LabelOrder')}</Card.Header>
              {!!review.order && (
                <Card.Body>
                  <PreviewGroup
                    label={t('LabelOrder') + ' №'}
                    value={review.order.id}
                  />
                  <PreviewGroup
                    label={t('LabelTravelers')}
                    value={getTravelers(review.order)}
                  />
                  <PreviewGroup
                    label={t('LabelPrice')}
                    value={`${review.order.currency} ${review.order.currencyAmount}`}
                  />
                  <PreviewGroup
                    label={t('LabelPriceDiscount')}
                    value={`${review.order.currency} ${review.order.discountPriceCurrency}`}
                  />
                  <PreviewGroup
                    label={t('LabelPeriod')}
                    value={`${review.order.productPeriod.date} ${review.order.productPeriod.timeStart} - ${review.order.productPeriod.dateEnd} ${review.order.productPeriod.timeEnd}`}
                  />
                  <PreviewGroup
                    label={t('LabelHotel')}
                    value={review.order.hotel?.title || '-'}
                  />
                  {review.order.discount && (
                    <PreviewGroup
                      label={t('LabelDiscount')}
                      value={
                        <div>
                          {review.order.discount.title}
                          <br />
                          {review.order.discount.value} %
                        </div>
                      }
                    />
                  )}
                </Card.Body>
              )}
            </Card>
          </Col>

          <Col md={6}>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('LabelProduct')}</Card.Header>
              {!!review.product && (
                <Card.Body>
                  <PreviewGroup
                    label={t('LabelName')}
                    value={
                      <Link
                        href={`/admin/products/${review.product.id}`}
                        newTab
                      >
                        <span className='text-bold'>
                          {review.product.title}
                        </span>
                      </Link>
                    }
                  />
                  <PreviewGroup
                    label={t('LabelProductType')}
                    value={review.product.productType?.title || '-'}
                  />
                </Card.Body>
              )}
            </Card>
          </Col>

          <Col md={6}>
            <Card className='mt-4'>
              <Card.Header as='h4'>{t('Partner')}</Card.Header>
              {!!review.merchant && (
                <Card.Body>
                  <PreviewGroup
                    label={t('LabelName')}
                    value={
                      <Link to={`/merchants/${review.merchant.id}`} newTab>
                        <span className='text-bold'>
                          {review.merchant.directorFirstName +
                            ' ' +
                            review.merchant.directorLastName}
                          <br />
                          {review.merchant.companyTitle}
                        </span>
                      </Link>
                    }
                  />
                  <PreviewGroup
                    label={t('LabelEmail')}
                    value={
                      review.merchant.email ? (
                        <a href={`mailto:${review.merchant.email}`}>
                          {review.merchant.email}
                        </a>
                      ) : (
                        '-'
                      )
                    }
                  />
                  <PreviewGroup
                    label={t('LabelPhone')}
                    value={
                      review.merchant.phone ? (
                        <a href={`tel:${review.merchant.phone}`}>
                          {parsePhone(review.merchant.phone)}
                        </a>
                      ) : (
                        '-'
                      )
                    }
                  />
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </LoadingWrapper>
    </>
  )
}

export default ReviewsPage
