import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'

import './styles/index.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-notifications-component/dist/theme.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
