import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import Empty from 'components/Empty'
import LoadingWrapper from 'components/Loading'
import SpecialOfferForm from './SpecialOffersForm'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { FALLBACK } from 'utils/localization/config'
import { Button, Row, Col, Card } from 'react-bootstrap'
import { useState, useEffect, useCallback } from 'react'

const SpecialOffers = () => {
  const [data, setData] = useState([])
  const [modal, setModal] = useState({ id: null, open: false })
  const [loading, setLoading] = useState(false)

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/admin/main/special?locale=${FALLBACK}`)
      setData(res.data)
    } catch (error) {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const toggleModal =
    (open, id = null) =>
    () =>
      setModal({ id, open })

  const handleDelete = (id) => async () => {
    if (await confirm(t('ConfirmSpecialOfferDelete'))) {
      try {
        setLoading(true)

        await axios.delete(`/admin/main/special/${id}`)

        getData()
        alert.success()
      } catch (error) {
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <LoadingWrapper loading={loading}>
      <Button className='mb-4' onClick={toggleModal(true)}>
        {t('Add')}
      </Button>
      <Row>
        {data.map((item) => (
          <Col lg={4} key={item.id}>
            <Card className='mb-2'>
              <Card.Img
                variant='top'
                src={item.locale.image || item.commonImage}
                alt={item.locale.title}
              />
              <Card.Body>
                <Card.Title>{item.locale.title}</Card.Title>
                <Card.Text>{`${t('LabelProduct')} №${item.product.id}: ${
                  item.product.title
                }`}</Card.Text>
                <Button onClick={toggleModal(true, item.id)} className='me-2'>
                  {t('Edit')}
                </Button>
                <Button onClick={handleDelete(item.id)} variant='danger'>
                  {t('Delete')}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {!data.length && (
          <Col lg={12}>
            <Empty />
          </Col>
        )}
      </Row>

      <Modal
        open={modal.open}
        title={t(modal.id ? 'Edit' : 'Add')}
        onClose={toggleModal(false)}
      >
        <SpecialOfferForm id={modal.id} afterSubmit={getData} />
      </Modal>
    </LoadingWrapper>
  )
}

export default SpecialOffers
