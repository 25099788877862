import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import Empty from 'components/Empty'
import LoadingWrapper from 'components/Loading'
import PopularDirectionForm from './PopularDirectionForm'
import { t } from 'utils/localization'
import { confirm } from 'components/Dialog'
import { FALLBACK } from 'utils/localization/config'
import { Button, Row, Col, Card } from 'react-bootstrap'
import { useState, useEffect, useCallback } from 'react'

const PopularDirections = () => {
  const [data, setData] = useState([])
  const [modal, setModal] = useState({ id: null, open: false })
  const [loading, setLoading] = useState(false)

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/admin/directions?locale=${FALLBACK}`)
      setData(res.data)
    } catch (error) {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const toggleModal =
    (open, id = null) =>
    () =>
      setModal({ id, open })

  const handleDelete = (id) => async () => {
    if (await confirm(t('ConfirmDirectionDelete'))) {
      try {
        setLoading(true)

        await axios.delete(`/admin/directions/${id}`)

        getData()
        alert.success()
      } catch (error) {
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <LoadingWrapper loading={loading}>
      <Button onClick={toggleModal(true)} className='mb-4'>
        {t('Add')}
      </Button>
      <Row>
        {data.map((item) => (
          <Col lg={4} key={item.id}>
            <Card className='mb-2'>
              <Card.Img
                alt={item.locale.name}
                src={item.locale.images?.[0] || item.commonImages?.[0]}
                style={{ height: 200, objectFit: 'cover' }}
                variant='top'
              />
              <Card.Body>
                <Card.Title>{item.locale.name}</Card.Title>
                <Button onClick={toggleModal(true, item.id)} className='me-2'>
                  {t('Edit')}
                </Button>
                <Button variant='danger' onClick={handleDelete(item.id)}>
                  {t('Delete')}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {!data.length && (
          <Col lg={12}>
            <Empty />
          </Col>
        )}
      </Row>

      <Modal
        size='xl'
        open={modal.open}
        title={t(modal.id ? 'Edit' : 'Create')}
        onClose={toggleModal(false)}
      >
        <PopularDirectionForm id={modal.id} afterSubmit={getData} />
      </Modal>
    </LoadingWrapper>
  )
}

export default PopularDirections
