import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import TextControl from 'components/TextControl'
import PreviewGroup from 'components/PreviewGroup'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { useProfile } from 'utils/hooks/useContext'
import { STATUS_COLORS } from 'utils/constants'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { Collapse, Button, Badge, Tabs, Tab, Row, Col } from 'react-bootstrap'

const CancelRequestPage = () => {
  const params = useParams()
  const { loadAdminNotifications } = useProfile()

  const [data, setData] = useState({})
  const [show, setShow] = useState(false)
  const [action, setAction] = useState(null)
  const [loading, setLoading] = useState(false)
  const [refund, setRefund] = useState({
    show: false,
    returnSum: 0,
    returnPercent: 100
  })

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/admin/cancel-order/request/${params.id}`)
      setData(res.data)
    } catch {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [params.id])

  useEffect(() => {
    getData()
  }, [getData])

  const updateRequest = async (payload) => {
    try {
      setLoading(true)

      await axios.put(`/admin/cancel-order/${params.id}`, payload)

      loadAdminNotifications()

      if (action === 'done') {
        setRefund({
          show: false,
          returnSum: 0,
          returnPercent: 100
        })
      }

      getData()
      setAction(null)
      alert.success('')
    } catch (error) {
      alert.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = () => {
    if (action === 'done') {
      setRefund({
        show: true,
        returnSum: data.order.amountAMD,
        returnPercent: 100
      })
    } else if (action) {
      updateRequest({ action })
    }
  }

  const handleMakeRefund = () => {
    updateRequest({
      action: action,
      returnSum: refund.returnSum
    })
  }

  const handleCancelRefund = () => {
    setRefund({
      show: false,
      returnSum: 0,
      returnPercent: 100
    })
  }

  const handleChangeRefund = (name) => (value) => {
    if (name === 'returnSum') {
      setRefund({
        ...refund,
        [name]: value
      })
    } else if (name === 'returnPercent' && value <= 100) {
      setRefund({
        ...refund,
        returnSum: (data.order.amountAMD * value) / 100,
        returnPercent: value
      })
    }
  }

  const toggleVisibility = () => {
    setShow(!show)
  }

  const getActions = () => {
    let result = []
    if (data.actions?.length) {
      result = data.actions.map((action) => ({
        label: action.title,
        value: action.code
      }))
    }
    return result
  }

  return (
    params.id && (
      <LoadingWrapper loading={loading}>
        <div className='mb-5'>
          <h1 className='m-0'>{`${t('Request')} №${params.id}`}</h1>
          <Badge variant={STATUS_COLORS[data.state?.code]}>
            {data.state?.title}
          </Badge>
        </div>

        <Row>
          <Col lg={6} className='d-flex align-items-end'>
            <SelectControl
              name='action'
              label={t('LabelAction')}
              value={action}
              options={getActions()}
              onChange={setAction}
              useFormik={false}
              className='me-3 w-100'
            />
            <Button onClick={handleSubmit} disabled={!action} className='mb-4'>
              {t('Save')}
            </Button>
          </Col>

          <Col lg={6}>
            <PreviewGroup
              label={t('Return')}
              value={
                data.moneyReturnByCondition
                  ? t('ReturnOption1')
                  : data.moneyReturnRejectReason === 'less24'
                  ? t('ReturnOption2')
                  : data.moneyReturnRejectReason === 'cancelPolicyFinal'
                  ? t('ReturnOption3')
                  : ''
              }
            />
          </Col>
        </Row>

        <Tabs defaultActiveKey='request' className='mb-3'>
          <Tab eventKey='request' title={t('TabRequest')}>
            <Row>
              <Col lg={6}>
                <PreviewGroup
                  label={t('LabelDateCreation')}
                  value={data.requestInfo?.createdAt}
                />
                <PreviewGroup
                  label={t('LabelInitiator')}
                  value={data.requestInfo?.initiator?.title}
                />
                <PreviewGroup
                  label={t('LabelReason')}
                  value={data.requestInfo?.reason}
                />
                {data.requestInfo?.cancellationPolicy && (
                  <PreviewGroup value={data.requestInfo.cancellationPolicy} />
                )}
              </Col>
            </Row>
          </Tab>

          <Tab eventKey='event' title={t('TabEvent')}>
            <Row>
              <Col lg={6}>
                {data.product?.client?.id && (
                  <PreviewGroup
                    label={t('LabelClientName')}
                    value={
                      <Link
                        to={`/users/${data.product.client.id}`}
                        target='_blank'
                      >
                        {data.product.client.fullName}
                      </Link>
                    }
                  />
                )}
                {data.product?.client?.email && (
                  <PreviewGroup
                    label={t('LabelClientEmail')}
                    value={
                      <a href={`mailto:${data.product.client.email}`}>
                        {data.product.client.email}
                      </a>
                    }
                  />
                )}
                {data.product?.client?.phone && (
                  <PreviewGroup
                    label={t('LabelClientPhone')}
                    value={
                      <a href={`tel:${data.product.client.phone}`}>
                        {data.product.client.phone}
                      </a>
                    }
                  />
                )}
                <PreviewGroup
                  label={t('LabelEvent')}
                  value={data.product?.title}
                />
                <PreviewGroup
                  label={t('LabelEventDates')}
                  value={`${data.product?.dateStart} ${data.product?.timeStart} - ${data.product?.dateEnd} ${data.product?.timeEnd}`}
                />
              </Col>
              <Col lg={6}>
                {data.product?.merchant?.id && (
                  <PreviewGroup
                    label={t('LabelMerchantName')}
                    value={
                      <Link
                        to={`/merchants/${data.product.merchant.id}`}
                        target='_blank'
                      >
                        {data.product.merchant.companyTitle}
                      </Link>
                    }
                  />
                )}
                {data.product?.merchant?.email && (
                  <PreviewGroup
                    label={t('LabelMerchantEmail')}
                    value={
                      <a href={`mailto:${data.product.merchant.email}`}>
                        {data.product.merchant.email}
                      </a>
                    }
                  />
                )}
                {data.product?.merchant?.phone && (
                  <PreviewGroup
                    label={t('LabelMerchantPhone')}
                    value={
                      <a href={`tel:${data.product.merchant.phone}`}>
                        {data.product.merchant.phone}
                      </a>
                    }
                  />
                )}
              </Col>
            </Row>
          </Tab>

          <Tab eventKey='order' title={t('TabOrder')}>
            <Row>
              <Col lg={6}>
                <PreviewGroup
                  label={t('LabelInidivdual')}
                  value={data.order?.individual}
                />
                <PreviewGroup
                  label={t('LabelAdults')}
                  value={data.order?.adultCount}
                />
                <PreviewGroup
                  label={t('LabelChildren')}
                  value={data.order?.childCount}
                />
                <PreviewGroup
                  label={t('LabelPaymentMethod')}
                  value={t(`PaymentMethod-${data.order?.paymentType}`)}
                />

                {data.order?.datePayment && (
                  <Button
                    variant='outline-primary'
                    onClick={toggleVisibility}
                    className='mb-2'
                  >
                    {t('LabelPaymentRequisites')}
                  </Button>
                )}

                <Collapse in={show}>
                  <div>
                    {data.order?.paymentType === 'telcell' && (
                      <>
                        <PreviewGroup
                          label={t('LabelTelcellId')}
                          value={data.order?.invoice}
                        />
                        <PreviewGroup
                          label={t('LabelTelcellIdTransaction')}
                          value={data.order?.paymentId}
                        />
                      </>
                    )}
                    {data.order?.paymentType === 'card' && (
                      <PreviewGroup
                        label={t('LabelAmericanCatdId')}
                        value={data.order?.orderIdAC}
                      />
                    )}
                  </div>
                </Collapse>
              </Col>
              <Col lg={6}>
                <PreviewGroup
                  label={t('LabelDatePayment')}
                  value={data.order?.datePayment}
                />
                <PreviewGroup
                  label={t('LabelSumDram')}
                  value={data.order?.amountAMD}
                />
                <PreviewGroup
                  label={t('LabelSumCurreny')}
                  value={data.order?.currencyAmount}
                />
                <PreviewGroup
                  label={t('LabelCurreny')}
                  value={data.order?.currency}
                />
                <PreviewGroup
                  label={t('LabelRate')}
                  value={data.order?.exchangeRate}
                />
              </Col>
            </Row>
          </Tab>

          {!!data.refundInfo && (
            <Tab eventKey='refund' title={t('TabRefund')}>
              <Row>
                <Col lg={6}>
                  <PreviewGroup
                    label={t('ReturnSum')}
                    value={data.refundInfo.sum}
                  />
                </Col>
              </Row>
            </Tab>
          )}
        </Tabs>

        <Modal
          open={refund.show}
          title={t('Refund')}
          onClose={handleCancelRefund}
          loading={loading}
        >
          <Row>
            <Col md={6}>
              <TextControl
                label={t('ReturnPercent')}
                value={refund.returnPercent}
                onChange={handleChangeRefund('returnPercent')}
                useFormik={false}
              />
            </Col>
            <Col md={6}>
              <TextControl
                label={t('ReturnSum')}
                value={refund.returnSum}
                onChange={handleChangeRefund('returnSum')}
                useFormik={false}
              />
            </Col>
          </Row>

          <div className='b-modal__footer'>
            <Button onClick={handleMakeRefund}>{t('Save')}</Button>
          </div>
        </Modal>
      </LoadingWrapper>
    )
  )
}

export default CancelRequestPage
