import React from 'react'
import { Form } from 'react-bootstrap'

const FormGroup = (props) => {
  const { label, children } = props

  return (
    <Form.Group className='mb-4'>
      {label && <Form.Label>{label}</Form.Label>}
      <div>{children}</div>
    </Form.Group>
  )
}

export default FormGroup
