import * as Yup from 'yup'
import axios from 'utils/axios'
import Table from 'components/Table'
import Modal from 'components/Modal'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { LANGUAGE } from 'utils/constants'
import { useFormik } from 'formik'
import { useIsMounted } from 'utils/hooks'
import { objectToUrlParams } from 'utils/helpers'
import { useMemo, useState, useEffect, useCallback } from 'react'

const PushMessagesPage = () => {
  const isMounted = useIsMounted()

  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = useState({ page: 1, rowsPerPage: 10 })
  const [loading, setLoading] = useState(false)
  const [audience, setAudience] = useState([])
  const [pagination, setPagination] = useState({})

  const getData = useCallback(async () => {
    try {
      isMounted && setLoading(true)
      const res = await axios.get(
        `/admin/sending/push?${objectToUrlParams(filter)}`
      )
      if (isMounted) {
        setData(res.data)
        setPagination(res.pagination)
      }
    } catch {
      if (isMounted) {
        setData([])
        setPagination({})
      }
    } finally {
      isMounted && setLoading(false)
    }
  }, [filter, isMounted])

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get('/admin/sending/push/recipients')
        setAudience(
          res.data.map((item) => ({
            value: item.code,
            label: item.title
          }))
        )
      } catch (error) {
        /* empty */
      }
    }
    getData()
  }, [])

  const toggleModal = () => {
    setOpen(!open)
  }

  const handleChangePagination = ({ pagination }) => {
    setFilter(pagination)
  }

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id'
      },
      {
        Header: t('DateCreate'),
        accessor: 'createdAt'
      },
      {
        Header: t('Recipient'),
        accessor: 'recipients.title'
      },
      {
        Header: t('Sender'),
        accessor: 'author',
        Cell: ({ value }) => (
          <div>
            <Link to={`/users/${value.id}`} target='_blank'>
              #{value.id}&nbsp;{value.fullName}
            </Link>
          </div>
        )
      },
      {
        Header: t('Message'),
        accessor: 'messages',
        Cell: ({ value }) => (
          <div>
            {value.map((item) =>
              !item.text ? null : (
                <div key={item.locale} className='mb-2'>
                  <div>
                    <i>{t(item.locale)}</i>
                  </div>
                  <div>
                    <b>{item.title}</b>
                  </div>
                  <div>{item.text}</div>
                </div>
              )
            )}
          </div>
        )
      }
    ],
    []
  )

  return (
    <>
      <h1>{t('MailingManagmentPUSH', 'menu')}</h1>

      <Button onClick={toggleModal} className='mb-5'>
        {t('Create')}
      </Button>

      <LoadingWrapper loading={loading}>
        <Table
          data={data}
          columns={columns}
          onChange={handleChangePagination}
          pagination={pagination}
        />
      </LoadingWrapper>

      <PushModal
        open={open}
        onClose={toggleModal}
        audience={audience}
        afterSubmit={getData}
      />
    </>
  )
}

export default PushMessagesPage

const PushModal = (props) => {
  const { open, onClose, audience, afterSubmit } = props

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSave = async (values) => {
    try {
      setLoading(true)

      const { recipients, ...locales } = values

      const payload = {
        messages: [],
        recipients
      }

      Object.keys(locales).forEach((locale) => {
        if (locales[locale].text) {
          payload.messages.push({
            locale,
            text: locales[locale].text,
            title: locales[locale].title
          })
        }
      })

      await axios.post('/admin/sending/push', payload)
      afterSubmit()
      handleClose()
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    onClose()
    setError()
    resetForm()
  }

  const handleChangeRecipients = (value) => {
    setFieldValue('recipients', value)
  }

  const getInitial = () => {
    const initial = {
      recipients: ''
    }
    LANGUAGE.getLanguageCodes().forEach((code) => {
      initial[code] = {
        text: '',
        title: ''
      }
    })
    return initial
  }

  const getShape = () => {
    const shape = {
      recipients: Yup.string().required(t('Required', 'validation'))
    }

    LANGUAGE.getLanguageCodes().forEach((code) => {
      shape[code] = Yup.object().shape({
        text: Yup.string().notRequired(),
        title: Yup.string().notRequired()
      })
    })

    return shape
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    resetForm,
    handleSubmit
  } = useFormik({
    onSubmit: (values) => handleSave(values),
    initialValues: getInitial(),
    validationSchema: Yup.object().shape(getShape()),
    enableReinitialize: true
  })

  return (
    <Modal
      open={open}
      title={t('Create')}
      loading={loading}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ErrorMessage error={error} />

        <SelectControl
          name='recipients'
          label={t('SelectRecipient')}
          value={values.recipients}
          error={touched.recipients && errors.recipients}
          options={audience}
          onChange={handleChangeRecipients}
          required
        />

        {LANGUAGE.getLanguageCodes().map((code) => (
          <div key={code}>
            <div className='mb-2'>
              <b>{LANGUAGE.getNameByCode(code)}</b>
            </div>

            <TextControl
              name={`${code}.title`}
              label={t('LabelTitle')}
              value={values[code].title}
              onChange={handleChange}
              maxLength={255}
            />

            <TextControl
              name={`${code}.text`}
              label={t('Message')}
              value={values[code].text}
              onChange={handleChange}
              maxLength={255}
            />
          </div>
        ))}

        <div className='b-modal__footer'>
          <Button type='submit' className='ms-auto'>
            {t('SendMessages')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
