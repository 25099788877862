import React from 'react'
import LoadingWrapper from 'components/Loading'
import { Modal as BModal } from 'react-bootstrap'

const Modal = (props) => {
  const {
    open,
    size = 'lg',
    title,
    footer,
    onClose,
    loading,
    children,
    className = ''
  } = props

  return (
    <BModal
      show={open}
      size={size}
      onHide={onClose}
      centered
      className={className}
    >
      <LoadingWrapper loading={loading}>
        {title && (
          <BModal.Header closeButton>
            <BModal.Title>{title}</BModal.Title>
          </BModal.Header>
        )}

        <BModal.Body>{children}</BModal.Body>

        {footer && <BModal.Footer>{footer}</BModal.Footer>}
      </LoadingWrapper>
    </BModal>
  )
}

export default Modal
