import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import Table from 'components/Table'
import TextControl from 'components/TextControl'
import PreviewGroup from 'components/PreviewGroup'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { useProfile } from 'utils/hooks/useContext'
import { useIsMounted } from 'utils/hooks'
import { Row, Col, Button } from 'react-bootstrap'
import { useDebouncedCallback } from 'use-debounce'
import { useMemo, useState, useEffect } from 'react'
import { getTravelers, objectToUrlParams } from 'utils/helpers'

const ManyDayTourRequestsPage = () => {
  const isMounted = useIsMounted()
  const { loadAdminNotifications } = useProfile()

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [selected, setSelected] = useState({
    open: false,
    data: null,
    action: null,
    loading: false
  })
  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    rowsPerPage: 10
  })

  const getList = useDebouncedCallback(async () => {
    try {
      isMounted && setLoading(true)
      const res = await axios.get(
        `/admin/tour-requests/?${objectToUrlParams(filter)}`
      )
      if (isMounted) {
        setList(res.data)
        setPagination(res.pagination)
      }
    } catch {
      setList([])
      setPagination({})
    } finally {
      isMounted && setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getList()
  }, [filter, getList])

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        Cell: ({ value }) => `# ${value}`
      },
      {
        Header: t('LabelStatus'),
        accessor: 'state.title'
      },
      {
        Header: t('TableTitleContacts'),
        Cell: ({
          row: {
            original: { name, email, phone }
          }
        }) => (
          <div>
            <div>
              <b>{name}</b>
            </div>
            {email && (
              <div>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            )}
            {phone && (
              <div>
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
            )}
          </div>
        )
      },
      {
        Header: t('LabelDescription'),
        accessor: 'description'
      },
      {
        id: 'details',
        Header: '',
        Cell: ({ row }) => (
          <Button onClick={toggleSelected(true, row.original)}>
            {t('TableTitleDetails')}
          </Button>
        )
      }
    ],
    []
  )

  const toggleSelected = (open, data) => () => {
    setSelected({
      open: open,
      data: data || null,
      action: null,
      loading: false
    })
  }

  const handleChange = (name) => (value) => {
    if (name === 'pagination') {
      setFilter({ ...filter, ...value.pagination })
    } else {
      setFilter({ ...filter, [name]: value, page: 1 })
    }
  }

  const handleSubmit = async () => {
    try {
      setSelected({
        ...selected,
        loading: true
      })

      const res = await axios.put(`/admin/tour-request/${selected.data?.id}`, {
        state: selected.action
      })

      const index = list.findIndex((item) => item.id === res.data.id)
      if (index > -1) {
        const newList = [...list]
        newList[index] = res.data
        setList(newList)
        setSelected({
          ...selected,
          data: res.data,
          action: null,
          loading: false
        })
      }

      loadAdminNotifications()
    } catch (error) {
      setSelected({
        ...selected,
        loading: false
      })
      alert.error(error.messae)
    }
  }

  const handleSelectAction = (value) => {
    setSelected({
      ...selected,
      action: value
    })
  }

  return (
    <>
      <h1>{t('ManyDayTourRequests', 'menu')}</h1>

      <LoadingWrapper loading={loading}>
        <TextControl
          name='search'
          label={t('LabelSearch')}
          value={filter.search}
          onChange={handleChange('search')}
          multiple
          useFormik={false}
          className='mb-4'
        />

        <Table
          id='cancel-requests'
          data={list}
          columns={columns}
          onChange={handleChange('pagination')}
          pagination={pagination}
        />
      </LoadingWrapper>

      <Modal
        open={selected.open}
        title={t('ManyDayRequest')}
        loading={selected.loading}
        onClose={toggleSelected(false)}
      >
        <Row>
          <Col md={9}>
            <SelectControl
              value={selected.action}
              onChange={handleSelectAction}
              options={selected.data?.actions?.map((action) => ({
                value: action.code,
                label: action.title
              }))}
            />
          </Col>
          <Col md={3}>
            <Button
              className='w-100'
              onClick={handleSubmit}
              disabled={!selected.action}
            >
              {t('Save')}
            </Button>
          </Col>
        </Row>

        <PreviewGroup
          label={t('LabelStatus')}
          value={selected.data?.state?.title}
        />
        <PreviewGroup label={t('LabelFirstName')} value={selected.data?.name} />
        <PreviewGroup label={t('LabelEmail')} value={selected.data?.email} />
        <PreviewGroup
          label={t('LabelPhone')}
          value={selected.data?.phone || '-'}
        />
        <PreviewGroup
          label={t('LabelPeriod')}
          value={`${selected.data?.dateStart} - ${selected.data?.dateEnd}`}
        />
        <PreviewGroup
          label={t('LabelTravelers')}
          value={getTravelers(selected.data)}
        />
        <PreviewGroup
          label={t('LabelTags')}
          value={selected.data?.tags?.join(', ') || '-'}
        />
        <PreviewGroup
          label={t('LabelDescription')}
          value={selected.data?.description}
        />
      </Modal>
    </>
  )
}

export default ManyDayTourRequestsPage
