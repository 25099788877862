import React from 'react'
import Login from 'pages/Login'
import Header from 'components/Header'
import Routes from 'pages/Routes'
import SideMenu from 'components/SideMenu'
import Breadcrumbs from 'components/Breadcrumbs'
import { useProfile } from 'utils/hooks/useContext'
import { GeneralLoadingWrapper } from 'components/Loading'

import './styles.scss'

export const Layout = () => {
  const { user, loading } = useProfile()

  return (
    <section className='b-layout'>
      <GeneralLoadingWrapper loading={loading} />

      {!loading && <Header />}

      <main>
        {user ? (
          <>
            <SideMenu />
            <section className='b-layout__content'>
              <Breadcrumbs />

              <Routes />
            </section>
          </>
        ) : (
          <Login showForm={!loading} />
        )}
      </main>
    </section>
  )
}

export default Layout
