import { store } from 'react-notifications-component'
import { t } from './localization'

const Alerts = {
  info(message) {
    fireNotification('info', { message })
  },
  warning(message) {
    fireNotification('warning', { message })
  },
  success(message) {
    fireNotification('success', prepareParams(message, 'success'))
  },
  error(message) {
    fireNotification('danger', prepareParams(message, 'error'))
  },
  default(message) {
    fireNotification('default', { message })
  }
}

const prepareParams = (params, type) => {
  if (!params && type) {
    if (type === 'success') {
      return { message: t('SaveSuccessMessage', 'validation') }
    }
    if (type === 'error') {
      return { message: t('SaveErrorMessage', 'validation') }
    }
  }
  if (typeof params === 'string') {
    return { message: params }
  }
  return params
}

const fireNotification = (type, params) => {
  const { message, duration, ...rest } = params

  if (message) {
    store.addNotification({
      type: type,
      insert: 'top',
      message: message,
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: duration || 5000,
        showIcon: true,
        pauseOnHover: true
      },
      isMobile: false,
      className: 'b-toast',
      ...rest
    })
  }
}

export default Alerts
