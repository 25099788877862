import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import Table from 'components/Table'
import FormGroup from 'components/FormGroup'
import CrudActions from 'components/CrudActions'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import SelectControl from 'components/SelectControl'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { confirm } from 'components/Dialog'
import { useFormik } from 'formik'
import { Button, Row, Col } from 'react-bootstrap'
import { objectToUrlParams } from 'utils/helpers'
import { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

const PaymentComissionPage = () => {
  const [data, setData] = useState({})
  const [modal, setModal] = useState({ open: false, data: {} })

  const [error, setError] = useState(null)
  const [mError, setMError] = useState(null)

  const [loading, setLoading] = useState(false)
  const [mLoading, setMLoading] = useState(false)

  const [filter, setFilter] = useState({ page: 1, search: '', rowsPerPage: 10 })
  const [merchants, setMerchants] = useState([])
  const [pagination, setPagination] = useState({})

  const formik = useFormik({
    onSubmit: (values) => handleSubmitModal(values),
    initialValues: {
      tour: modal.data.paymentCommissionTour || '',
      event: modal.data.paymentCommissionEvent || '',
      transfer: modal.data.paymentCommissionTransfer || '',
      merchant: modal.data.id || undefined
    },
    validationSchema: Yup.object().shape({
      tour: Yup.string().notRequired(),
      event: Yup.string().notRequired(),
      transfer: Yup.string().notRequired(),
      merchant: Yup.number().required(t('Required', 'validation'))
    }),
    enableReinitialize: true
  })

  const {
    dirty,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: {
      tour: data.tour || '',
      event: data.event || '',
      transfer: data.transfer || ''
    },
    validationSchema: Yup.object().shape({
      tour: Yup.string().required(t('Required', 'validation')),
      event: Yup.string().required(t('Required', 'validation')),
      transfer: Yup.string().required(t('Required', 'validation'))
    }),
    enableReinitialize: true
  })

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        const res = await axios.get('/admin/payment-commission')
        if (res.data && isMounted) {
          setData(res.data)
        }
      } catch {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    getData()
    return () => {
      isMounted = false
    }
  }, [])

  const getMerchants = useDebouncedCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(
        `/admin/payment-commission/merchants?${objectToUrlParams(filter)}`
      )
      setMerchants(res.data)
      setPagination(res.pagination)
    } catch {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getMerchants()
  }, [filter, getMerchants])

  const handleSubmit = async (values) => {
    if (dirty) {
      try {
        setError(null)
        setLoading(true)

        const res = await axios.put('/admin/payment-commission', values)
        setData(res.data)

        alert.success()
      } catch (error) {
        setError(error)
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  const handleSubmitModal = async (values) => {
    if (formik.dirty) {
      try {
        setMError(null)
        setMLoading(true)

        const { merchant, ...rest } = values
        await axios.put(`/admin/payment-commission/merchant/${merchant}`, rest)

        getMerchants()
        toggleModal(false)()
        alert.success()
      } catch (error) {
        setMError(error)
        alert.error()
      } finally {
        setMLoading(false)
      }
    }
  }

  const handleReset = async () => {
    if (await confirm(t('ResetConfirm'))) {
      try {
        setMError(null)
        setMLoading(true)

        await axios.put(`/admin/payment-commission/merchant/${modal.data.id}`, {
          tour: null,
          event: null,
          transfer: null
        })

        getMerchants()
        toggleModal(false)()
        alert.success()
      } catch (error) {
        setMError(error)
        alert.error()
      } finally {
        setMLoading(false)
      }
    }
  }

  const handleChangeSearch = (value) => {
    setFilter({ ...filter, search: value })
  }

  const handleChangeMerchant = (value) => {
    formik.setFieldValue('merchant', value.value)
  }

  const toggleModal =
    (open, data = {}) =>
    () => {
      setModal({ open, data })
      if (!open) {
        formik.resetForm()
      }
    }

  const handleChangeTable = ({ pagination }) =>
    setFilter({ ...filter, ...pagination })

  const columns = [
    {
      Header: '№',
      accessor: 'id',
      Cell: ({ value }) => (
        <Link to={`/merchants/${value}`} target='_blank'>
          <span className='text-bold'>{`# ${value}`}</span>
        </Link>
      )
    },
    {
      Header: t('LabelFirstName'),
      id: 'info',
      Cell: ({
        row: {
          original: { id, companyTitle, directorFirstName, directorLastName }
        }
      }) => (
        <div>
          {directorFirstName && (
            <div>
              <Link to={`/merchants/${id}`}>
                <span className='text-bold'>
                  {directorFirstName + ' ' + directorLastName}
                </span>
              </Link>
            </div>
          )}
          {companyTitle && <div className='text-secondary'>{companyTitle}</div>}
        </div>
      )
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row: { original } }) => (
        <CrudActions onEdit={toggleModal(true, original)} />
      )
    }
  ]

  return (
    <>
      <h1>{t('PaymentCommission', 'menu')}</h1>

      <LoadingWrapper loading={loading}>
        <form onSubmit={onSubmit}>
          <ErrorMessage error={error} />

          <Row>
            <Col lg={6}>
              <TextControl
                name='tour'
                label={t('LableTourCoeff')}
                value={values.tour}
                error={touched.tour && errors.tour}
                onChange={handleChange}
              />
              <TextControl
                name='event'
                label={t('LableTourEvent')}
                value={values.event}
                error={touched.event && errors.event}
                onChange={handleChange}
              />
              <TextControl
                name='transfer'
                label={t('LableTourTransfer')}
                value={values.transfer}
                error={touched.transfer && errors.transfer}
                onChange={handleChange}
              />

              <div className='mt-5'>
                <Button type='submit' fullWidth>
                  {t('Save')}
                </Button>
              </div>
            </Col>
            <Col lg={6}>
              <FormGroup label={t('MerchantsList')}>
                <div className='d-flex align-items-start'>
                  <div className='w-100 me-2'>
                    <TextControl
                      name='search'
                      value={filter.search}
                      onChange={handleChangeSearch}
                      useFormik={false}
                      placeholder={t('LabelSearch')}
                    />
                  </div>
                  <Button onClick={toggleModal(true)}>{t('Add')}</Button>
                </div>
                <Table
                  data={merchants}
                  columns={columns}
                  onChange={handleChangeTable}
                  pagination={pagination}
                />
              </FormGroup>
            </Col>
          </Row>
        </form>
      </LoadingWrapper>

      <Modal
        open={modal.open}
        title={t('MerchantsEdit')}
        loading={mLoading}
        onClose={toggleModal(false)}
      >
        <div>
          <form onSubmit={formik.handleSubmit}>
            <ErrorMessage error={mError} />

            <SelectControl
              url={'/admin/merchants?search='}
              name='merchant'
              keys={{ label: 'companyTitle', value: 'id' }}
              async
              label={t('Partner')}
              error={formik.touched.merchant && formik.errors.merchant}
              onSelect={handleChangeMerchant}
              isDisabled={!!modal.data.id}
              placeholder={modal.data.companyTitle}
            />
            <TextControl
              name='tour'
              label={t('LableTourCoeff')}
              value={formik.values.tour}
              onChange={formik.handleChange}
              placeholder={values.tour}
            />
            <TextControl
              name='event'
              label={t('LableTourEvent')}
              value={formik.values.event}
              onChange={formik.handleChange}
              placeholder={values.event}
            />
            <TextControl
              name='transfer'
              label={t('LableTourTransfer')}
              value={formik.values.transfer}
              onChange={formik.handleChange}
              placeholder={values.transfer}
            />

            <div className='b-modal__footer'>
              <Button
                variant='danger'
                onClick={handleReset}
                className='ms-auto'
              >
                {t('Reset')}
              </Button>
              <Button type='submit'>{t('Save')}</Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default PaymentComissionPage
