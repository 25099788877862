import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import alert from 'utils/alert'
import axios from 'utils/axios'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import { t } from 'utils/localization'
import { Button } from 'react-bootstrap'
import { confirm } from 'components/Dialog'
import { LANGUAGE } from 'utils/constants'
import { useFormik } from 'formik'
import { objectToFormData } from 'utils/helpers'

const EditPartnerForm = (props) => {
  const { id, locale, setLoading, afterSubmit, commonData } = props

  const [data, setData] = useState({})
  const [error, setError] = useState(null)

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: {
      title: data.title || '',
      cover: null
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(t('Required', 'validation')),
      cover: Yup.mixed().notRequired()
    }),
    enableReinitialize: true
  })

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        const res = await axios.get(
          `/admin/about-bustourma/partner/${id}?locale=${locale}`
        )
        if (isMounted) {
          setData(res.data)
          afterSubmit(res.data, false)
        }
      } catch (error) {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    if (id) {
      getData()
    }
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, locale])

  const handleSubmit = async (values) => {
    try {
      setError(null)
      setLoading(true)

      const res = await axios({
        url: `/admin/about-bustourma/partner/${id || ''}`,
        data: objectToFormData({ ...values, locale, ...commonData }),
        method: id ? 'put' : 'post'
      })

      setData(res.data)
      afterSubmit(res.data, true)
      setFieldValue('cover', null)

      alert.success()
    } catch (error) {
      setError(error)
      alert.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    if (await confirm(t('ConfirmDelete'))) {
      try {
        setLoading(true)
        await axios.delete(`/admin/about-bustourma/partner/${id}`)
        afterSubmit(null, true, true)
        alert.success()
      } catch (error) {
        setError(error)
        alert.error(t('ErrorDelete', 'validation'))
      } finally {
        setLoading(false)
      }
    }
  }

  const handleChangeCover = (value) => {
    setFieldValue('cover', value)
  }

  return (
    <>
      <ErrorMessage error={error} />

      <form onSubmit={onSubmit}>
        <TextControl
          name='title'
          label={t('LabelName')}
          value={values.title}
          error={touched.title && errors.title}
          onChange={handleChange}
          required
        />
        <FileUpload
          label={t('Logo')}
          value={values.cover}
          files={data.cover}
          onChange={handleChangeCover}
        />
        <div className='b-modal__footer'>
          {id && (
            <Button variant='danger' onClick={handleDelete}>
              {t('DeletePartner')}
            </Button>
          )}
          <Button type='submit'>
            {t('SaveFor', 'common', { locale: LANGUAGE.getNameByCode(locale) })}
          </Button>
        </div>
      </form>
    </>
  )
}

export default EditPartnerForm
