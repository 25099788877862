import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Form } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import { RequiredSpan } from 'components/Error'
import { EditorState, ContentState, convertToRaw } from 'draft-js'

import './styles.scss'

const HtmlControl = (props) => {
  const { label, value, error, onChange, required, className, ...rest } = props

  return (
    <Form.Group className={clsx('mb-4', className)}>
      {label && (
        <Form.Label>
          {label}
          {required ? <RequiredSpan /> : ''}
        </Form.Label>
      )}
      <HtmlEditor error={error} value={value} onChange={onChange} {...rest} />
      {error && (
        <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default HtmlControl

const HtmlEditor = (props) => {
  const { error, value, onChange } = props

  const [editorState, setEditorState] = useState(null)

  useEffect(() => {
    if (value && !editorState) {
      const content = htmlToDraft(value)
      if (content) {
        const contentState = ContentState.createFromBlockArray(
          content.contentBlocks
        )
        const editorState = EditorState.createWithContent(contentState)
        setEditorState(editorState)
      }
    }
  }, [value, editorState])

  const handleChange = (changedState) => {
    setEditorState(changedState)
    if (onChange) {
      onChange(draftToHtml(convertToRaw(changedState.getCurrentContent())))
    }
  }

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleChange}
      editorClassName='b-html-editor__main'
      toolbarClassName='b-html-editor__toolbar'
      wrapperClassName={clsx(error && 'b-html-editor--error')}
    />
  )
}
