import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useCreateOrderApi } from './api'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { t } from 'utils/localization'
import Table from 'components/Table'
import alert from 'utils/alert'
import { useDebouncedCallback } from 'use-debounce'
import './styles.scss'

const getStatusTranslate = (status) => {
  switch (status) {
    case 'paid':
      return t('OrderStatusPaid')
    case 'paymentWait':
      return t('OrderStatusPaymentWait')
    case 'cancel':
    case 'rejected':
      return t('OrderStatusCancel')
    default:
      return ''
  }
}

const OrdersPage = () => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [filter, setFilter] = useState({
    page: 1,
    rowsPerPage: 10
  })

  const history = useHistory()
  const { fetchAllOrders, generateNewUrl, sendUrlToUser } = useCreateOrderApi()

  const getData = useDebouncedCallback(async () => {
    const res = await fetchAllOrders(filter)
    setData(res.data)
    setPagination(res.pagination)
  }, 500)

  const handleCopyLink = (value) => () => {
    navigator.clipboard.writeText(value).then(function () {
      alert.success('Ссылка скопирована')
    })
  }

  const handleChangePagination = (value) => {
    setFilter({ ...filter, ...value.pagination })
  }

  const handleCreateNewLink = useCallback(
    (id) => () => {
      generateNewUrl(id)
      getData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleSendEmail = (orderId) => () => {
    sendUrlToUser(orderId, 'email')
  }

  const handleSendSMS = (orderId) => () => {
    sendUrlToUser(orderId, 'sms')
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData, filter, handleCreateNewLink])

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id'
      },
      {
        Header: t('LabelTitle'),
        accessor: 'title'
      },
      {
        Header: t('LabelDateTimeStart'),
        accessor: 'dateTimeStart'
      },
      {
        Header: t('LabelDateTimeEnd'),
        accessor: 'dateTimeEnd'
      },
      {
        id: 1,
        Header: t('LabelPayLink'),
        accessor: 'payment',
        Cell: ({ value }) =>
          value.state === 'paid' ? (
            <div>-</div>
          ) : (
            <div
              className='url-link'
              onClick={handleCopyLink(value.paymentURL)}
            >
              {value.paymentURL}
            </div>
          )
      },
      {
        Header: t('LabelPhone'),
        accessor: 'userPhone'
      },
      {
        id: 2,
        Header: '',
        accessor: 'payment',
        Cell: ({ value, row }) => {
          if (value.state === 'paid') {
            return ''
          }

          if (value.state === 'cancel' || value.state === 'rejected') {
            return (
              <div>
                <Button onClick={handleCreateNewLink(row.original.payment.id)}>
                  {t('CreateNewLinkButton')}
                </Button>
              </div>
            )
          }

          if (value.state === 'paymentWait') {
            return value.paymentURL ? (
              <div style={{ display: 'flex' }}>
                <Button
                  onClick={handleSendSMS(row.original.payment.id)}
                  style={{ marginRight: 10 }}
                >
                  {t('SendSMSButton')}
                </Button>
                <Button onClick={handleSendEmail(row.original.payment.id)}>
                  {t('SendEmailButton')}
                </Button>
              </div>
            ) : (
              <div>
                <Button onClick={handleCreateNewLink(row.original.payment.id)}>
                  {t('CreateNewLinkButton')}
                </Button>
              </div>
            )
          }
        }
      },
      {
        id: 3,
        Header: t('LabelStatus'),
        accessor: 'payment',
        Cell: ({ value }) =>
          value.state === 'paid' ? (
            <span style={{ color: '#78B060' }}>
              {getStatusTranslate(value.state)}
            </span>
          ) : (
            getStatusTranslate(value.state)
          )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleRedirect = () => {
    history.push('/create-order')
  }

  return (
    <>
      <Button size='lg' onClick={handleRedirect} className='mb-4'>
        {t('CreateOrder', 'menu')}
      </Button>
      <Table
        data={data}
        columns={columns}
        onChange={handleChangePagination}
        pagination={pagination}
      />
    </>
  )
}

export default OrdersPage
