import moment from 'moment'
import * as config from './config'

const date = {
  init(locale) {
    return new Promise((resolve, reject) => {
      config.SUPPORTED_LOCALES[locale]
        .momentLocaleLoader()
        .then(() => {
          moment.locale(config.SUPPORTED_LOCALES[locale].moment)
          return resolve()
        })
        .catch((err) => reject(err))
    })
  },
  format(date, format) {
    return moment(date).format(format)
  }
}

export default date
