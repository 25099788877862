import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import Modal from 'components/Modal'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import DateControl from 'components/DateControl'
import ErrorMessage from 'components/Error'
import SelectControl from 'components/SelectControl'
import ControlInputGroup from 'components/ControlInputGroup'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'
import { objectToFormData } from 'utils/helpers'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Button, Badge, Row, Col } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

const UserPage = (props) => {
  const [data, setData] = useState({})
  const [modal, setModal] = useState({ open: false, type: '' })
  const [error, setError] = useState(null)
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState([])

  const params = useParams()
  const history = useHistory()

  const formik = useFormik({
    onSubmit: (values) => handleLimitUser(values),
    initialValues: { reason: '' },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required(t('Required', 'validation'))
    })
  })

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('Required', 'validation')),
    lastName: Yup.string().required(t('Required', 'validation')),
    birthday: Yup.string().required(t('Required', 'validation')),
    sex: Yup.mixed().notRequired(),
    country: Yup.string().notRequired(),
    email: Yup.string()
      .required(t('Required', 'validation'))
      .email(t('InvalidEmail', 'validation')),
    phone: Yup.string()
      .notRequired()
      .test('check-phone', t('InvalidPhone', 'validation'), (value) => {
        return value ? isValidPhoneNumber(value) : true
      }),
    avatar: Yup.mixed().notRequired(),
    roles: Yup.array().notRequired()
  })

  const {
    dirty,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: {
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      birthday: data.birthday || '',
      country: data.country || '',
      avatar: null,
      email: data.email || '',
      phone: data.phone || '',
      roles: data.roles || [],
      sex: data.sex
    },
    validationSchema: ValidationSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        const res = await axios.get('/admin/roles')
        isMounted && setRoles(res.data)
      } catch {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    getData()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      try {
        isMounted && setLoading(true)
        const res = await axios.get(`/admin/user/${params.id}`)
        isMounted && setData(res.data)
      } catch {
        /* empty */
      } finally {
        isMounted && setLoading(false)
      }
    }
    if (params.id) {
      getData()
    }
    return () => {
      isMounted = false
    }
  }, [params.id])

  useEffect(() => {
    const getList = async () => {
      try {
        const res = await axios.get('/public/user/lk/countries')
        setCountries(res.data.map((value) => ({ label: value, value })))
      } catch {
        /* empty */
      }
    }
    getList()
  }, [])

  const handleSubmit = async (values) => {
    if (dirty) {
      try {
        setError(null)
        setLoading(true)

        const url = `/admin/user/${params.id || ''}`
        const data = objectToFormData(values)
        const method = params.id ? 'put' : 'post'

        const res = await axios({ url, data, method })

        if (params.id) {
          setData(res.data)
          setFieldValue('avatar', null)
          alert.success()
        } else {
          history.replace(`/users/${res.data.id}`)
        }
      } catch (error) {
        setError(error)
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  const handleLimitUser = async (values) => {
    try {
      setLoading(true)
      const res = await axios.post(
        `/admin/user/${modal.type}/${params.id}`,
        values
      )
      setData(res.data)
      toggleModal(false)()
      alert.success()
    } catch (error) {
      alert.error()
    } finally {
      setLoading(false)
    }
  }

  const handleChangeField = (name) => (value) => {
    setFieldValue(name, value)
  }

  const handlePhoneChange = (value) => {
    setFieldValue('phone', value.indexOf('+') !== 0 ? '+' + value : value)
  }

  const toggleModal =
    (open, type = '') =>
    () => {
      setModal({ open, type })
      if (!open) {
        formik.resetForm()
      }
    }

  const getModalTitle = () => {
    if (modal.type === 'suspension') {
      return t(data.isSuspension ? 'ActivateUser' : 'DeactivateUser')
    } else if (modal.type === 'block') {
      return t(data.inBlackList ? 'UnlockUser' : 'BlockUser')
    }
    return ''
  }

  return (
    <>
      <div className='mb-5'>
        <h1 className='m-0'>{t(params.id ? 'EditUser' : 'CreateUser')}</h1>
        {data.inBlackList === true && (
          <Badge variant='dark'>{t('LabelInBlackList')}</Badge>
        )}
        {data.isSuspension === true && (
          <Badge variant='warning' className='ml-1'>
            {t('LabelIsSuspension')}
          </Badge>
        )}
        {data.selfDeactivation === true && (
          <Badge variant='secondary' className='ml-1'>
            {t('LabelSelfDeactivation')}
          </Badge>
        )}
      </div>

      {params.id && (
        <div className='mb-5'>
          <Button className='me-2' onClick={toggleModal(true, 'suspension')}>
            {t(data.isSuspension ? 'Activate' : 'Deactivate')}
          </Button>
          <Button variant='danger' onClick={toggleModal(true, 'block')}>
            {t(data.inBlackList ? 'Unlock' : 'Block')}
          </Button>
        </div>
      )}

      <LoadingWrapper loading={loading}>
        <form onSubmit={onSubmit}>
          {error && <ErrorMessage error={error} />}

          <Row>
            <Col lg={6}>
              <TextControl
                name='firstName'
                label={t('LabelFirstName')}
                value={values.firstName}
                error={touched.firstName && errors.firstName}
                onChange={handleChange}
              />
              <TextControl
                name='lastName'
                label={t('LabelLastName')}
                value={values.lastName}
                error={touched.lastName && errors.lastName}
                onChange={handleChange}
              />
              <DateControl
                name='birthday'
                label={t('LabelBirthday')}
                value={values.birthday}
                error={touched.birthday && errors.birthday}
                onChange={handleChangeField('birthday')}
              />
              <ControlInputGroup
                name='sex'
                label={t('LabelSex')}
                value={values.sex}
                error={touched.sex && errors.sex}
                onChange={handleChange}
                options={[
                  { label: t('OptionMale'), value: 'male' },
                  { label: t('OptionFemale'), value: 'female' }
                ]}
              />
              <SelectControl
                name='country'
                label={t('LabelCountry')}
                value={values.country}
                onChange={handleChangeField('country')}
                options={countries}
              />
              <TextControl
                name='email'
                label='Email'
                value={values.email}
                error={touched.email && errors.email}
                onChange={handleChange}
                disabled={!!params.id}
              />
              <TextControl
                name='phone'
                label={t('LabelPhone')}
                value={values.phone}
                error={touched.phone && errors.phone}
                onChange={handlePhoneChange}
                disabled={!!params.id}
                useFormik={false}
              />
              <SelectControl
                name='roles'
                label={t('LabelRoles')}
                value={values.roles}
                options={roles.map((role) => ({
                  label: role.title,
                  value: role.id
                }))}
                onChange={handleChangeField('roles')}
                multiple
              />
            </Col>
            <Col lg={6}>
              <FileUpload
                label={t('LabelAvatar')}
                value={values.avatar}
                files={data.avatar}
                onChange={handleChangeField('avatar')}
              />
            </Col>
          </Row>

          <div className='mt-5'>
            <Button type='submit' fullWidth>
              {t('Save')}
            </Button>
          </div>
        </form>
      </LoadingWrapper>

      <Modal
        open={modal.open}
        title={getModalTitle()}
        loading={loading}
        onClose={toggleModal(false)}
      >
        <div>
          <form onSubmit={formik.handleSubmit}>
            <TextControl
              as='textarea'
              rows={6}
              name='reason'
              label={t('LabelReason')}
              value={formik.values.reason}
              error={formik.touched.reason && formik.errors.reason}
              onChange={formik.handleChange}
            />

            <div className='b-modal__footer'>
              <Button type='submit' className='ms-auto'>
                {t('SaveUser')}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default UserPage
