import io from 'socket.io-client'

const prod = process.env.REACT_APP_ENV === 'production'

const logSocketWork = (message, data) => {
  if (prod) {
    console.info(`Socket.IO: ${message}`, data)
  }
}

class WS {
  socket = null
  addNotification = () => {}

  init(addNotificationFunction) {
    if (prod && !this.socket?.connected) {
      this.addNotification = addNotificationFunction

      this.socket = io(process.env.REACT_APP_SOCKET, {
        jsonp: false,
        transports: ['websocket']
      })

      this.socket.connect()

      this.socket.on('connect', async () => {
        logSocketWork('Connection to server established.')

        const token = localStorage.getItem('token')

        this.socket
          .emit('authenticate', { token })
          .on('authenticated', this.onAuthenticated)
          .on('unauthorized', this.onUnauthorized)
          .on('error', this.onError)
          .on('disconnect', this.onDisconnect)
      })
    }
  }

  isConnected() {
    return this.socket?.connected
  }

  close() {
    if (this.socket) {
      this.socket.close()
      logSocketWork('Connection to server is closed.')
    }
  }

  onError = (error) => {
    logSocketWork('Error', error)
  }

  onDisconnect = () => {
    logSocketWork('Connection to server is lost.')
  }

  onAuthenticated = () => {
    logSocketWork('User authenticated')
    this.socket.on('notification', this.onNotification)
  }

  onNotification = (notification) => {
    logSocketWork('User received notification', { notification })

    // if (store && notification?.message) {
    //   NotificationSevice.info({
    //     message: notification.message.title,
    //     onRemoval: (_, data) => {
    //       if (data === 'click') {
    //         store.dispatch({
    //           type: READ_NOTIFICATION,
    //           payload: [notification.message.id]
    //         })
    //       }
    //     }
    //   })

    //   store.dispatch({
    //     type: ADD_NOTIFICATION,
    //     payload: notification.message
    //   })
    // }
  }

  onUnauthorized = (message) => {
    logSocketWork('User is unauthorized', JSON.stringify(message.data))
  }
}

const SocketService = new WS()

export default SocketService
