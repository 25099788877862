import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { t } from 'utils/localization'
import { Form } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { RequiredSpan } from 'components/Error'
import { deleteFromArray } from 'utils/helpers'

import iconDownload from 'images/icon-download.svg'
import './styles.scss'

const FileUpload = (props) => {
  const { label, error, required, ...rest } = props

  return (
    <Form.Group className='mb-4'>
      {label && (
        <Form.Label>
          {label}
          {required ? <RequiredSpan /> : ''}
        </Form.Label>
      )}
      <Dropzone {...rest} error={error} />
      {error && (
        <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default FileUpload

const Dropzone = (props) => {
  const {
    text,
    icon,
    // size = 'lg',
    note,
    value,
    error,
    files: uploaded,
    accept = 'image/*',
    onChange,
    multiple,
    onDelete,
    disabled
  } = props

  const [files, setFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])

  useEffect(() => {
    setFiles(value ? (Array.isArray(value) ? value : [value]) : [])
  }, [value])

  useEffect(() => {
    setUploadedFiles(
      uploaded ? (Array.isArray(uploaded) ? uploaded : [uploaded]) : []
    )
  }, [uploaded])

  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange
        ? onChange(multiple ? [...files, ...acceptedFiles] : acceptedFiles[0])
        : setFiles([...files, ...acceptedFiles])
    },
    [files, multiple, onChange]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept })

  const handleDeleteFile = (index) => () => {
    const newFiles = deleteFromArray(files, { index })
    onChange ? onChange(multiple ? newFiles : newFiles[0]) : setFiles(newFiles)
  }

  const handleDeleteUploaded = (file, index) => () => {
    onDelete(file, index)
  }

  const renderPreview = () => {
    const list = files.map((file) => ({
      ...file,
      preview: URL.createObjectURL(file),
      isImage: file.type ? file.type.includes('image') : false
    }))

    return list.map((file, index) => (
      <div className='b-preview__wrapper' key={index}>
        <div className='b-preview'>
          <div className='b-preview__inner'>
            <span className='b-preview__item'>
              {file.isImage ? (
                <a href={file.preview} data-fancybox={'preview-' + index}>
                  <img
                    src={file.preview}
                    alt={`${t('AttachedFile')}-${index + 1}`}
                    title={file.path}
                  />
                </a>
              ) : (
                <span>{file.path}</span>
              )}
              <span
                className='b-preview__close'
                onClick={handleDeleteFile(index)}
              >
                ×
              </span>
            </span>
          </div>
        </div>
      </div>
    ))
  }

  const renderUploaded = () => {
    return uploadedFiles.map((file, index) => (
      <div className='b-preview__wrapper' key={index}>
        <div className='b-preview'>
          <div className='b-preview__inner'>
            <span className='b-preview__item'>
              <a href={file} data-fancybox={'preview-' + index}>
                <img
                  src={file}
                  alt={`${t('UploadedFile')}-${index + 1}`}
                  title={file}
                />
              </a>
              {onDelete && (
                <span
                  className='b-preview__close'
                  onClick={handleDeleteUploaded(file, index)}
                >
                  ×
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div className={clsx('b-dropzone', error && 'b-dropzone--error')}>
      <aside className='b-preview__container'>{renderUploaded()}</aside>

      <div {...getRootProps()}>
        <section className='b-dropzone__inner'>
          <input {...getInputProps({ multiple, disabled })} />
          {icon || <img alt='' src={iconDownload} />}
          <span className='b-dropzone__text'>{text || t('DropFiles')}</span>
          {note && <span>{note}</span>}
        </section>
      </div>

      <aside className='b-preview__container'>{renderPreview()}</aside>
    </div>
  )
}
