import { Switch } from 'react-router-dom'

import PrivateRoute from 'components/PrivateRoute'

import MainPage from 'pages/Main'

import FinancePage from 'pages/Finance'

import HomePage from 'pages/Home'
import AboutPage from 'pages/About'
import TermsOfUsePage from 'pages/TermsOfUse'
import AboutArmeniaPage from 'pages/AboutArmenia'
import FilesStoragePage from 'pages/FilesStorage'
import PrivacyPolicyPage from 'pages/PrivacyPolicy'

import UserPage from 'pages/User'
import UsersPage from 'pages/Users'

import ReviewPage from 'pages/Review'
import ReviewsPage from 'pages/Reviews'

import CancelRequestPage from 'pages/CancelRequest'
import CancelRequestsPage from 'pages/CancelRequests'

import MerchantPage from 'pages/Merchant'
import MerchantsPage from 'pages/Merchants'

import OrdersPage from 'pages/Orders'
import CreateOrderPage from 'pages/Orders/CreateOrder'
import ProductPage from 'pages/Product'
import ProductsPage from 'pages/Products'

import MailingPushPage from 'pages/Mailing/Push'
import MailingEmailsPage from 'pages/Mailing/Emails'

import ManyDayRequestsPage from 'pages/ManyDayRequests'
import ProductQuestionsPage from 'pages/ProductQuestions'
import FeedbackRequestsPage from 'pages/FeedbackRequests'
import ZeroRequestsPage from 'pages/ZeroRequests'

import SettingsSeoPage from 'pages/Settings/SeoPage'
import SettingsContractsPage from 'pages/Settings/ContractsPage'
import SettingsPromotionPage from 'pages/Settings/PromotionSettings'
import SettingsPaymentCommissionPage from 'pages/Settings/PaymentCommissionPage'

import ListsProductCategoriesPage from 'pages/Lists/ProductCategories'
import ListsHealthRestrictionsPage from 'pages/Lists/HealthRestrictions'
import ListsProductComponentsListPage from 'pages/Lists/ProductComponentsList'
import ListsProductRestrictionsListPage from 'pages/Lists/ProductRestrictionsList'

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path='/' exact roles={[]} component={MainPage} />

      <PrivateRoute path='/home' exact roles={[]} component={HomePage} />
      <PrivateRoute path='/about' exact roles={[]} component={AboutPage} />

      <PrivateRoute
        path='/terms-of-use'
        exact
        roles={[]}
        component={TermsOfUsePage}
      />

      <PrivateRoute
        path='/privacy-policy'
        exact
        roles={[]}
        component={PrivacyPolicyPage}
      />

      <PrivateRoute
        path='/files-storage'
        exact
        roles={[]}
        component={FilesStoragePage}
      />

      <PrivateRoute
        path='/about-armenia'
        exact
        roles={[]}
        component={AboutArmeniaPage}
      />

      <PrivateRoute path='/users' exact roles={[]} component={UsersPage} />
      <PrivateRoute path='/users/new' exact roles={[]} component={UserPage} />
      <PrivateRoute path='/users/:id' exact roles={[]} component={UserPage} />

      <PrivateRoute path='/orders' exact roles={[]} component={OrdersPage} />
      <PrivateRoute
        path='/create-order/:id?'
        exact
        roles={[]}
        component={CreateOrderPage}
      />
      <PrivateRoute
        path='/products'
        exact
        roles={[]}
        component={ProductsPage}
      />
      <PrivateRoute
        path='/products/:id'
        exact
        roles={[]}
        component={ProductPage}
      />

      <PrivateRoute
        path='/merchants'
        exact
        roles={[]}
        component={MerchantsPage}
      />
      <PrivateRoute
        path='/merchants/:id'
        exact
        roles={[]}
        component={MerchantPage}
      />

      <PrivateRoute path='/reviews' exact roles={[]} component={ReviewsPage} />
      <PrivateRoute
        path='/reviews/:id'
        exact
        roles={[]}
        component={ReviewPage}
      />

      <PrivateRoute
        path='/cancel-requests'
        exact
        roles={[]}
        component={CancelRequestsPage}
      />
      <PrivateRoute
        path='/cancel-requests/:id'
        exact
        roles={[]}
        component={CancelRequestPage}
      />

      <PrivateRoute path='/finance' exact roles={[]} component={FinancePage} />

      <PrivateRoute path='/seo' exact roles={[]} component={SettingsSeoPage} />
      <PrivateRoute
        path='/contracts'
        exact
        roles={[]}
        component={SettingsContractsPage}
      />
      <PrivateRoute
        path='/promotions'
        exact
        roles={[]}
        component={SettingsPromotionPage}
      />
      <PrivateRoute
        path='/payment-commission'
        exact
        roles={[]}
        component={SettingsPaymentCommissionPage}
      />

      <PrivateRoute
        path='/many-day-tour-requests'
        exact
        roles={[]}
        component={ManyDayRequestsPage}
      />
      <PrivateRoute
        path='/zero-requests'
        exact
        roles={[]}
        component={ZeroRequestsPage}
      />
      <PrivateRoute
        path='/feedback-requests'
        exact
        roles={[]}
        component={FeedbackRequestsPage}
      />
      <PrivateRoute
        path='/product-questions'
        exact
        roles={[]}
        component={ProductQuestionsPage}
      />

      <PrivateRoute
        path='/mailing-push'
        exact
        roles={[]}
        component={MailingPushPage}
      />
      <PrivateRoute
        path='/mailing-email'
        exact
        roles={[]}
        component={MailingEmailsPage}
      />

      <PrivateRoute
        path='/product-categories'
        exact
        roles={[]}
        component={ListsProductCategoriesPage}
      />
      <PrivateRoute
        path='/health-restrictions'
        exact
        roles={[]}
        component={ListsHealthRestrictionsPage}
      />
      <PrivateRoute
        path='/tour-components'
        exact
        roles={[]}
        component={ListsProductComponentsListPage}
      />
      <PrivateRoute
        path='/tour-restrictions'
        exact
        roles={[]}
        component={ListsProductRestrictionsListPage}
      />
    </Switch>
  )
}

export default Routes
