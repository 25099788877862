import * as Yup from 'yup'
import axios from 'utils/axios'
import alert from 'utils/alert'
import Loading from 'components/Loading'
import { t } from 'utils/localization'
import TextControl from 'components/TextControl'
import { FALLBACK } from 'utils/localization/config'
import { LANGUAGE } from 'utils/constants'
import { useFormik } from 'formik'
import { Collapse, Button, Tabs, Tab } from 'react-bootstrap'
import { useState, useEffect, useCallback } from 'react'

const ProductSeo = (props) => {
  const { id, title, locales, mainLocale = FALLBACK } = props

  const [data, setData] = useState({})
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const getSeo = useCallback(async () => {
    if (id) {
      try {
        const res = await axios.get(`/public/seo/product-${id}`)
        if (res.data) {
          setData(res.data)
        }
      } catch (error) {
        /* empty */
      }
    }
  }, [id])

  useEffect(() => {
    getSeo()
  }, [getSeo])

  const getInitial = () => {
    const initial = {
      pageCode: `product-${id}`,
      pageName: `Product: ${title}`
    }
    locales?.forEach((code) => {
      initial[code] = {
        title: data[code]?.title || '',
        keywords: data[code]?.keywords || '',
        description: data[code]?.description || ''
      }
    })

    return initial
  }

  const getShape = () => {
    const shape = {
      pageCode: Yup.string().required(t('Required', 'validation')),
      pageName: Yup.string().required(t('Required', 'validation'))
    }
    locales?.forEach((code) => {
      shape[code] = Yup.object().shape({
        title:
          code === mainLocale
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired(),
        keywords:
          code === mainLocale
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired(),
        description:
          code === mainLocale
            ? Yup.string().required(t('Required', 'validation'))
            : Yup.string().notRequired()
      })
    })

    return shape
  }

  const toggleVisibility = () => {
    setShow(!show)
  }

  const handleSubmit = async (values) => {
    if (dirty) {
      try {
        setLoading(true)

        const res = await axios({
          url: `/admin/seo${data?.id ? `/${data.pageCode}` : ''}`,
          data: values,
          method: data?.id ? 'put' : 'post'
        })
        setData(res.data)

        alert.success()
      } catch {
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  const {
    dirty,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit: onSubmit
  } = useFormik({
    onSubmit: (values) => handleSubmit(values),
    initialValues: getInitial(),
    validationSchema: Yup.object().shape(getShape()),
    enableReinitialize: true
  })

  return (
    <>
      <div className='d-flex'>
        <h3 className='mb-0'>{t('SeoTitle')}</h3>
        <Button variant='link' onClick={toggleVisibility}>
          {t(show ? 'Hide' : 'Show')}
        </Button>
      </div>
      <Collapse in={show}>
        <div>
          <Loading loading={loading}>
            <form onSubmit={onSubmit} className='mt-4'>
              <TextControl
                name='pageCode'
                label={t('TableTitlePageCode')}
                value={values.pageCode}
                error={touched.pageCode && errors.pageCode}
                onChange={handleChange}
                readOnly
                required
              />
              <TextControl
                name='pageName'
                label={t('LabelName')}
                value={values.pageName}
                error={touched.pageName && errors.pageName}
                onChange={handleChange}
                required
              />

              {locales?.length > 1 ? (
                <Tabs defaultActiveKey={locales[0]} className='mt-4 mb-4'>
                  {LANGUAGE.getLanguages().map((lang) =>
                    locales.includes(lang.code) ? (
                      <Tab
                        key={lang.code}
                        eventKey={lang.code}
                        title={lang.name}
                      >
                        <TextControl
                          name={`${lang.code}.title`}
                          label={t('LabelTitle')}
                          value={values[lang.code]?.title}
                          error={
                            touched?.[lang.code]?.title &&
                            errors?.[lang.code]?.title
                          }
                          onChange={handleChange}
                          required={lang.code === mainLocale}
                        />
                        <TextControl
                          name={`${lang.code}.keywords`}
                          label={t('TableTitlePageKeywords')}
                          value={values[lang.code]?.keywords}
                          error={
                            touched?.[lang.code]?.keywords &&
                            errors?.[lang.code]?.keywords
                          }
                          onChange={handleChange}
                          required={lang.code === mainLocale}
                        />
                        <TextControl
                          name={`${lang.code}.description`}
                          label={t('LabelDescription')}
                          value={values[lang.code]?.description}
                          error={
                            touched?.[lang.code]?.description &&
                            errors?.[lang.code]?.description
                          }
                          onChange={handleChange}
                          required={lang.code === mainLocale}
                        />
                      </Tab>
                    ) : null
                  )}
                </Tabs>
              ) : (
                <>
                  <TextControl
                    name={`${mainLocale}.title`}
                    label={t('LabelTitle')}
                    value={values[mainLocale]?.title || ''}
                    error={
                      touched?.[mainLocale]?.title &&
                      errors?.[mainLocale]?.title
                    }
                    onChange={handleChange}
                    required
                  />
                  <TextControl
                    name={`${mainLocale}.keywords`}
                    label={t('TableTitlePageKeywords')}
                    value={values[mainLocale]?.keywords || ''}
                    error={
                      touched?.[mainLocale]?.keywords &&
                      errors?.[mainLocale]?.keywords
                    }
                    onChange={handleChange}
                    required
                  />
                  <TextControl
                    name={`${mainLocale}.description`}
                    label={t('LabelDescription')}
                    value={values[mainLocale]?.description || ''}
                    error={
                      touched?.[mainLocale]?.description &&
                      errors?.[mainLocale]?.description
                    }
                    onChange={handleChange}
                    required
                  />
                </>
              )}

              <Button type='submit'>{t('Save')}</Button>
            </form>
          </Loading>
        </div>
      </Collapse>
    </>
  )
}

export default ProductSeo
