import React from 'react'
import clsx from 'clsx'
import InputMask from 'react-input-mask'
import { Form } from 'react-bootstrap'
import { RequiredSpan } from 'components/Error'

const TextControl = (props) => {
  const {
    type,
    name,
    note,
    mask,
    label,
    value,
    error,
    onChange,
    required,
    className,
    useFormik = true,
    ...rest
  } = props

  const handleChange = (event) => {
    if (onChange) {
      onChange(useFormik ? event : event.target.value)
    }
  }

  return (
    <Form.Group className={clsx('mb-4', className)}>
      {label && (
        <Form.Label>
          {label}
          {required ? <RequiredSpan /> : ''}
        </Form.Label>
      )}
      {mask ? (
        <InputMask
          mask={mask}
          name={name}
          type={type}
          value={value}
          onChange={handleChange}
          className={clsx('form-control', error && 'is-invalid')}
          {...rest}
        />
      ) : (
        <Form.Control
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          isInvalid={!!error}
          {...rest}
        />
      )}
      {note && <Form.Text className='text-muted'>{note}</Form.Text>}
      <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
    </Form.Group>
  )
}

export default TextControl
