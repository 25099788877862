import axios from 'utils/axios'
import alert from 'utils/alert'
import FileUpload from 'components/FileUpload'
import TextControl from 'components/TextControl'
import ErrorMessage from 'components/Error'
import LoadingWrapper from 'components/Loading'
import { t } from 'utils/localization'
import { Button, Row, Col } from 'react-bootstrap'
import { objectToFormData } from 'utils/helpers'
import { useState, useEffect } from 'react'

const ContractsSettings = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [uploaded, setUploaded] = useState({
    telcell: null,
    bustourma: null
  })
  const [values, setValues] = useState({
    telcell: {
      title: '',
      document: ''
    },
    bustourma: {
      title: '',
      document: ''
    }
  })

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const telcell = await axios.get(`/admin/document/contract/telcell`)
        const bustourma = await axios.get(`/admin/document/contract/bustourma`)

        setValues({
          telcell: {
            title: telcell.data.title,
            document: ''
          },
          bustourma: {
            title: bustourma.data.title,
            document: ''
          }
        })
        setUploaded({
          telcell: telcell.data.document,
          bustourma: bustourma.data.document
        })
      } catch {
        /* empty */
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const handleSubmit = (type) => async (event) => {
    event.preventDefault()

    if (values[type]) {
      try {
        setError(null)
        setLoading(true)

        const res = await axios.put(
          `/admin/document/contract/${type}`,
          objectToFormData(values[type])
        )

        setValues({
          ...values,
          [type]: {
            title: res.data.title,
            document: null
          }
        })
        setUploaded({ ...uploaded, [type]: res.data.document })

        alert.success()
      } catch (error) {
        setError(error)
        alert.error()
      } finally {
        setLoading(false)
      }
    }
  }

  const handleChange = (type, name) => (value) => {
    const newValues = { ...values[type] }
    newValues[name] = value
    setValues({ ...values, [type]: newValues })
  }

  return (
    <>
      <h1>{t('Contracts', 'menu')}</h1>

      <LoadingWrapper loading={loading}>
        <Row>
          <Col md={6}>
            <h2>Telcell</h2>

            <ErrorMessage error={error} />

            <form onSubmit={handleSubmit('telcell')}>
              <TextControl
                name='value'
                value={values.telcell.title}
                label={t('LabelName')}
                onChange={handleChange('telcell', 'title')}
                useFormik={false}
              />

              {uploaded.telcell && (
                <div className='mb-3'>
                  <a rel='noreferrer' href={uploaded.telcell} target='_blank'>
                    {t('UploadedFile')}
                  </a>
                </div>
              )}

              <FileUpload
                label={t('LableFile')}
                value={values.telcell.document}
                accept='application/pdf'
                onChange={handleChange('telcell', 'document')}
              />

              <Button type='submit'>{t('Save')}</Button>
            </form>
          </Col>
          <Col md={6}>
            <h2>Bustourma</h2>

            <ErrorMessage error={error} />

            <form onSubmit={handleSubmit('bustourma')}>
              <TextControl
                name='value'
                value={values.bustourma.title}
                label={t('LabelName')}
                onChange={handleChange('bustourma', 'title')}
                useFormik={false}
              />

              {uploaded.bustourma && (
                <div className='mb-3'>
                  <a rel='noreferrer' href={uploaded.bustourma} target='_blank'>
                    {t('UploadedFile')}
                  </a>
                </div>
              )}

              <FileUpload
                label={t('LableFile')}
                value={values.bustourma.document}
                accept='application/pdf'
                onChange={handleChange('bustourma', 'document')}
              />

              <Button type='submit'>{t('Save')}</Button>
            </form>
          </Col>
        </Row>
      </LoadingWrapper>
    </>
  )
}

export default ContractsSettings
