import { t } from 'utils/localization'
import { Route } from 'react-router-dom'
import { useProfile } from 'utils/hooks/useContext'
import { checkUserRoles } from 'utils/helpers'

import iconLock from 'images/icon-lock.svg'

const PrivateRoute = (props) => {
  const { component: Component, roles, ...rest } = props
  const { roles: userRoles } = useProfile()

  const isAllowed = checkUserRoles(userRoles, roles)

  return (
    <Route
      {...rest}
      render={() =>
        isAllowed ? (
          <Component {...rest} />
        ) : (
          <div className='d-flex flex-column align-items-center'>
            <h3 className='mb-4'>{t('ErrorForbidden', 'validation')}</h3>
            <img alt='' src={iconLock} width={100} height={100} />
          </div>
        )
      }
    />
  )
}

export default PrivateRoute
